import { useTheme } from "@material-ui/core";
import React from "react";
import { fonts } from "../../utilities/styles";
import Text from "./Text";

const LinkTextSM = ({ children, style, onClickHandler }) => {
  const theme = useTheme();
  const customStyle = {
    color: theme.palette.primary.dark,
    ...style,
  };
  return (
    <Text
      fontSize={12}
     // fontFamily={fonts.rubik.value}
      onClickHandler={onClickHandler}
      centered
      style={customStyle}
    >
      {children}
    </Text>
  );
};

export default LinkTextSM;
