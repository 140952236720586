import axios from 'axios';
import AxiosWithE2EE from '../config/services/Interceptors';
import {
  API_FILE_URL,
  API_URL,
  API_URL_LOGIN,
  POLLING_URL,
  NAME_MISMATCH_API_URL,
  FETCH_DIGILOCKER_URL,
  POST_DETAILS_URL,
  SAVE_DIGILOCKER_RESPONSE,
  FETCH_PRE_SINGED_URL,
  POST_SAVE_DOCUMENTS,
  POST_VERIFY_KYC_DETAILS,
  GET_CUSTOMER_DOCUMENTS,
  INITIATE_DIGILOCKER,
  POST_VERIFY_PENNY_DROP,
  GET_DOCUMENT_DETAILS,
  INITIATE_ESIGN,
  SAVE_ESIGN_RESPONSE,
  POST_SAVE_KYC_VIDEO,
  CUSTOMER_SYNC,
  CREATE_CUSTOMER,
  INTIAL_VERIFY,
  API_URL1,
  GET_FUND_INFO,
  API_URL3,
  GET_PAYMENT_DETAILS
} from '../config/ApiConfig';
import { getError, getHeaders } from '../helper-methods';
import { resolve } from '../interceptors/index';
import { sampleJson } from '../mock-data/nmOnboardingJson.js';

const devMode = true;

const customerIds="CUST-575d5ef5-b159-460b-9552-14532e2dd8ab"
export const generateEmailOTP = async (data) => {
  return await Promise.resolve(
    (devMode ? axios : AxiosWithE2EE)
      .post(`${API_URL_LOGIN}/email/generate-otp`, data, {
        headers: getHeaders(),
      })
      .then((res) => {
        if (res && res.data && 'string' === typeof res.data) {
          return {
            data: JSON.parse(res.data),
            // error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        }
        return {
          data: res.data,
        //   error: getError(res.error),
          status: res.status,
          headers: res?.headers,
        };
      })
  );
};


  export const verifyEmailOTP = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(`${API_URL_LOGIN}/email/verify`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          var token = res.headers.get('x-device-token');
         console.log("token", token, res);
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };


  export const verifyGmail = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : axios)
        .post(`${API_URL_LOGIN}/google`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };


  export const verifyGoogleLogin = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : axios)
        .post(`${API_URL_LOGIN}/email/verify/google`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };
  export const generateMobileOTP = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(`https://nm-uat-login.fnpaas.com/dev/nm/v1/phone/otp`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        })
    );
  };
  
  
    export const verifyMobileOTP = async (data) => {
      return await Promise.resolve(
        (devMode ? axios : AxiosWithE2EE)
          .post(`https://nm-uat-login.fnpaas.com/dev/nm/v1/phone/verify`, data, {
            headers: getHeaders(),
          })
          .then((res) => {
            if (res && res.data && 'string' === typeof res.data) {
              return {
                data: JSON.parse(res.data),
                // error: getError(res.error),
                status: res.status,
                headers: res?.headers,
              };
            }
            return {
              data: res.data,
            //   error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
            }).catch(e => console.log("VerifyEmailOTPData", e) )
      );
    };

  export const login = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(`${API_URL_LOGIN}/login`, {}, {
          headers: getHeaders(data),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        }).catch(e => {
          return {
            data: e?.response?.data,
              status: e?.response?.status,
              headers: e?.response?.headers,
          }})
    );
  };


  export const emailResendOTP = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(`${API_URL_LOGIN}/email/resend-otp`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        })
    );
  };
  export const mobileResendOTP = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(`${API_URL_LOGIN}/phone/resend-otp`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        })
    );
  };
  
  //  Onboarding api's 

  export const getSampleJson = async () => {
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(`https://nm-web-uat2.fnpaas.com/onboarding-config.json`)
        .then((res) => {
          // if (res && res.data && 'string' === typeof res.data) {
          //   return {
          //     data: JSON.parse(res.data),
          //     error: getError(res.error),
          //     status: res.status,
          //     headers: res?.headers,
          //   };
          // }
          // return {
          //   data: res.data,
          //   error: getError(res.error),
          //   status: res.status,
          //   headers: res?.headers,
          // };
          return {
            data: sampleJson,
            error: null,
            status: 200,
            headers: {},
          };
        })
    );
  };
  
  export const ifscCheckAPI = async (data) => {
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(
          `https://m9lq0cscld.execute-api.ap-south-1.amazonaws.com/dev/nm/v1/search`,
          {
            // headers: await getHeaders(),
            headers: {
              'Content-type': 'application/json',
            },
            params: {
              ifsc: data.ifsc,
            },
          }
        )
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const postUserDetails = async ({ customerId, payload, path }) => {
    console.log({ customerId, payload, path });
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(POST_DETAILS_URL({ customerIds, path: path }), payload, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const patchNameMismatchApi = async ({ customerId, payload }) => {
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .patch(NAME_MISMATCH_API_URL({ customerIds }), payload, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
            error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        })
        .catch((err) => {
        })
    );
  };
  
  export const getPollingDetailsApi = async ({ requestId, path }) => {
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(POLLING_URL({ requestId }), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const getInitialAccountDetails = async ({ customerId, endpoint }) => {
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(`${API_URL}customer/${customerIds}/${endpoint}/details`, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
            error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        })
    );
  };
  
  export const fetchDigilockerUrl = async (data) => {
    const { customerId } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(FETCH_DIGILOCKER_URL(customerIds), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
            error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
        })
        .catch((err) => {
        })
    );
  };
  
  export const postSaveDocumentsApi = async (data) => {
    const { customerIds, documentUrl, document_type, doc_name, format } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(
          POST_SAVE_DOCUMENTS({ customerIds, document_type }),
          {
            documentUrl: documentUrl,
            doc_type: document_type,
            doc_name: doc_name,
            ...(format && { format: format }),
          },
          {
            headers: await getHeaders(),
          }
        )
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const getPreSingedUrlApi = async (data) => {
    const { customerId, document_type, format } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(FETCH_PRE_SINGED_URL({ customerIds, document_type, format }), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  export const getDocumentDownloadApi = async (data) => {
    const { customerId, document_type } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(GET_CUSTOMER_DOCUMENTS({ customerIds, document_type }), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const getDocumentDetails = async (data) => {
    const { customerId } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(GET_DOCUMENT_DETAILS({ customerIds }), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
                        return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const saveDigilockerResponse = async (data) => {
    const { customerId } = data;
    console.log('saveDigioResponseApiCall: ', data);
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(SAVE_DIGILOCKER_RESPONSE({ customerIds }), data, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const postVerfiyKycDetails = async (data, path) => {
    const { customerId } = data;
  
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(
          POST_VERIFY_KYC_DETAILS({ customerIds }),
          {},
          {
            headers: await getHeaders(),
          }
        )
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {

        })
    );
  };
  
  export const postVerfiyPennyDrop = async (data) => {
    const { customerId } = data;
  
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(
          POST_VERIFY_PENNY_DROP({ customerIds }),
          {},
          {
            headers: await getHeaders(),
          }
        )
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const intiateDigilocker = async (data) => {
    const { customerId } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(INITIATE_DIGILOCKER({ customerIds }), data, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  
  export const intiateEsign = async (data) => {
    const { customerId } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(INITIATE_ESIGN({ customerIds }), data, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
        })
    );
  };
  export const saveEsignResponse = async (data) => {
    const { customerId } = data;
  
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(SAVE_ESIGN_RESPONSE(customerIds), data, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {

        })
    );
  };
  
  export const postSaveKycVideo = async (data) => {
    console.log('postSaveKycVideo', { data });
    const { customerId, payload, path } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .post(POST_DETAILS_URL({ customerIds, path }), payload, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {

        })
    );
  };
  
  export const getInitialVerifyInfo = async (data) => {
    const { customerId } = data;
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(INTIAL_VERIFY({ customerIds }), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {

        })
    );
  };
  

  // try ------------------------------
  export const getCustomerSyncAPI = async ( data ) => {
    return await resolve(
      (devMode ? axios : AxiosWithE2EE)
        .get(CUSTOMER_SYNC(), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          } else {
            return {
              data: res.data,
              error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
        })
        .catch((err) => {
          return {
            data: err?.response?.data,
              status: err?.response?.status,
              headers: err?.response?.headers,
          }
        })
    );
  };

  // export const getCustomerSyncAPI = async (data) => {
  //   return await Promise.resolve(
  //     (devMode ? axios : axios)
  //       .get(`${API_URL}customer/sync`, {
  //         headers:await getHeaders()
  //       })

  //       .then((res) => {
  //         console.log("The ressssss:",res.data?.customerData)
  //         return {
  //           data: res,
  //           error: getError(res.error),
  //           status: res.status,
  //           headers: res?.headers,
  //         };
  //         console.log("The response of sync11",res)
  //         if (res && res.data && 'string' === typeof res.data) {
            
  //           return {
  //             data: JSON.parse(res.data),
  //             error: getError(res.error),
  //             status: res.status,
  //             headers: res?.headers,
  //           };
  //         }
  //         return {
  //           data: res.data,
  //         //   error: getError(res.error),
  //           status: res.status,
  //           headers: res?.headers,
  //         };
  //         }).catch(e => {
            // return {
            //   data: e?.response?.data,
            //     status: e?.response?.status,
            //     headers: e?.response?.headers,
            // }})
  //   );
  // };

  export const createV2CustomerUnifiedAPI = async (data) => {
    return await Promise.resolve(
      (devMode ? axios : axios)
        .post(CREATE_CUSTOMER(),{}, {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };

  export const getFundInfoDetails = async (data) => {
    const { mfId } = data
    return await Promise.resolve(
      (devMode ? axios : axios)
        .get(GET_FUND_INFO({mfId}), {
          headers: await getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };

  export const getPaymentRefId = async (data) => {
    console.log('inside getPaymentRefId'+JSON.stringify(data));
    return await Promise.resolve(
      (devMode ? axios : axios)
        .post(`${API_URL3}mf-transaction-processing/v1/transaction/instruction`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };

  export const getPaymentDetails = async (data) => {
    console.log('inside getPaymentDetails'+JSON.stringify(data));
    let { paymentRefId } = data;
    return await Promise.resolve(
      (devMode ? axios : axios)
        .get(GET_PAYMENT_DETAILS({paymentRefId}), {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };

  export const postSuccessPayment = async (data) => {
    console.log('inside postSuccessPayment'+JSON.stringify(data));
    return await Promise.resolve(
      (devMode ? axios : axios)
        .post(`${API_URL3}mf-transaction-processing/v1/transaction/payment/callback`, data, {
          headers: getHeaders(),
        })
        .then((res) => {
          if (res && res.data && 'string' === typeof res.data) {
            return {
              data: JSON.parse(res.data),
              // error: getError(res.error),
              status: res.status,
              headers: res?.headers,
            };
          }
          return {
            data: res.data,
          //   error: getError(res.error),
            status: res.status,
            headers: res?.headers,
          };
          }).catch(e => {
            return {
              data: e?.response?.data,
                status: e?.response?.status,
                headers: e?.response?.headers,
            }})
    );
  };