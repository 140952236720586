import React, { useState }  from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from '../../components/StyledComponents/Button';
import { Box, Tab, useTheme } from '@material-ui/core';
import LinkTextSM from '../shared/LinkTextSM';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import SortIcon from '../../assets/icons/sort-icon.svg';
import SmarterSavingIcon from '../../assets/icons/smarter-saving.svg';
import ArrowIcon from '../../assets/icons/arrow-icon.svg';
import NipponIcon from '../../assets/icons/Nippon_Life_Insurance.svg';
import SipPurchseIcon from '../../assets/icons/ellipse.svg';
import ExecutedIcon from '../../assets/icons/executed.svg';
import { quickActionJson } from '../../mock-data/quickActionsJson';
import {
  smarterSavingsButton,
  tabSelectionBox,
  dashboardTabs,
  fcJc,
  frJc,
  frAs,
  text14,
  text12lh15,
  frJsb,
  text23,
  fonts,
  cursorPointer
} from '../../utilities/styles';


const headCells = [
  {
    id: 'fundName',
    numeric: false,
    disablePadding: true,
    label: 'Fund name',
    tab: 'all'
  },
  {
    id: 'invested',
    numeric: true,
    disablePadding: false,
    label: 'Invested',
    tab: 'portfolio'
  },
  {
    id: 'returns',
    numeric: true,
    disablePadding: false,
    label: 'Returns',
    tab: 'portfolio'
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date',
    tab: 'recentTxn'
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Value',
    tab: 'all'
  }
];

function EnhancedTableHead(props) {
  const { tabSelected, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  // const createSortHandler =
  // (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
  //   onRequestSort(event, property);
  // };
  const icon = <img src={SortIcon} alt='' />
  console.log("tab is"+tabSelected)
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
         ( (headCell.tab === tabSelected) || (headCell.tab === 'all')) ?
          (
            <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
            //  IconComponent = {SortIcon}
            // onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null} */}
            </TableSortLabel>
          </TableCell>
          ) : ''
        ))}
      </TableRow>
    </TableHead>
  );

}

const TransactionList = () => {
  const { myPortfolioList } = quickActionJson.myPortfolio;
  const { recentTransactionsList } = quickActionJson.recentTransactions;
  const navigate = useNavigate();
  const [showFlag, setshowFlag] = useState('portfolio');
  const [portfolioTabSelected, setPortfolioTabSelected] = useState(false);
  const [sipTabSelected, setSipTabSelected] = useState(false);
  const [recentTxnTabSelected, setrecentTxnTabSelected] = useState(false);
  let savingIcon = <img src={SmarterSavingIcon} alt='' />
  let arrowIcon = <img src={ArrowIcon} alt='' />
  let nipponIcon = <img src={NipponIcon} alt='' />
  let sipPurchaseIcon = <img src={SipPurchseIcon} alt='' />
  let executedIcon = <img src={ExecutedIcon} alt='' />
  let handleClick = (event) => {
    if(event === 'portfolio'){
      setPortfolioTabSelected(true);
      setSipTabSelected(false);
      setrecentTxnTabSelected(false);
    }else if(event === 'sip'){
      setPortfolioTabSelected(false);
      setSipTabSelected(true);
      setrecentTxnTabSelected(false);
    }else if(event === 'recentTxn'){
      setPortfolioTabSelected(false);
      setSipTabSelected(false);
      setrecentTxnTabSelected(true);
    }
    console.log("show flag is "+event);
    setshowFlag(event);
  }
  let fundNameHandler = (data) => {
    console.log("row is "+data);
    navigate('/timeline');
  }
  const theme = useTheme();
  return (
    <div>
    <>
      <Box style={{...frJsb,...tabSelectionBox}}>
        <Box  fontFamily={fonts.rubikNormal.value} style={{ ...dashboardTabs,...text23,
              border: `2.5px solid ${portfolioTabSelected ?'#FFFFFF' : '#F9F9F9'}`,
              background:`${portfolioTabSelected ?'#FFFFFF' : '#F9F9F9'}`}} onClick={() => handleClick('portfolio')} >My portfolio</Box>
        <Box  fontFamily={fonts.rubikNormal.value} style={{...dashboardTabs,...text23,
              border: `3.5px solid ${sipTabSelected ?'#FFFFFF' : '#F9F9F9'}`,
              background: `${sipTabSelected ?'#FFFFFF' : '#F9F9F9'}`}} onClick={() => handleClick('sip')} >SIP(4)</Box>
        <Box  fontFamily={fonts.rubikNormal.value} style={{...dashboardTabs,...text23,
              border: `3.5px solid ${recentTxnTabSelected ?'#FFFFFF' : '#F9F9F9'}`,
              background: `${recentTxnTabSelected ?'#FFFFFF' : '#F9F9F9'}`}} onClick={() => handleClick('recentTxn')} >Recent Transactions</Box>      
      </Box>
    </>  

   <TableContainer>
      <Table sx={{ minWidth: 500 }}
        aria-labelledby="tableTitle"
        size='small'
      //{dense ? 'small' : 'medium'}
      >
        <EnhancedTableHead
          numSelected=''
          order='asc'
          // orderBy= 'fundName'
          onSelectAllClick=""
          onRequestSort=""
          rowCount=""
          tabSelected={showFlag === 'sip' ? 'portfolio' : showFlag}
        />
        {((showFlag === 'portfolio') || showFlag === 'sip') ?
        <TableBody>
          {myPortfolioList.map((row) => (
            <TableRow
              key={row.fundName}
           //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Box style={{ ...fcJc, ...text14 }}>
                  <Box style={{ ...frAs }} onClick={() => fundNameHandler(row)}>
                    <LinkTextSM style={{ textAlign: 'inherit' }}>{nipponIcon}</LinkTextSM>
                    {row.fundName}
                  </Box>
                  <Box style={{...frAs, ...smarterSavingsButton}}>
                    <LinkTextSM style={{marginBottom: '2px'}}>{savingIcon}</LinkTextSM>
                    <LinkTextSM style={{  ...text12lh15, marginLeft: '8px', marginTop: '2px',color: '#081320' }} fontFamily={fonts.rubikNormal.value}>Smarter Savings</LinkTextSM>
                  </Box>
                  <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6" }}>Folio{row.folioNo}</LinkTextSM>
                </Box>
              </TableCell>
              <TableCell align="left">
                <Box>
                {row.invested}
                <LinkTextSM style={{ textAlign: 'inherit'}}></LinkTextSM>
                <LinkTextSM style={{ textAlign: 'inherit'}}></LinkTextSM>
                </Box>
                </TableCell>
              <TableCell align="left">
                <Box style={{ ...fcJc, ...text14 }}>
                  {row.returns}
                  <LinkTextSM style={{ textAlign: 'inherit', color: "#1A936F" }}>({row.returnPercent})</LinkTextSM>
                </Box>
              </TableCell>
              <TableCell align="left">
                <Box style={{ ...fcJc, ...text14 }}>
                  {row.value}
                  <Box style={{ ...frJc }}>
                    <LinkTextSM style={{ textAlign: 'inherit', color: "#9CA1A6" }}>XIRR</LinkTextSM>
                    <LinkTextSM style={{ textAlign: 'inherit', color: "#1A936F", lineHeight: '18px' }}>{arrowIcon}</LinkTextSM>
                    <LinkTextSM style={{ textAlign: 'inherit', color: "#1A936F" }}>{row.xirr}</LinkTextSM>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}

        </TableBody> : 

        <TableBody>
        {recentTransactionsList.map((row) => (
          <TableRow
            key={row.fundName}
        //   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Box style={{ ...fcJc, ...text14 }}>
                <Box style={{ ...frAs, ...cursorPointer }}>
                  <LinkTextSM style={{ textAlign: 'inherit' }}>{nipponIcon}</LinkTextSM>
                  {row.fundName}
                </Box>
                <Box style={{ ...frAs }}>
                  <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6" }}>{sipPurchaseIcon}</LinkTextSM>
                  <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6" }}>SIP purchase</LinkTextSM>
                  <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6" }}>Folio{row.folioNo}</LinkTextSM>
                </Box>
              </Box>
            </TableCell>
            <TableCell align="left">
              <Box style={{ ...fcJc, ...text14 }}>
                {row.value}
                <Box style={{...frAs}}>
                  <LinkTextSM style={{ textAlign: 'inherit', color: "#1A936F" }}>{executedIcon}</LinkTextSM>
                  <LinkTextSM style={{ textAlign: 'inherit', color: "#1A936F" }}>Executed</LinkTextSM>
                </Box>
              </Box>
            </TableCell>
            <TableCell align="left">
              <Box style={{ ...fcJc, ...text14 }}>
                {row.date}
              </Box>
            </TableCell>
          </TableRow>
        ))}
        </TableBody>
         }
      </Table>
    </TableContainer> 
    </div>
  )
}

export default TransactionList;