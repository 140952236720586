import { Route } from 'react-router';
import { onboardingRoutes } from './constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { getParamsFromUrl } from '../helper-methods/index';
import { useEffect } from 'react';
export const useGenerateRoutes = (sectionsList) => {
  const location = useLocation();
  const { search } = location;
  const navigate = useNavigate();

  const initialiseOnboarding = () => {
    console.log('Initialise onboarding called  ');
    let token = null;
    let source = null;
    let platform = null;
    let appVersion = null;
    let appName = null;
    let platformId = null;
    let deviceId = null;

    if (location?.search && location.search.includes('token')) {
      token = getParamsFromUrl('token', location.search);
      source = getParamsFromUrl('source', location.search);
      platform = getParamsFromUrl('platform', location.search);
      appVersion = getParamsFromUrl('x-app-version', location.search);
      appName = getParamsFromUrl('x-app-name', location.search);
      platformId = getParamsFromUrl('x-platform-id', location.search);
      deviceId = getParamsFromUrl('x-device-id', location.search);

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('source', source);
      sessionStorage.setItem('platform', platform);
      sessionStorage.setItem('appVersion', appVersion);
      sessionStorage.setItem('appName', appName);
      sessionStorage.setItem('platformId', platformId);
      sessionStorage.setItem('deviceId', deviceId);
    }
  };

  useEffect(() => {
    console.log('checking location:', location.pathname);
    // this will after the token validation is added
    // ******* DONOT REMOVE ***************

    //    if(!sessionStorage.getItem("token") || !(location &&  location.search.includes("token"))  ) {
    //         window.loading.then(function (response) {
    //           if (response) {
    //             console.log("inside if---", response);
    //             let parsedValue = typeof response !== "object" ? JSON.parse(response) : response;
    //             console.log("parsed value ----", parsedValue);
    //             sessionStorage.setItem("token", parsedValue.token);
    //             sessionStorage.setItem("source", parsedValue.source);
    //             sessionStorage.setItem("platform", parsedValue.platform);
    //             sessionStorage.setItem("browserName", parsedValue.browser);
    //             sessionStorage.setItem("browserVersion", parsedValue.browserVersion);
    //             sessionStorage.setItem("isMobileBrowser", Number(parsedValue.isMobile) ? true : false);
    //           }
    //         });
    //       }
    initialiseOnboarding();
    if (
      location.pathname === '/DigilockerRedirection' ||
      location.pathname === '/EsignRedirection'
    )
      return;
    console.log('Navigating to doggy');
    navigate(`/${search}`);
  }, []);

  return sectionsList?.map((section) => {
    return location.pathname === '/EsignRedirection' ? (
      <Route
        exact={false}
        path={'/EsignRedirection/'}
        element={onboardingRoutes['esignRedirect'].component}
        section={section}
        key={section}
      />
    ) : location.pathname === '/DigilockerRedirection' ? (
      // ||
      //   (location?.search &&
      //     getParamsFromUrl('status', location.search) === 'success' &&
      //     getParamsFromUrl('digio_doc_id', location.search)?.length)
      <Route
        exact={false}
        path={'/DigilockerRedirection/'}
        element={onboardingRoutes['digilockerRedirect'].component}
        section={section}
        key={section}
      />
    ) : location.pathname === '/waitingScreen' ? (
      <Route
        exact={false}
        path={'/waitingScreen/'}
        element={onboardingRoutes['waitingScreen'].component}
        section={section}
        key={section}
      />
    ) : (
      <Route
        exact={false}
        path={`${onboardingRoutes[section].path}`}
        element={onboardingRoutes[section].component}
        section={section}
        key={section}
      />
    );
  });
};
