import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useTheme, Box, withStyles } from "@material-ui/core";

import { useCustomQuery, useSampleJson } from "../hooks/fetchQuery";
import FullpageLoader from "../components/shared/FullpageLoader";
import Error from "../components/shared/Error";
import { setCustomQuery, getSectionData } from "../helper-methods";
import HeaderText from "../components/shared/HeaderText";
import SecondaryText from "../components/shared/SecondaryText";
import Button from "../components/StyledComponents/Button";
import Text from "../components/shared/Text";
import {
  fr,
  fc,
  fcAc,
  fcJsb,
  frAc,
  inheritHeight,
  inheritSize,
} from "../utilities/styles";
import successIcon from "../assets/success.svg";
import failureIcon from "../assets/failure.svg";
import { StyledBox } from "../components/StyledComponents/StyledBox";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateMapping } from "../helper-methods/constants";


const SectionBox = withStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    opacity: (props) => (props.disabled ? "0.5" : "1"),
    border: `1px solid rgba(234, 234, 234, 1)`,
    borderRadius: `${theme.spacing(0.5)}px`,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(1.8)}px ${theme.spacing(
      1
    )}px ${theme.spacing(0.5)}px`,
    marginBottom: `${theme.spacing(2.5)}px`,
    width: "95%",
  },
}))(Box);

const Home = ({ section }) => {
  const { isLoading, isError, data, isFetching, refetch } = useSampleJson();
  const sectionQuery = useCustomQuery({ section: section });
  const [nextPath, setNextPath] = useState(null);
  const theme = useTheme();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: customerStatus } = useCustomQuery({ section: 'customerStatus' });
  const redirectTo = (path) => {
    console.log("The section in home :",path)
    if (!navigateMapping[path]) {
      navigate(`${path}${search}`);
      return;
    }
    setCustomQuery({
      section: "backPath",
      data: {
        redirectToHomeFrom: path,
        backPath: "/home",
      },
      queryClient,
    });
    navigate(`${navigateMapping[path]}${search}`);
  };

  useEffect(async () => {
    if (data) return;
    await refetch();
  }, []);

  useEffect(() => {
    if (!sectionQuery.data) navigate(`/${search}`);
  }, []);

  const onContinueClick=()=>{
    navigate(`${nextPath}${search}`)
  }

  if (isLoading || isFetching)
    return (
      <>
        <FullpageLoader />
      </>
    );

  if (isError) {
    return (
      <>
        <Error />
      </>
    );
  }

  return (
    <div style={{ ...inheritSize, ...fcAc }}>
      <StyledBox style={{ ...inheritHeight, ...fcJsb, ...fcAc }}>
        <div>
          <HeaderText>{sectionQuery.data?.home.topHeading}</HeaderText>
          <SecondaryText style={{ marginBottom: `${theme.spacing(2.5)}px` }}>
            {sectionQuery.data?.home.topSubHeading}
          </SecondaryText>
          {sectionQuery.data?.accountActivationData.map((section) => {
            if (section?.nextPath && section?.nextPath !== nextPath)
              setNextPath(section.nextPath);
            return (
              <SectionBox
                disabled={section?.disabled}
                key={section.displayName}
                style={{ ...fr }}
                onClick={
                  !section?.disabled
                    ? section?.completed
                      ? () => redirectTo(section.onClickRedirection)
                      : () => redirectTo(section.nextPath)
                    : null
                }
              >
                <img
                  src={section?.iconUrl}
                  style={{ width: "85px", marginRight: "15px" }}
                />
                <Box style={{ ...fc }}>
                  <Box
                    style={{ ...frAc, marginBottom: `${theme.spacing(0.5)}px` }}
                  >
                    {!section?.disabled ? (
                      section?.completed ? (
                        <img
                          src={successIcon}
                          style={{ width: "15px", marginRight: "8px" }}
                        />
                      ) : (
                        <img
                          src={failureIcon}
                          style={{ width: "15px", marginRight: "8px" }}
                        />
                      )
                    ) : section?.completed ? (
                      <img
                        src={successIcon}
                        style={{ width: "15px", marginRight: "8px" }}
                      />
                    ) : null}
                    <Text style={{ color: theme.palette.common.darkBlack }}>
                      {section?.displayName}
                    </Text>
                  </Box>
                  <Text
                    fontSize={12}
                    style={{
                      lineHeight: "18px",
                    }}
                  >
                    {section?.description}
                  </Text>
                </Box>
              </SectionBox>
            );
          })}
        </div>
        <Button
          onClick={onContinueClick}
          // navigate(`${nextPath}${search}`)
          style={{ marginTop: "10px" }}
        >
          Continue
        </Button>
      </StyledBox>
    </div>
  );
};

export default Home;
