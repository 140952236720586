import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from 'react-router-dom';

import { useCustomQuery, useSampleJson } from "../hooks/fetchQuery";
import FullpageLoader from "../components/shared/FullpageLoader";
import Error from "../components/shared/Error";
import { setCustomQuery, getSectionData } from "../helper-methods";
import InitialLoaderPage from "../components/InitialLoader";

const Status = ({ section }) => {
  const navigate=useNavigate()
  const { isLoading, isError, data, isFetching, refetch } = useSampleJson();
  const sectionQuery = useCustomQuery({ section: section });
  const queryClient = useQueryClient();

  useEffect(async () => {

    navigate("/accountDetails/kycDetails")

    if (data) return;
    await refetch();
  }, []);

  useEffect(() => {
    if (isLoading || isFetching || sectionQuery.data) return;

    setCustomQuery({
      section: section,
      data: data
        ? getSectionData({ response: data, section: section })[section]
        : null,
      queryClient: queryClient,
    });
  }, [data]);

  if (isLoading || isFetching)
    return (
      <>
        <FullpageLoader />
      </>
    );

  if (isError) {
    return (
      <>
        <Error />
      </>
    );
  }

  return (
    <>
      <InitialLoaderPage />
    </>
  );
};

export default Status;
