import failureIcon from '../assets/failure.svg';
import successIcon from '../assets/success.svg';

export const sampleJson = {
  regex: {
    EMAIL: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    DIGITS: /^[0-9]+$/,
    MOBILE_NUMBER: /^[0-9]{4,20}$/,
    MOBILE_NUMBER_INDIA: /^[1-9][0-9]{9}$/,
    //  MOBILE_NUMBER:  (/^\+[0-9]{1,6}\-[0-9]{3,14}$/)|(/^[0-9]{4,20}$/),
    AMOUNT_ANY_DP1: /^[0-9]*\.[0-9]*$/, //will also match '.'
    AMOUNT_ANY_DP: /^[+-]?(\d*\.)?\d+$/,
    //the regex should allow 0, 0.0, .0, .5 etc but should not allow just '.'
    AMOUNT_2DP: /^(([0-9]+(\.[0-9]?[0-9]?)?)|(\.[0-9][0-9]?))$/,
    PAN: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
    IFSC: /^[A-Za-z0-9]{11}$/, // 11 alphanumeric characters
    WORDS: /^[a-zA-Z\./\s]+$/,
    FNAME: /^[a-zA-Z\.\s]+$/,
    SINGLE_ALPHABET_CAPS: /^[A-Z]+$/,
    ALPHA_NUMERIC: /^[a-zA-Z0-9]+$/,
    ALPHA_NUMERIC_WITH_DASH_COMMA: /^[a-zA-Z0-9\-\,\&\.\s]+$/,
    BRANCH_NAME: /^[a-zA-Z\s\-,0-9]+$/,
    ADDRESS: /^[a-zA-Z0-9\#&-\\\./,\r\n\s]+$/,
    DATE: /^[1|2]\d{3}-[0|1][0-9]-[0-3][0-9]$/,
    DATE_DMY: /^[0-3][0-9]-[0|1][0-9]-[1|2]\d{3}$/,
    INDIAN_PINCODE: /^\d{6}/,
    GENERAL_PINCODE: /^[A-Za-z0-9\-\s]{4,8}$/,
    ALPHA_NUMERIC_CAN_NULL: /^$|^[a-zA-Z0-9]+$/,
    BANK_ACCOUNT_NUMBER: /^[A-Za-z0-9]{5,25}$/,
    AADHAARNUMBER: /^\d{4}\d{4}\d{4}$/,
    GLIDE_PATH: /^[0-9.;]+$/,
    INV_SCHEDULE: /^[0-9.;]+$/,
    IP: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
    NOTIFICATION_RECIPIENT: /^[ A-Za-z0-9_@./#&$*+-]*$/,
    SINGLE_ALPHABET_LOWER: /^[a-z]+$/,
  },
  messages: {
    common: {
      SUCCESS: 'success',
      FAILURE: 'failure',
      EXPIRED: 'expired',
      ERROR: 'error',
      REQUEST_INVALID: 'Invalid Request',
      PAN_INVALID: 'Looks like you mistyped your PAN Number. Try again.',
      PAN_KYC_REGISTERED:
        "Woohoo! You are already KYC compliant! Someone's been investing early. ,)",
      PAN_ALREADY_REGISTERED: 'This PAN is already registered with us!',
      INVALID_IMAGE_FILE: 'Make sure image file is valid!',
      NO_INPUT: 'No Input',
      CONTACT_SUPPORT:
        'This action could not be completed. Please contact support for help.',
      ADDRESS_COMM_UPDATE_SUCCESS:
        'Successfully updated communication address.',
      TXN_SUM_NOT_MATCHED:
        'Total transaction amount and amount distributed among Mutual Funds did not match.',
      DATA_EMPTY: 'Data sent was empty',
      SERVER_ERROR: 'Servers on a tea break.',
      GRAPH_NO_DATA: 'GRAPH_NO_DATA',
      NOTIFICATION_TRACKING_VALIDATION: 'Invalid inputs !!!',
      NO_USER_FOUND: 'No user record found ',
      MODE_EMAIL_ERROR: "Notification mode should be 'email'",
      INPUT_VALIDATION_ERROR: 'Invalid inputs !!!',
      INVALID_COUNTRY_HEADING: "You don't seem to be in India",
      INVALID_COUNTRY_PARAGRAPH_1:
        "As per SEBI's new KYC regulations, you must be in India when you are registering your KYC application. You can retry this process once you return to India.",
      INVALID_COUNTRY_PARAGRAPH_2:
        'If you thing this is a mistake, try switching from WiFi to a 4G connection, or connect to a home WiFi instead of an office WiFi connection. You can then try submitting your application again.',
      KYC_LOADING_SCREEN: {
        heading: 'Complete registration',
        description:
          'We just need to check a few things before starting your registration',
        imageUrl:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/kyc-onboarding/verify_details.png',
      },
      EXTERNAL_API_ERROR: {
        heading: "Uh oh! Something's not right",
        description:
          'There seems to be some technical issue. Please try again later or get in touch with us for additional assistance.',
        imageUrl: '',
        showImage: true,
        imageUrl: failureIcon,
        ctaText: 'Okay',
        nextAction: '',
        secondaryAction: '',
      },
    },
    Signature: {
      UPLOAD_ERROR: {
        heading: 'Error Occurred',
        description: `We couldn't upload the signature. Please try again. Reach out to us if you have any queries.`,
        showImage: true,
        imageUrl: failureIcon,
        ctaText: 'Got It',
        nextAction: '',
        secondaryCta: '',
        secondaryAction: '',
      },
      FAILURE: {
        heading: 'Error Occurred',
        description: `There seems to some error. Please try again. Reach out to us if you have any queries.`,
        showImage: true,
        imageUrl: failureIcon,
        ctaText: 'Got It',
        nextAction: '',
      },
    },
    Cheque: {
      UPLOAD_ERROR: {
        heading: 'Error Occurred',
        description: `We couldn't upload you documents. Please try again. Reach out to us if you have any queries.`,
        showImage: true,
        imageUrl: failureIcon,
        ctaText: 'Got It',
        nextAction: '',
        secondaryCta: '',
        secondaryAction: '',
      },
      FAILURE: {
        heading: 'Error Occurred',
        description: `There seems to some error. Please try again. Reach out to us if you have any queries.`,
        showImage: true,
        imageUrl: failureIcon,
        ctaText: 'Got It',
        nextAction: '',
      },
    },
    OnlineKyc: {
      Digilocker: {
        POLLING: {
          topNote: 'We are fetching your documents from Digilocker',
          bottomNote: '…this will take about',
          timeUnit: 'seconds',
        },
        SUCCESS: {
          heading: '',
          description: 'Redirecting you to digilocker in a moment...',
          showImage: true,
          imageUrl: successIcon,
          // ctaText: 'Next',
          nextAction: '',
        },
        INCOMPLETE: {
          heading: 'Verification incomplete',
          description: `Our system was unable to verify your bank details automatically so our team will verify them manually once you have completed the rest of the registration process. Our team will verify these details within 1 business day. However, you can start investing right after completing the registration process.`,
          showImage: false,
          ctaText: 'Next',
          nextAction: '',
        },
        FAILURE: {
          heading: 'Digilocker process could not be completed',
          description: `We have not received the documents from Digilocker. In order to create your investment account, we need a copy of Aadhaar and PAN Card from Digilocker.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Retry',
          nextAction: '/onlineKyc/digilocker',
        },
      },
      LivelinessCheck: {
        NO_CAMERA_FOUND: {
          heading: 'No Camera available',
          description: `We couldn't detect a camera on your device. Please login from a device with a camera to continue. A Live Selfie is mandatory.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Got It',
          nextAction: '',
          secondaryCta: '',
          secondaryAction: '',
        },
        CAMERA_PERMISSION_DENIED: {
          heading: 'Camera Permission Denied',
          description: `We couldn't access the camera on your device. Please allow camera access from your device to continue. A Live Selfie is mandatory.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Got It',
          nextAction: '',
          secondaryCta: '',
          secondaryAction: '',
        },
        ERROR_STARTING_CAMERA: {
          heading: 'Error Occurred',
          description: `We couldn't start your camera for video kyc. Please try again. Reach out to us if you have any queries.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Got It',
          nextAction: '',
          secondaryCta: '',
          secondaryAction: '',
        },
        POLLING: {
          description: 'We are submitting your video kyc details',
        },
        UPLOAD_ERROR: {
          heading: 'Error Occurred',
          description: `We couldn't upload the Live Selfie. Please try again. Reach out to us if you have any queries.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Got It',
          nextAction: '',
          secondaryCta: '',
          secondaryAction: '',
        },
        TIME_EXCEED: {
          heading: 'Time Exceeded',
          description: `Please complete all the challenges in the allowed time frame to proceed with video KYC`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Got It',
          nextAction: '',
          secondaryCta: '',
          secondaryAction: '',
        },
        FACE_MATCH_FAILURE: {
          heading: 'Face Mismatch Error',
          description: `We could not match your face. Please try video kyc again.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Got It',
          nextAction: '',
          secondaryCta: '',
          secondaryAction: '',
        },
        FAILURE: {
          heading: 'Error Occurred',
          description: `We couldn't upload the Live Selfie. Please try again. Reach out to us if you have any queries.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Got It',
          nextAction: '',
          secondaryCta: '',
          secondaryAction: '',
        },
      },
      Esign: {
        POLLING: {
          topNote: 'We are redirecting to Esign …',
          bottomNote: '…this will take about',
          timeUnit: 'seconds',
        },
        SUCCESS: {
          heading: '',
          description: 'Redirecting you to esign in a moment...',
          showImage: true,
          imageUrl: successIcon,
          // ctaText: "Next",
          nextAction: '',
        },
        PAN_NAME_MISMATCH_ERROR: {
          heading: 'Verification failed',
          description: `The PAN you entered did not completely match the PAN received from your Digilocker account.
          In order to create your investment account, the KYC details from Digilocker should exactly match with your PAN.
          Please re-submit your Digilocker documents or contact us from more info.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Re-submit Digilocker Documents',
          nextAction: '/onlineKyc/Digilocker',
        },
        PAN_NUMBER_MISMATCH_ERROR: {
          heading: 'Verification failed',
          description: `The PAN you entered did not completely match the PAN received from your Digilocker account.
          In order to create your investment account, the KYC details from Digilocker should exactly match with your PAN.
          Please re-submit your Digilocker documents or contact us from more info.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Re-submit Digilocker Documents',
          nextAction: '/onlineKyc/Digilocker',
        },
        DIGILOCKER_DATA_NOT_FOUND: {
          heading: 'eSign process could not be completed',
          description: `The KYC Form was not eSigned. In order to create your investment account, you need to eSign the KYC Form.’
          “Please retry or contact us from more info`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Retry eSign Now',
          nextAction: '/onlineKyc/esign',
        },
        CUSTOMER_PHOTO_AADHAAR_MISMATCH: {
          heading: 'Verification failed',
          description: `Our system was unable to verify your photo with with the AADHAAR. Please try  KYC video again.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Re-submit Video',
          nextAction: '/onlineKyc/livelinessCheck',
        },
        KYC_VIDEO_NOT_FOUND: {
          heading: 'Verification incomplete',
          description: `Our system was unable to find the KYC VIDEO . Please try kyc video again `,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Okay',
          nextAction: '/onlineKyc/livelinessCheck',
        },
        FACE_MATCH_FAILURE: {
          heading: 'Verification incomplete',
          description: `Our system was unable to recognise your face . Please try kyc video again `,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Okay',
          nextAction: '/onlineKyc/livelinessCheck',
        },

        INCOMPLETE: {
          heading: 'Verification incomplete',
          description: `Our system was unable to verify your details automatically so our team will verify them manually once you have completed the rest of the registration process. Our team will verify these details within 1 business day. However, you can start investing right after completing the registration process.`,
          showImage: false,
          ctaText: 'Next',
          nextAction: '',
        },
        FAILURE: {
          heading: 'Verification failure',
          description: `Esign process failure. Please try again. Reach out to us if you have any queries.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Okay',
          nextAction: '',
        },
      },
    },
    AccountDetails: {
      bankDetails: {
        POLLING: {
          topNote: 'We are depositing ₹1 in your bank account to verify it…',
          bottomNote: '…this will take about',
          timeUnit: 'seconds',
        },
        SUCCESS: {
          heading: 'Verification successful!',
          description:
            'Your bank details have been verified successfully. You can now continue with the registration process.',
          showImage: true,
          imageUrl: successIcon,
          ctaText: 'Next',
          nextAction: '/signature',
        },
        INCOMPLETE: {
          heading: 'Verification incomplete',
          description: `Our system was unable to verify your bank details automatically so our team will verify them manually once you have completed the rest of the registration process. Our team will verify these details within 1 business day. However, you can start investing right after completing the registration process.`,
          showImage: false,
          ctaText: 'Next',
          nextAction: '',
        },
        FAILURE: {
          heading: 'Verification failure',
          description: `The details you entered did not completely match the details fetched from your bank account records. Please make sure that you have entered all the details as they exactly appear in your bank records. 

          If you think this is a mistake, you can continue with the same details and upload a bank document as proof later.`,
          showImage: true,
          imageUrl: failureIcon,
          ctaText: 'Edit Bank Details',
          nextAction: '/cheque',
          secondaryCta: 'Upload Bank Proof',
          secondaryAction: '',
        },
      },
      panDetails: {
        POLLING: {
          topNote: 'We are fetching your pan details...',
          bottomNote: '…this will take about',
          timeUnit: 'seconds',
        },
        DOB_MISMATCH_ERROR: {
          type: 'DOB_MISMATCH_ERROR',
          errorScreenType: 'bottomSheet',
          showImage: true,
          imageUrl: failureIcon,
          heading: `Date of Birth doesn’t match`,
          reason: '',
          icon_size: 'small',
          description:
            'The date of birth entered by you does not match the one mentioned on your PAN card. Please enter the same date of birth as on PAN and try again. ',
          ctaAction: '',
          ctaText: ' Re-enter Date of Birth',
        },
        NAME_MISMATCH_ERROR: {
          fieldsObject: {
            value: '',
            key: 'panName',
            required: true,
            type: 'text',
            errors: [],
            displayName: 'PAN Card Name',
            help_text: '',
            minLength: 1,
            maxLength: 100,
            placeHolder: 'Enter PAN Card Name',
            errorMessage: 'Enter Valid Pan Name',
            minErrorMessage: 'Min length is 10 characters',
            maxErrorMessage: 'Max length is 10 characters',
            regex: '/^[A-Za-z]{3}[p|P]{1}[A-Za-z]{1}\\d{4}[A-Za-z]{1}$/',
            webImage:
              'https://alphafront.s3-ap-southeast-1.amazonaws.com/web_resources/pan.png',
          },
          type: 'NAME_MISMATCH',
          errorScreenType: 'bottomSheet',
          imageUrl:
            'https://alphafront.s3-ap-southeast-1.amazonaws.com/niyo/frozen-account.png',
          title: `Name doesn’t match`,
          reason: '',
          icon_size: 'small',
          description:
            'The name selected by you does not match the one mentioned on your PAN card. Please select the same name as on PAN and try again. ',
          inputLabel: '',
          ctaAction: '/accountDetails/panDetails',
          ctaText: ' Re-enter PAN ',
        },
      },
    },
  },
  jsonstructure: {
    review: {
      topHeading: 'All done!',
      topSubHeading:
        'Your Niyo Money account is now activated for investments!',
      cta: 'Submit',
    },
    registrationSuccessful: {
      topHeading: 'All done!',
      topSubHeading:
        'Your Niyo Money account is now activated for investments!',
      cta: 'Start Investing',
    },
    status: {
      topHeading: 'Complete registration',
      topSubHeading:
        'We just need to check a few things before starting your registration',
      cta: 'Continue',
      list: [
        {
          displayName: 'Checking your Mutual Fund KYC status',
          section: 'AccountDetails',
          tab: 'panDetails',
        },
        {
          displayName: 'Checking your Niyo Money account details.',
          section: 'AccountDetails',
          tab: 'panDetails',
        },
        {
          displayName:
            'Checking your current location. Please allow location access to complete this step.',
          section: 'AccountDetails',
          tab: 'panDetails',
        },
        {
          displayName: 'We are depositing Re. 1 to verify your bank details.',
          section: 'AccountDetails',
          tab: 'panDetails',
        },
        {
          displayName: 'Checking required documents.',
          section: 'AccountDetails',
          tab: 'panDetails',
        },
      ],
    },
    
    home: {
      topHeading: 'Activate Account',
      topSubHeading:
        'You need to complete the following steps to start investing in Mutual Funds.',
      cta: 'Continue',
    },
    sectionsList: ['AccountDetails', 'Cheque', 'Signature', 'OnlineKyc'],
    sectionsObject: {
      AccountDetails: {
        displayName: 'Account Creation',
        cta_text: 'Fill Account Details',
        key: 'AccountDetails',
        iconUrl:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/kyc-onboarding/account_details.png',
        description: 'Provide your Personal, Family and Bank Details',
        completed: false,
        disabled: false,
        onClickRedirection: 'basicDetails',
        tabsList: [
          'panDetails',
          'basicDetails',
          'kycDetails',
          'nomineeDetails',
          'bankDetails',
        ],
        tabsObject: {
          panDetails: {
            displayName: 'PAN Details',
            key: 'panDetails',
            cleverTapEventSectionKey: 'get_started',
            ctaText: 'Next',
            section_terms_header: 'I confirm that :',
            section_terms_description: [
              'I am an Indian National, Born in India and a tax resident of India (not an NRI)',
              'I am not politically exposed nor related to politically exposed person',
            ],
            fieldsList: ['pan', 'dob'],
            fieldsObject: {
              pan: {
                value: '',
                key: 'pan',
                required: true,
                type: 'text',
                errors: [],
                displayName: 'PAN Card Number',
                help_text: '',
                minLength: 10,
                maxLength: 10,
                placeHolder: 'Enter PAN Card Number',
                errorMessage:
                  'PAN format should be ABCPE1234F and should belong to an individual and not corporate/HUF',
                minErrorMessage: 'Min length is 10 characters',
                maxErrorMessage: 'Max length is 10 characters',
                regex: '/^[A-Za-z]{3}[p|P]{1}[A-Za-z]{1}\\d{4}[A-Za-z]{1}$/',
                webImage:
                  'https://alphafront.s3-ap-southeast-1.amazonaws.com/web_resources/pan.png',
              },
              dob: {
                value: '',
                key: 'dob',
                required: true,
                type: 'date',
                errors: [],
                displayName: 'Date of Birth',
                help_text: '',
                placeHolder: 'DD/MM/YYYY',
                errorMessage: '',
                note_text: 'You must be 18 years or older',
                webImage:
                  'https://alphafront.s3-ap-southeast-1.amazonaws.com/web_resources/dob.svg',
              },
            },
          },
          basicDetails: {
            displayName: 'Basic Details',
            key: 'basicDetails',
            ctaText: 'Next',
            fieldsList: ['occupation', 'maritalStatus', 'gender', 'income'],
            fieldsObject: {
              maritalStatus: {
                key: 'new_marital_status',
                required: true,
                preselect: true,
                value: 'Single',
                type: 'radio',
                displayName: 'Marital Status',
                list: [
                  {
                    label: 'Married',
                    value: 'Married',
                  },
                  {
                    label: 'Single',
                    value: 'Single',
                  },
                ],
                disabled: false,
                errors: [],
                appInputType: 'radio',
                placeHolder: '',
                errorMessage: '',
                note: '',
              },
              occupation: {
                key: 'occupation',
                required: true,
                preselect: false,
                value: '',
                type: 'select',
                displayName: 'Occupation',
                disabled: false,
                errors: [],
                appInputType: 'select',
                placeHolder: 'Choose Occupation',
                errorMessage: 'Please select occupation',
                note: '',
                list: [
                  {
                    label: 'Private Sector Service',
                    value: 'Private Sector Service',
                  },
                  {
                    label: 'Public Sector Service',
                    value: 'Public Sector Service',
                  },
                  {
                    label: 'Government Service',
                    value: 'Government Service',
                  },
                  {
                    label: 'Housewife',
                    value: 'Housewife',
                  },
                  {
                    label: 'Student',
                    value: 'Student',
                  },
                  {
                    label: 'Retired',
                    value: 'Retired',
                  },
                  {
                    label: 'Agriculturist',
                    value: 'AGRICULTURIST',
                  },
                  {
                    label: 'Self Employed',
                    value: 'SELF EMPLOYED',
                  },
                  {
                    label: 'Others',
                    value: 'Others',
                  },
                ],
              },
              gender: {
                key: 'gender',
                required: true,
                preselect: true,
                value: 'F',
                type: 'radio',
                list: [
                  {
                    label: 'Male',
                    value: 'M',
                  },
                  {
                    label: 'Female',
                    value: 'F',
                  },
                ],
                displayName: 'Gender',
                disabled: false,
                errors: [],
                appInputType: 'radio',
                placeHolder: '',
                errorMessage: '',
                note: '',
              },
              income: {
                key: 'income',
                required: true,
                value: '',
                type: 'select',
                toShow: true,
                disabled: false,
                errors: [],
                appInputType: 'select',
                errorMessage: 'Please select income range',
                note: '',
                list: [
                  {
                    label: 'Upto ₹ 1 Lakh',
                    value: '0.5',
                  },
                  {
                    label: '₹ 1 Lakh to 5 Lakh',
                    value: '1',
                  },
                  {
                    label: '₹ 5 Lakh to 10 Lakh',
                    value: '5',
                  },
                  {
                    label: '₹ 10 Lakh to 25 Lakh',
                    value: '10',
                  },
                  {
                    label: '₹ 25 Lakh to 1 Crore',
                    value: '25',
                  },
                  {
                    label: '> ₹ 1 Crore',
                    value: '100',
                  },
                ],
                displayName: 'Annual Income',
                help_text: '',
                placeHolder: 'Select Income range',
              },
            },
          },
          kycDetails: {
            displayName: 'KYC Details',
            key: 'kycDetails',
            completed: false,
            isValid: false,
            noRemaining: '',
            visited: false,
            ctaText: 'Next',
            fieldsList: ['motherName', 'fatherSpouseName', 'placeofBirth'],
            fieldsObject: {
              motherName: {
                key: 'motherName',
                required: true,
                value: '',
                type: 'text',
                disabled: false,
                errors: [],
                lengthMsg: 'characters',
                displayName: "Mother's Name",
                appInputType: 'text',
                placeHolder: "Enter Mother's Name",
                errorMessage: 'Enter only letters',
                minLength: 1,
                maxLength: 100,
                minErrorMessage: 'Min length is 1 characters',
                maxErrorMessage: 'Max length is 100 characters',
                regex: '/^[a-zA-Z\\.\\s]+$/',
                note: '',
              },
              fatherSpouseName: {
                key: 'fatherSpouseName',
                required: true,
                value: '',
                type: 'text',
                disabled: false,
                errors: [],
                lengthMsg: 'characters',
                displayName: "Father/Spouse's Name",
                appInputType: 'text',
                placeHolder: "Enter Father/Spouse's Name",
                errorMessage: 'Enter only letters',
                minLength: 1,
                maxLength: 100,
                minErrorMessage: 'Min length is 1 characters',
                maxErrorMessage: 'Max length is 100 characters',
                regex: '/^[a-zA-Z\\.\\s]+$/',
                note: '',
              },
              placeofBirth: {
                key: 'placeofBirth',
                required: true,
                value: '',
                type: 'text',
                toShow: true,
                disabled: false,
                errors: [],
                displayName: 'Place of Birth',
                appInputType: 'text',
                placeHolder: 'Enter your Place of Birth',
                errorMessage: 'Enter valid Place of Birth',
                minLength: 1,
                maxLength: 30,
                minErrorMessage: 'Min length is 1 characters',
                maxErrorMessage: 'Max length is 30 characters',
                regex: '^[a-zA-Z\\s]*$',
                note: '',
              },
            },
          },
          bankDetails: {
            displayName: 'Bank Details',
            key: 'bankDetails',
            cleverTapEventSectionKey: 'bank_details',
            completed: false,
            disabled: true,
            isValid: false,
            noRemaining: '',
            visited: false,
            ctaText: 'Next',
            sectionNote:
              'Please enter the details of the bank account you will use to invest. This bank account MUST belong to the PAN card holder, otherwise investments will be rejected.',
            fieldsList: ['accountNumber', 'bankIfscCode'],
            fieldsObject: {
              // accountname: {
              //   key: 'accountname',
              //   required: true,
              //   value: '',
              //   type: 'text',
              //   disabled: false,
              //   errors: [],
              //   lengthMsg: 'characters',
              //   toShow: true,
              //   displayName: "Account holder's name (as in bank records)",
              //   help_text:
              //     'Please enter details of the bank account you will use to invest. You will not be able to invest through any other bank account.',
              //   appInputType: 'text',
              //   placeHolder: 'Account Holder’s Name',
              //   errorMessage: 'Enter only letters',
              //   minLength: 1,
              //   maxLength: 100,
              //   minErrorMessage: 'Min length is 1 characters',
              //   maxErrorMessage: 'Max length is 100 characters',
              //   regex: '/^[a-zA-Z\\.\\s]+$/',
              //   note: '',
              //   displayNote: {
              //     lineHeight: 20,
              //     backgroundColor: '#EDFAFF',
              //     textColor: '#666666',
              //     displayText:
              //       'Please enter details of the bank account you will use to invest. Bank account MUST belong to the PAN holder otherwise investments will be rejected.',
              //   },
              // },
              // accounttype: {
              //   key: 'accounttype',
              //   required: true,
              //   value: 'SB',
              //   type: 'select',
              //   preselect: true,
              //   list: [
              //     {
              //       label: 'Savings Account',
              //       value: 'SB',
              //     },
              //     {
              //       label: 'Current Account',
              //       value: 'CA',
              //     },
              //   ],
              //   disabled: false,
              //   errors: [],
              //   displayName: 'Bank Account Type',
              //   appInputType: 'select',
              //   placeHolder: 'Select Bank Account Type',
              //   errorMessage: '',
              //   note: '',
              // },
              accountNumber: {
                key: 'accountNumber',
                required: true,
                value: '',
                type: 'text',
                toShow: true,
                disabled: false,
                errors: [],
                minLength: 9,
                maxLength: 18,
                lengthMsg: 'characters',
                displayName: 'Bank Account Number',
                appInputType: 'text',
                placeHolder: 'Enter your Bank Account Number',
                errorMessage: 'Enter valid Bank Account Number',
                minErrorMessage: 'Min length is 5 characters',
                maxErrorMessage: 'Max length is 25 characters',
                regex: '/^[A-Za-z0-9]{5,25}$/',
                note: '',
              },
              bankIfscCode: {
                key: 'bankIfscCode',
                required: true,
                value: '',
                type: 'text',
                disabled: false,
                errors: [],
                minLength: 11,
                maxLength: 11,
                lengthMsg: 'characters',
                displayName: 'Bank IFSC',
                conditional_data: '',
                show_ifsc_search_button: true,
                ajaxError: false,
                loading: false,
                appInputType: 'textWithAPI',
                placeHolder: 'Enter IFSC',
                errorMessage: 'Enter valid IFSC',
                minErrorMessage: 'Min length is 11 characters',
                maxErrorMessage: 'Max length is 11 characters',
                regex: '/^[A-Za-z0-9]{11,11}$/',
                note: '',
              },
            },
          },
          nomineeDetails: {
            displayName: 'Nominee Details',
            key: 'nomineeDetails',
            cleverTapEventSectionKey: 'nominee_details',
            completed: false,
            disabled: true,
            isValid: false,
            noRemaining: '',
            visited: false,
            ctaText: 'Next',
            secondaryCtaText: 'Skip',
            fieldsList: [
              'nomineeRelationship',
              'nomineeName',
              'nomineeDob',
              'guardianName',
            ],
            fieldsObject: {
              nomineeRelationship: {
                key: 'nomineeRelationship',
                required: true,
                value: '',
                type: 'select',
                toShow: true,
                displayName: 'Nominee is your ',
                disabled: false,
                errors: [],
                appInputType: 'select',
                placeHolder: 'Select your relation with Nominee',
                errorMessage: 'Please select nominee',
                note: '',
                list: [
                  {
                    label: 'Spouse',
                    value: 'Spouse',
                  },
                  {
                    label: 'Mother',
                    value: 'Mother',
                  },
                  {
                    label: 'Father',
                    value: 'Father',
                  },
                  {
                    label: 'Brother',
                    value: 'Brother',
                  },
                  {
                    label: 'Sister',
                    value: 'Sister',
                  },
                  {
                    label: 'Child',
                    value: 'Child',
                  },
                  {
                    label: 'Other',
                    value: 'Other',
                  },
                ],
              },
              nomineeName: {
                key: 'nomineeName',
                required: true,
                value: '',
                type: 'text',
                disabled: false,
                errors: [],
                toShow: true,
                displayName: "Nominee's Name",
                appInputType: 'text',
                placeHolder: "Enter Nominee's Name",
                errorMessage: 'Enter only letters',
                minLength: 1,
                maxLength: 100,
                minErrorMessage: 'Min length is 1 characters',
                maxErrorMessage: 'Max length is 100 characters',
                regex: '/^[a-zA-Z\\.\\s]+$/',
                note: '',
              },
              nomineeDob: {
                key: 'nomineeDob',
                required: true,
                value: '',
                type: 'date',
                disabled: false,
                errors: [],
                startDate: '',
                endDate: '2022-03-21',
                displayName: "Nominee's Date of Birth",
                appInputType: 'date',
                placeHolder: "Select Nominee's Date of Birth",
                errorMessage: '',
                note: '',
              },
              guardianName: {
                key: 'guardianName',
                required: false,
                value: '',
                type: 'text',
                disabled: false,
                lengthMsg: 'characters',
                errors: [],
                displayName: "Nominee's Guardian's Name",
                help_text: '',
                bottom_text: 'Needed as the nominee is below 18 years of age',
                hide: true,
                appInputType: 'text',
                placeHolder: "Enter Nominee's Guardian's Name",
                errorMessage: 'Enter only letters',
                minLength: 1,
                maxLength: 100,
                minErrorMessage: 'Min length is 1 characters',
                maxErrorMessage: 'Max length is 100 characters',
                regex: '/^[a-zA-Z\\.\\s]+$/',
                note_text: 'Needed as the nominee is below 18 years of age',
                note: '',
              },
            },
          },
          review: {
            displayName: 'Review',
            key: 'review',
            completed: false,
            visited: false,
            ctaText: 'Next',
          },
        },
      },
      Cheque: {
        actionType: 'upload',
        sectionImageUrl:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/kyc-onboarding/bank_document.png',
        sectionDescription:
          'Submit a copy of your bank statement, cancelled cheque or Bank Passbook',
        key: 'Cheque',
        completed: false,
        onClickRedirection: 'cheque',
        visited: false,
        verified: false,
        actionRequired: false,
        displayTitle: 'Submit bank document',
        cta_text: 'Upload Bank Document',
        edit_text: 'Edit Bank Document',
        edit_btn_text: 'Edit',
        disabled: false,
        display_camera_title: 'Take a picture of your bank document',
        display_camera_sub_title: 'Picture should fit inside the box',
        display_upload_title: 'Upload your bank document',
        displayTitle_success: 'Bank statement or cancelled cheque or passbook',
        displayName: 'Bank Document',
        type: 'photo-upload',
        format: '',
        required: 'image-pdf',
        imageUrl: '/ajax/download-image?imagefor=Cheque',
        name: 'Cheque',
        endpoint: '/ajax/upload-document',
        note_header: 'Note :',
        secure_text:
          'Niyo Money guarantees bank-grade safety and privacy for all your personal data. We only share relevant data with authorized agencies like MF companies and SEBI regd. KYC agencies.',
        note_text:
          'Please submit either a bank statement, a cancelled cheque, or a bank passbook. It should have your name, account number and IFSC clearly visible.',
        imageAvailable: false,
        pdfOnline: false,
        iosInputSources: ['camera', 'gallery', 'files'],
        androidInputSources: ['camera', 'gallery'],
        isCroppedEnabled: true,
        placeholderImage:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/userformdynamic/bank_statement_icon.png',
        niyoxPlaceholderImage:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/niyo/userform/cheque.png',
        aspectRatioX: -1,
        aspectRatioY: -1,

        note_desc:
          'Please submit either a bank statement, a cancelled cheque, or a bank passbook. It should have your name, account number and IFSC clearly visible.',
        verifiedText: 'Cheque verified successfully',
        pdfPassword: {
          tooltipText:
            'Your bank proof requires a password, please share to allow us to open the document',
          placeholder: 'Password',
          type: 'password',
          note_text: 'Password Protected PDF',
        },
      },
      Signature: {
        sectionImageUrl:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/kyc-onboarding/signature.png',
        sectionDescription:
          'Submit a clear signature by simply signing on the screen',
        actionType: 'upload',
        disabled: true,
        displayName: 'Signature',
        displayTitle: 'Signature',
        cta_text: 'Sign on Screen',
        edit_text: 'Edit Signature',
        edit_btn_text: 'Edit',
        retry_text: 'Sign Again',
        upload_text: 'Upload Signature',
        onClickRedirection: 'signature',
        display_camera_title: 'Take a picture of your signature',
        display_camera_sub_title:
          'Please sign on a white blank piece of paper.',
        displayTitle_success: 'Specimen Signature',
        key: 'Signature',
        visited: false,
        completed: false,
        verified: false,
        actionRequired: false,
        available: false,
        format: '',
        imageUrl: '/ajax/download-image?imagefor=Signature',
        type: 'photo-upload',
        required: 'image',
        value: '',
        endpoint: '/ajax/upload-document',
        note_text:
          'Submit signature to be sent to MF companies for their records (on white background/paper)',
        secure_text:
          'Signature should be same as that on PAN Card. Niyo Money guarantees bank-grade safety and privacy for all your personal data. We only share relevant data with authorized agencies like MF companies and SEBI regd. KYC agencies.',
        name: 'Signature',
        iosInputSources: ['camera', 'signaturePad'],
        androidInputSources: ['camera', 'signaturePad'],
        isCroppedEnabled: true,
        aspectRatioX: 240,
        aspectRatioY: 110,
        checkNote:
          'Please provide consent to store your signature. We ensure to keep it safely with us.',
        placeholderImage:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/userformdynamic/signature_icon.png',
        niyoxPlaceholderImage:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/niyo/userform/signature.png',
        note_header: 'Note',
        note_desc:
          'Please provide a clear signature for your mutual fund KYC records. Use your finger/stylus to sign your name on the screen.',
        verifiedText: 'Signature verified successfully',
        note_box: 'Please submit an image of your signature on a clean white paper preferably in black ink. If the sign is not clear, it might be rejected'
      },
      OnlineKyc: {
        displayName: 'Online KYC',
        cta_text: 'Complete Online Kyc',
        key: 'OnlineKyc',
        onClickRedirection: 'digilocker',
        disabled: true,
        completed: false,
        sectionImageUrl:
          'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/niyo-money/selfie_male.png',
        sectionDescription:
          'Keep your Aadhaar Number and PAN Number ready to complete',
        tabsList: ['Digilocker', 'LivelinessCheck', 'Esign'],
        tabsObject: {
          Digilocker: {
            displayName: 'KYC Documents',
            niyoxPlaceholderImage:
              'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/niyo-money/online_kyc.png',
            cta_text: 'Proceed',
            completed: false,
            disabled: true,
            description: {
              title:
                'We will fetch the following documents from your digilocker account',
              subTitle:"Please make sure that your Aadhaar is linked to your PAN.",  
              items: ['Aadhaar Card', 'PAN Card'],
              helpText:
                'DigiLocker is a Govt of India digital storage service for Aadhaar holders to store documents like PAN card, driving license, vehicle registration, etc. Our partner Digio will access your details through DigiLocker account. If you dont have a Digilocker account, the process will create one for you',
            },
            note: {
              text: 'Your Aadhaar should be linked with a valid mobile number on which you can receive the OTP',
              cta_text: 'Learn More',
              helpText:
                'In order to allow Niyo to access your documents, Digilocker will send an OTP to your mobile linked to your Aadhaar Card.',
            },
            errorToast: '',
          },
          LivelinessCheck: {
            displayName: 'KYC Video',
            niyoxPlaceholderImageMale:
              'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/niyo-money/selfie_male.png',
            niyoxPlaceholderImageFemale:
              'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/niyo-money/selfie_female.png',
            cta_text: 'Start recording',
            proceedBtn: 'Take a 5 Sec KYC Video',
            waitingTxt: 'Pemission Required',
            cta_text_2: 'Submit video',
            completed: false,
            disabled: true,
            note: {
              text: 'Submit a short 5 second video with your face clearly visible.',
              text_2: 'Looks good. Proceed to video kyc.',
              text_3:
                'We’re facing error in  OTP delivery for digilocker process. Please try after 1 hour',
              text_5: `NO CAMERA FOUND : Cannot proceed with video kyc`,
              text_6: `ERROR_STARTING_CAMERA: Error starting selfie`,
              text_7: `EXTERNAL SERVER API`,
              text_8: `Camera access is MANDATORY to proceed with Video Kyc. Please allow camera access.`,
              text_10: `We couldn't upload the Live Selfie. Please try again`,
              text_11: `We could not match your face. Please try again.`,
            },
            videoText: {
              text1: 'Video is succefully captured',
              text2: 'Move the tip of your nose inside the yellow area',
              text3: 'Time left to complete the video process',
            },
            kyc_success_msg:
              'KYC Video successfully captured. Taking you to the next step in 5 seconds',
            errorToast: 'Rejection reason',
            retryBtn: 'Retry',
            rejectedText: 'Error Occured',
            kyc_waiting_text: 'We are preparing your KYC form for you to eSign',
          },
          Esign: {
            displayName: 'E-Sign your KYC Form',
            niyoxPlaceholderImage:
              'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/niyo-money/online_kyc.png',
            cta_text: 'Proceed',
            completed: false,
            disabled: true,
            note: {
              text: 'Use your Aadhaar number and OTP to digitally sign the KYC form',
              cta_text: 'Why e-sign is required?',
              helpText:
                'E-Sign requires you to verify all the information and confirm by giving your Aadhaar number and OTP. The E-Sign appears as a stamp with your name, Aadhaar number, and today date and time in your KYC Form.',
            },
            errorToast: '',
          },
        },
      },
    },
  },
  kycDoggyLoaderScreen: {
    heading: 'Complete registration',
    note: {
      text_1:
        'We just need to check a few things before starting your registration',
    },
    imageUrl: '',
  },
  kycLoadingScreenData: {
    heading: 'Complete registration',
    description:
      'We just need to check a few things before starting your registration',
    imageUrl:
      'https://alphafront.s3-ap-southeast-1.amazonaws.com/app_resources/kyc-onboarding/verify_details.png',
  },
  code: 200,
};
