import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, useTheme
} from '@material-ui/core';
import { fundInfoDetailsJson } from '../../mock-data/fundInfoJson';
import LinkTextSM from '../../components/shared/LinkTextSM';
import {
    frJc,
    frJs,
    fonts,
    text14b,
    text14,
    investFlowOption,
    fcJs,
    cursorPointer
} from '../../utilities/styles';
import { getFundInfoDetails } from '../../http-calls';
import Button from '../../components/StyledComponents/Button';
import StartSIPIcon from '../../assets/icons/sipStartIcon.svg'; 
import LumpsumIcon from '../../assets/icons/lumpsumStartIcon.svg'; 


const InvestmentOptions = () => {
    const { fundInfoDetails } = fundInfoDetailsJson;
    let navigate = useNavigate();
    let onClickHandler = (event) => {
        if(event === 'sip'){
            navigate('/sip-invest');
        }else{
            navigate('/lumpsum-invest');
        }
    }
    let startSipIcon = <img src={StartSIPIcon} alt='' />
    let lumpsumIcon = <img src={LumpsumIcon} alt='' />
    useEffect(async () => {
        const response = await getFundInfoDetails({ "mfId": 8643 })
    }, []);
    return (
        <div>
            <Box style={{ ...frJc }}>
                <Box onClick={() => onClickHandler('sip')} style={{ width: '31%', ...cursorPointer }}>
                    <Box style={{...frJs,...investFlowOption, border: `2.5px solid #F9F9F9`}}>
                        <LinkTextSM style={{margin: '10px'}}>{startSipIcon}</LinkTextSM>
                        <Box style={{...fcJs}}>
                            <LinkTextSM style={{...text14b, color: '#081320', margin:'10px', textAlign: 'left'}} fontFamily={fonts.lexendNormal.value}>Start SIP</LinkTextSM>   
                            <LinkTextSM style={{...text14, color: '#6B7179', marginLeft: '12px'}} fontFamily={fonts.rubikNormal.value}>Lorem ipsum dolor sit amet</LinkTextSM>
                        </Box>
                    </Box>
                </Box>
                <Box onClick={() => onClickHandler('lumpsum')} style={{ width: '50%', ...cursorPointer }}>
                    <Box style={{...frJs,...investFlowOption, border: `2.5px solid #F9F9F9`}}>
                        <LinkTextSM style={{margin: '10px'}}>{lumpsumIcon}</LinkTextSM>
                        <Box style={{...fcJs}}>
                            <LinkTextSM style={{...text14b, color: '#081320', margin:'10px', textAlign: 'left'}} fontFamily={fonts.lexendNormal.value}>Invest Lumpsum</LinkTextSM>   
                            <LinkTextSM style={{...text14, color: '#6B7179', marginLeft: '12px'}} fontFamily={fonts.rubikNormal.value}>Lorem ipsum dolor sit amet</LinkTextSM>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default InvestmentOptions;