import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import CryptoJS from 'crypto-js';

const forge = require('node-forge');

//step 2.1- generate random AES key
export function generateAESKey() {
  return encodeBase64(forge.random.getBytesSync(32));
}

//step 2.2 generate IV
export function getInitializationVector() {
  return encodeBase64(forge.random.getBytesSync(16));
}

//step 2.3 - AES encrypt request body using IV and symmetric key : to be passed as request body

export function encryptByAES(message, secretKey, iv) {
  if (typeof message !== 'string') {
    message = JSON.stringify(message);
  }

  const cipher = forge.cipher.createCipher('AES-CBC', decodeBase64(secretKey));
  cipher.start({ iv: decodeBase64(iv) });
  cipher.update(forge.util.createBuffer(message, 'utf8'));
  let finish = cipher.finish();
  const output = cipher.output;
  const encodedBytes = forge.util.encode64(cipher.output.getBytes());

  return encodedBytes;
}

//step 3.1 - AES decrypt response body using IV and symmetric key
export function decryptByAES(ciphertext, secretKey, iv) {
  var decipher = forge.cipher.createDecipher(
    'AES-CBC',
    decodeBase64(secretKey)
  );
  decipher.start({ iv: decodeBase64(iv) });
  decipher.update(forge.util.createBuffer(decodeBase64(ciphertext)));
  var finish = decipher.finish(); // check 'result' for true/false
  // outputs decrypted hex
  const result = decipher.output.toString();

  return result;
}

//step 2.4 RSA encrypt symmetric key : to be passed as "x-custom-key" header
export function encryptByRSA(message, publicKeyString) {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyString);
  let originalData = message;
  if (typeof message !== 'string') {
    originalData = JSON.stringify(message);
  }
  const encryptedData = publicKey.encrypt(decodeBase64(originalData));
  const base64EncryptedData = forge.util.encode64(encryptedData);
  return base64EncryptedData;
}

export function encryptHeaderByRSA(message, publicKeyString) {
  const publicKey = forge.pki.publicKeyFromPem(publicKeyString);
  let originalData = message;
  if (typeof message !== 'string') {
    originalData = JSON.stringify(message);
  }
  const encryptedData = publicKey.encrypt(originalData);
  const base64EncryptedData = forge.util.encode64(encryptedData);
  return base64EncryptedData;
}

export function convertToUTF8(string) {
  return Utf8.stringify(string); // string to utf 8
}

export function parseFromUTF8(utf8String) {
  return Utf8.parse(utf8String); //returns a word array
}

export function encodeToHex(value) {
  return Hex.stringify(value);
}

export function decodeFromHex(hex) {
  return Hex.parse(hex);
}

export function getsha256HMAC(hashDigest, privateKey) {
  return CryptoJS.HmacSHA256(hashDigest, privateKey);
}

export function encodeBase64(string) {
  return forge.util.encode64(string);
}

export function convertToBase64(wordArray) {
  return CryptoJS.enc.Base64.stringify(wordArray);
}

export function decodeBase64(string) {
  return forge.util.decode64(string);
}
