import emailIcon from '../assets/emailIcon.svg';
import buildingIcon from '../assets/buildingIcon.svg';
import changeAddressIcon from '../assets/changeAddressIcon.svg';
import notepadIcon from '../assets/notepadIcon.svg';
import changeBankAccountIcon from '../assets/changeBankAccount.svg';

import AccountDetails from '../pages/AccountDetails';
import OnlineKyc from '../pages/OnlineKyc';
import Signature from '../pages/Signature';
import Home from '../pages/Home';
import Status from '../pages/Status';
import TestPage from '../pages/TestPage';
import AllDone from '../pages/AllDone';
import Review from '../pages/Review';
import DigilockerRedirection from '../pages/Digilocker/DigilockerRedirection';
import EsignRedirection from '../pages/ESign/EsignRedirection';
import Cheque from '../pages/BankDocuments/Cheque';
// import WaitingScreen from '../pages/WaitingScreen';

export const onboardingRoutes = {
  AccountDetails: {
    path: '/accountDetails/*',
    component: <AccountDetails section={'AccountDetails'} />,
    iconSource: <img src={changeAddressIcon} alt=''/>,
    displayName: 'Account Details',
  },
  Cheque: {
    path: '/cheque/*',
    component: <Cheque section={'Cheque'}  uploadType ="cheque"/>,
    iconSource: <img src={buildingIcon}  alt=''/>,
    displayName: 'Cheque',
  },
  OnlineKyc: {
    path: '/onlineKyc/*',
    component: <OnlineKyc section={'OnlineKyc'} />,
    iconSource: <img src={changeBankAccountIcon}  alt='' />,
    displayName: 'Online Kyc',
  },
  Signature: {
    path: '/signature/*',
    component: <Signature section={'Signature'}  uploadType ="signature" />,
    iconSource: <img src={emailIcon} alt=''/>,
    displayName: 'Signature',
  },
  home: {
    path: '/home/*',
    component: <Home section={'home'} />,
    iconSource: <img src={notepadIcon}  alt=''/>,
    displayName: 'Activate Account',
  },
  registrationSuccessful: {
    path: '/registrationSuccessful/*',
    component: <AllDone section={'registrationSuccessful'} />,
    iconSource: <img src={notepadIcon}  alt=''/>,
    displayName: 'Activate Account',
  },
  test: {
    path: '/test/*',
    component: <TestPage section={'AccountDetails'} />,
  },
  status: {
    path: '/',
    component: <Status section={'status'} />,
    iconSource: <img src={emailIcon}  alt='' />,
    displayName: 'Status',
  },
  review: {
    path: '/review/*',
    component: <Review section={'review'} />,
    iconSource: <img src={emailIcon}  alt='' />,
    displayName: 'Status',
  },
  digilockerRedirect: {
    path: '/DigilockerRedirection/*',
    component: <DigilockerRedirection section={'digilockerRedirect'} />,
    iconSource: <img src={emailIcon}  alt='' />,
    displayName: 'Digilocker Redirection',
  },
  esignRedirect: {
    path:'/EsignRedirection*',
    component: <EsignRedirection section={'esignRedirect'} />,
    iconSource: <img src={emailIcon}  alt=''/>,
    displayName: 'EsignRedirection',
  },
  // waitingScreen: {
  //   path:'/waitingScreen/*',
  //   component: <WaitingScreen section={'esignWaiting'} />,
  //   iconSource: <img src={emailIcon} />,
  //   displayName: 'waitingScreen',
  // }
};
