import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useCustomQuery, useSampleJson } from '../hooks/fetchQuery';
import FullpageLoader from '../components/shared/FullpageLoader';
import Error from '../components/shared/Error';
import { setCustomQuery, getSectionData } from '../helper-methods';
import { Typography, Box } from '@material-ui/core';
import accountDone from '../assets/Account_created.png';
import CustomButton from '../components/StyledComponents/Button';
import { StyledBox } from '../components/StyledComponents/StyledBox';
import {
  fcAc,
  fcJsb,
  fonts,
  inheritHeight,
  inheritSize,
  inheritWidth,
  text14,
  text20b,
  tac,
  loginContainer
} from '../utilities/styles';

const AllDone = ({ section }) => {
  const { isLoading, isError, data, isFetching, refetch } = useSampleJson();
  const sectionQuery = useCustomQuery({ section: section });
  const queryClient = useQueryClient();
  const { data: customerStatus } = useCustomQuery({ section: 'customerStatus' });
  useEffect(async () => {
    if (data) return;
    await refetch();
  }, []);

  useEffect(() => {
    if (isLoading || isFetching || sectionQuery.data) return;

    setCustomQuery({
      section: section,
      data: data
        ? getSectionData({ response: data, section: section })[section]
        : null,
      queryClient: queryClient,
    });
  }, [data]);

  if (isLoading || isFetching)
    return (
      <>
        <FullpageLoader />
      </>
    );

  if (isError) {
    return (
      <>
        <Error />
      </>
    );
  }
  return (
    <>
      <div style={{ ...inheritSize, ...fcAc }}>
        <StyledBox style={{ ...inheritHeight, ...fcJsb, ...loginContainer }}>
          <Typography
            style={{
              textAlign: tac.textAlign,
              fontFamily: fonts.galano,
              fontSize: text20b.fontSize,
              fontWeight: text20b.fontWeight,
            }}
          >
            All done!
          </Typography>
          <Box
            sx={{
              textAlign: tac.textAlign,
            }}
          >
            <img
              style={{ ...inheritWidth }}
              alt='accountCreation'
              src={accountDone}
            />
          </Box>
          <Box>
            <Typography
              style={{
                margin: '40px 0',
                textAlign: tac.textAlign,
                fontFamily: fonts.lexend,
                fontSize: text14.fontSize,
                fontWeight: text14.fontWeight,
              }}
            >
              You have completed the account opening process. We are reviewing
              the details and will get you started shortly.
            </Typography>
            {/* <CustomButton onClick={() => window.exitFromPWA()}>
              Return to Home Page
            </CustomButton> */}
          </Box>
        </StyledBox>
      </div>
    </>
  );
};

export default AllDone;
