import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, Routes, Route, useNavigate } from 'react-router-dom';
import { getCustomQuery } from '../helper-methods';
import Digilocker from '../pages/Digilocker/Digilocker';
import LivelinessCheck from '../pages/OnlineKyc/LivelinessCheck';
import Esign from '../pages/ESign/Esign';
import AsyncSubSection from '../pages/AccountDetails/AsyncSubSection';
import SyncSubSection from '../pages/AccountDetails/SyncSubSection';

const SubSectionElement = ({ subSection, section }) => {
  const subSectionItem = {
    Digilocker: <Digilocker subSection={subSection} section={section} />,
    LivelinessCheck: (
      <LivelinessCheck subSection={subSection} section={section} />
    ),
    Esign: <Esign subSection={subSection} section={section} />,
    panDetails: <AsyncSubSection subSection={subSection} section={section} />,
    bankDetails: <AsyncSubSection subSection={subSection} section={section} />,
    basicDetails: <SyncSubSection subSection={subSection} section={section} />,
    kycDetails: <SyncSubSection subSection={subSection} section={section} />,
    nomineeDetails: (
      <SyncSubSection subSection={subSection} section={section} />
    ),
  };

  return (
    subSectionItem[subSection] ||
    'Please check the element auto-gen mapping. Component : <SectionItemContainer/>'
  );
};

const SectionItemContainer = ({ section }) => {
  const queryClient = useQueryClient();
  const sectionTabsData = getCustomQuery({ section: section, queryClient });
  console.log("The sectionTabsData==",sectionTabsData)
  let subSections;
  if (sectionTabsData) {
    subSections = sectionTabsData?.sections?.tabsList;
  }

  return (
    <>
      {sectionTabsData && (
        <Routes>
          {subSections.map((subSection) => {
            const path = '/' + subSection;
            return (
              <Route
                key={subSection}
                path={path}
                element={
                  <SubSectionElement
                    section={section}
                    subSection={subSection}
                  />
                }
              />
            );
          })}
        </Routes>
      )}
    </>
  );
};
export default SectionItemContainer;
