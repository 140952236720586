export const quickActionJson = {
    quickActions: {
        quickActionsArray :
        [
            {name : "ImportMF"},
            {name : "Invest"},
            {name : "Redeem"},
            {name : "Autopay"},
            {name : "Rebalance"},
            {name : "PaymentSettings"}
        ]
    },
    recommendations: {
        recommendationArray: [
            {   
                type: "SIP payment",
                header: "SIP payment Due",
                body: "Your SIP payment is due. Pay now to avoid missing the last date."
            },
            {
                type: "Tax tool",
                header: "Tax Tool",
                body: "Have you maximised your tax savings under 80C this year? Use our tax tool to find out or chat with us."
            },
            {
                type: "SIP payment",
                header: "SIP payment Due",
                body: "Your SIP payment is due. Pay now to avoid missing the last date."
            }
        ]
    },
    myPortfolio: {
        myPortfolioList: [
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                invested: '₹5,00,000',
                returns: "₹34,000",
                value: "₹8,34,000",
                folioNo: "#3663553782",
                xirr: "15.4%",
                returnPercent: "12.67%"
            },
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                invested: '₹5,00,000',
                returns: "₹34,000",
                value: "₹8,34,000",
                folioNo: "#3663553782",
                xirr: "15.4%",
                returnPercent: "12.67%"
            },
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                invested: '₹5,00,000',
                returns: "₹34,000",
                value: "₹8,34,000",
                folioNo: "#3663553782",
                xirr: "15.4%",
                returnPercent: "12.67%"
            },
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                invested: '₹5,00,000',
                returns: "₹34,000",
                value: "₹8,34,000",
                folioNo: "#3663553782",
                xirr: "15.4%",
                returnPercent: "12.67%"
            }
        ]
    },
    recentTransactions: {
        recentTransactionsList: [
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                value: "₹8,34,000",
                folioNo: "#3663553782",
                date: 'Jul 28, 2020'
            },
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                value: "₹8,34,000",
                folioNo: "#3663553782",
                date: 'Jul 28, 2020'
            },
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                value: "₹8,34,000",
                folioNo: "#3663553782",
                date: 'Jul 28, 2020'
            },
            {
                fundName: 'Axis Blue Chip Regular Growth Fund',
                value: "₹8,34,000",
                folioNo: "#3663553782",
                date: 'Jul 28, 2020'
            }
        ]
    },
    summary: {
        summaryObj: {
            investedValue: '₹ 2,00,000',
            totalGain: '5.4%',
            totalGainValue: '(+ ₹ 2,400)',
            oneDayChange: '0.4%',
            oneDayChangeValue: '(₹ 300)',
            totalSips: '₹ 4,000',
            currentValue: '₹ 2,34,988',
            currentValueDecimal: '00',
            equity: '80%',
            debt: '20%',
        }
    }
}



