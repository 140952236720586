import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useQueryClient } from "react-query";
import { useTheme, Box, Input, Checkbox } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

import { useCustomQuery, useSampleJson } from "../../hooks/fetchQuery";
import {
  setCustomQuery,
  getSectionDataNew,
  getBackButtonPath,
  setAccountActivationData,
  generatePresignedUrl,
  uploadDataToS3,
  saveDataUpload,
  getCustomQuery,
  updateSingleAccountActivationStatus,
  removeRejectedRemarks,
  checkFileExtension
} from "../../helper-methods";
import Button from "../../components/StyledComponents/Button";
import Text from "../../components/shared/Text";
import {
  accountActivationMapping,
  overlayStates,
  signatureFileType
} from "../../helper-methods/constants";
import {
  // imageContainer,
  imageContainerSignature,
  fcAc,
  fcJsb,
  inheritHeight,
  inheritSize,
  EditDocUpload,
  fonts,
  frAs,
  inheritWidth,
  positionAbsolute,
  positionRelative,
  googleLoginButton,
  loginContainer
} from "../../utilities/styles";
import { StyledBox } from "../../components/StyledComponents/StyledBox";
import HeaderText from "../../components/shared/HeaderText";
import NoteComponent from '../../components/NoteComponent'
import BottomSheet from "../../components/BottomSheet/index";
import { useGetDocumentDownload } from "../../hooks/fetchQuery";
import FullpageLoader from "../../components/shared/FullpageLoader";
import SignatureImage from "../../assets/SignatureImage.png";
import { getOverlayComponent, setBackButtonPath } from "../../helper-methods";
// import { forwardNavigationMapping } from '../helper-methods/constants';
//  From mobile specific
import EditIcon from "../../assets/editIcon.svg";
import secureIcon from "../../assets/secureIcon.svg";

const useStyles = makeStyles((theme) => ({
  labelEdit: {
    color: "blue",
    cursor: "pointer",
    paddingLeft: "5px",
  },
}));

const Signature = ({ section, uploadType }) => {
  // const { data: customerId } = useCustomQuery({ section: "customerId" });
  const customerId = 'CUST-575d5ef5-b159-460b-9552-14532e2dd8ab'
  const { data: homeSectionQueryData } = useCustomQuery({ section: "home" });
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const sectionQuery = useCustomQuery({ section: section });
  const { isLoading, data, isFetching, refetch } = useSampleJson();
  const sectionData = sectionQuery?.data?.sections;
  const [overlayState, setOverlayState] = useState(null);
  const [fileUploadData, _mobileUploadData] = useState();
  const [isPdfSelected, _isPdfSelected] = useState(false);
  const [localCustomerPIIData, _localCustomerPIIData] = useState(null);
  const [userConsent, _userConsent] = useState(true);
  const [extensionOfFile, __setExtensionOfFile] = useState(null)
  // const { data: enabledSubmit } = useCustomQuery({
  //   section: 'SignatureSubmitEnabled',
  // });

  const getDocumentDownloadQuery = useGetDocumentDownload({
    section,
    payload: {
      customerId: customerId,
      document_type: uploadType,
    },
  });
  const customerPIIData = getCustomQuery({
    section: "customerPIIData",
    queryClient,
  });

  const toggleUserConsent = () => {
    _userConsent(!userConsent);
  };
  // const toggleDisabledSubmit = () => {
  //   setCustomQuery({
  //     section: 'SignatureSubmitEnabled',
  //     data: !enabledSubmit,
  //     queryClient,
  //   });
  // };

  const messages = sectionQuery?.data?.messages;
  const [uploadingData, _uploadingData] = useState(0);
  const { data: forwardNavigationMapping } = useCustomQuery({
    section: "forwardNavigationMapping",
  });
  const { data: customerStatus } = useCustomQuery({
    section: "customerStatus",
  });
  const rejectionData = getCustomQuery({
    section: "rejectedRemarks",
    queryClient,
  })?.[section.toLowerCase()];
  /**
   * uploadingData = -1; upload-error
   * uploadingData = 0; not_started
   * uploadingData = 1; processing
   * uploadingData = 2; success
   */
  useEffect(async () => {
    const backPathData = getBackButtonPath({ queryClient });
    if (backPathData?.redirectToHomeFrom === uploadType) {
      setBackButtonPath({ data: "/home", queryClient });
    }
    console.log("rejectionData", rejectionData);

    if (rejectionData && rejectionData?.remarks) {
      console.log("rejection-remarks found, query removed");
      getDocumentDownloadQuery?.remove();
      console.log({ getDocumentDownloadQuery: getDocumentDownloadQuery });
    } else {
      console.log("no-rejection-remarks-found, api called");
      getDocumentDownloadQuery?.refetch();
      console.log({ getDocumentDownloadQuery: getDocumentDownloadQuery });
    }
    if (data) return;
    await refetch();
  }, []);
  useEffect(() => {
    if (isLoading || isFetching || sectionQuery.data) return;
    setCustomQuery({
      section: section,
      data: data ? getSectionDataNew({ section, response: data }) : null,
      queryClient: queryClient,
    });
  }, [data]);
  useEffect(() => {
    if (customerPIIData && !localCustomerPIIData) {
      _localCustomerPIIData(customerPIIData);
    }
  }, [customerPIIData]);
  useEffect(async () => {
    switch (uploadingData) {
      case -1:
        // upload-error
        setOverlayState(overlayStates.UPLOAD_ERROR);
        _uploadingData(0);
        break;
      case 0:
        // not_started
        break;
      case 1:
        // processing
        try {
          let dataToSend = null;
          if (fileUploadData) {
            console.log({ fileUploadData });
            const preSignedUrl = await generatePresignedUrl({
              customerId,
              document_type: uploadType,
              format: extensionOfFile,
            });
            console.log("The presigned url is ", preSignedUrl)
            await uploadDataToS3({
              format: extensionOfFile,
              preSignedUrl: preSignedUrl?.data?.data?.data?.url,
              base64String: fileUploadData,
            });
            dataToSend = {
              customerId: customerId,
              document_type: uploadType,
              documentUrl: preSignedUrl?.data?.data?.data?.url.split("?")[0],
              // doc_name: `${uploadType}_${customerId}`, [uncomment this]
              doc_name: `${uploadType}_${"CUST-575d5ef5-b159-460b-9552-14532e2dd8ab"}`,
              format: extensionOfFile,
            };
          } else if (getDocumentDownloadQuery?.data?.data?.data?.url) {
            console.log({
              "document-query-data": getDocumentDownloadQuery?.data,
            });
            dataToSend = {
              customerId: customerId,
              document_type: uploadType,
              documentUrl:
                getDocumentDownloadQuery?.data?.data?.data?.url.split("?")[0],
              doc_name: `${uploadType}_${customerId}`,
              format: getDocumentDownloadQuery?.data?.data?.data?.format,
            };
          }
          if (dataToSend) {
            console.log({ dataToSend });
            await saveDataUpload({ payload: dataToSend });
          } else {
            console.log("no data to send");
          }
          _uploadingData(2);
        } catch (e) {
          console.log("error : ", e);
          _uploadingData(-1);
        }
        break;
      case 2:
        // success
        console.log("all-documents-uploaded-successfully");
        setBackButtonPath({ data: null, queryClient });
        const { index: itemIndex } =
          accountActivationMapping[section.toLowerCase()];
        if (rejectionData) {
          removeRejectedRemarks({ keys: [section.toLowerCase()], queryClient });
          updateSingleAccountActivationStatus({
            itemIndex,
            homeSectionQueryData,
            queryClient,
            isCompleted: true,
            isDisabled: false,
            nextPath: forwardNavigationMapping[section],
            updateAllNextPath: true,
          });
        } else {
          setAccountActivationData({
            itemIndex: itemIndex + 1,
            isCompleted: false,
            isDisabled: false,
            nextPath: `${forwardNavigationMapping[section]}${search}`,
            queryClient,
            homeSectionQueryData,
            initialUpdate: false,
          });
        }
        _mobileUploadData(null);
        console.log("getDocumentDownloadQuery cleared");
        getDocumentDownloadQuery?.remove();
        navigate(`${forwardNavigationMapping[section]}${search}`);
        break;
    }
  }, [uploadingData]);

  // const signAgainMobile = async (value) => {

  //   const allowedSources = ["onScreen"];
  //   window.getFilesFromNative(allowedSources, "back", section);
  //   let response = await window.loadingFileReceivedFromNative();
  //   if (response) {
  //     let parsedResponse =
  //       typeof response === "string" ? JSON.parse(response) : response;
  //     if (parsedResponse) {
  //       console.log("parsed response signature", parsedResponse);
  //       if (isPdfSelected) _isPdfSelected(false);
  //       _mobileUploadData(parsedResponse);
  //       if (userConsent) toggleUserConsent();
  //       // if (enabledSubmit) toggleDisabledSubmit();
  //     }
  //   }
  // };
  const signUploadMobile = async (event) => {
    console.log("Reached sign upload")

    let imgtype = event.target.files[0].name;
    __setExtensionOfFile(checkFileExtension(imgtype))

    if (signatureFileType.includes(checkFileExtension(imgtype))) {
      console.log("The file :::", checkFileExtension(imgtype))
      if (checkFileExtension(imgtype) === "pdf") {
        if (!isPdfSelected) _isPdfSelected(true);
        _mobileUploadData();
      } else {
        if (isPdfSelected) _isPdfSelected(false);
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          _mobileUploadData(reader.result)
        };
        if (userConsent) toggleUserConsent();
      }

    }
  };

  const closeOverlay = () => {
    setOverlayState(null);
  };

  console.log("data11", fileUploadData)
  const onSubmitHandler = async () => {
    console.log("Inside submit handler");
    _uploadingData(1);
    setBackButtonPath({ data: null, queryClient });
  };

  if (
    isLoading ||
    isFetching ||
    getDocumentDownloadQuery?.isLoading ||
    uploadingData == 1 ||
    uploadingData == 2
  )
    return (
      <>
        <FullpageLoader />
      </>
    );

  if (getDocumentDownloadQuery?.isError) {
    getDocumentDownloadQuery.remove();
    setOverlayState(overlayStates.EXTERNAL_API_ERROR);
  }
  return (
    <>
      {overlayState &&
        getOverlayComponent({
          section,
          subSection: null,
          messages,
          overlayState,
          closeOverlay,
          ctaHandler: closeOverlay,
        })}

      {sectionData && (
          <div style={{ ...inheritSize, ...fcAc }}>
          <StyledBox style={{ ...inheritHeight, ...fcJsb, ...loginContainer }}>
            <HeaderText
              style={{
                color: theme.palette.common.darkBlack,
              }}
            >
              {sectionData?.displayName}
            </HeaderText>
            {(rejectionData?.remarks || isPdfSelected) && (
              <Box
                style={{
                  background: theme.palette.error.orangeLight,
                  padding: "12px",
                  borderRadius: "2px",
                  margin: "12px 0 ",
                }}
              >
                <Text
                  style={{
                    color: theme.palette.error.orageLighter,
                    fontFamily: fonts.rubik.value,
                    fontSize: "10px",
                    textAlign: "left",
                  }}
                >
                  {/* {LivelinessCheck.rejectedText} */}
                  Document Rejected
                </Text>
                {isPdfSelected ? (
                  <Text
                    style={{
                      color: theme.palette.common.main,
                      fontFamily: fonts.rubik.value,
                      fontSize: "12px",
                      textAlign: "left",
                      lineHeight: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Please select an image file
                  </Text>
                ) : (
                  rejectionData?.remarks && (
                    <Text
                      style={{
                        color: theme.palette.common.main,
                        fontFamily: fonts.rubik.value,
                        fontSize: "10px",
                        textAlign: "left",
                        lineHeight: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {rejectionData?.remarks}
                    </Text>
                  )
                )}
              </Box>
            )}
            <Box>
              {/* <Box> */}
              <img
                src={
                  getDocumentDownloadQuery?.data?.data &&
                    !fileUploadData
                    ? getDocumentDownloadQuery?.data?.data?.data?.url
                    : fileUploadData
                      ? fileUploadData
                      : SignatureImage
                  // sectionQuery?.data?.sections?.niyoxPlaceholderImage
                }
                // onClick={signAgainMobile}
                style={{ ...imageContainerSignature }}
                unoptimized={true}
              />
              {/* </Box> */}
              {fileUploadData || getDocumentDownloadQuery?.data ? (
                <div
                  style={{ ...EditDocUpload }}
                // onClick={handleMobileSignatureEditClick}
                >
                  <span>
                    <img src={EditIcon} />
                  </span>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    name="imageUploadEdit"
                    id="imageUploadEdit"
                    style={{ display: "none" }}
                    onChange={(file) => signUploadMobile(file)}
                  />

                  <label htmlFor="imageUploadEdit" className={classes.labelEdit}>
                    {sectionQuery?.data.sections.edit_btn_text}
                  </label>
                </div>
              ) : null}
            </Box>

            <NoteComponent
              textValue={sectionQuery?.data.sections.note_box}
              style={{
                fontSize: "12px",
                lineHeight: "1.5",
                margin: "20px 0 0 0"
              }}
            />
            <div>
              <Box style={{ margin: "20px 0" }}>
                {getDocumentDownloadQuery?.data?.data?.data?.url ||
                  fileUploadData ? (
                  <div style={{ ...frAs }}>
                    <Checkbox
                      checked={userConsent}
                      onChange={toggleUserConsent}
                      // checked={enabledSubmit}
                      // onChange={toggleDisabledSubmit}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      style={{
                        paddingLeft: "0",
                        marginTop: "5px",
                        color: `${userConsent
                            ?  positionAbsolute.background
                            : theme.palette.secondary.ebony
                          }`,
                      }}
                    />
                    <Text
                      primary
                      fontSize={12}
                      regular
                      fontFamily={"Rubik"}
                      style={{
                        color: theme.palette.secondary.sentinal_gray,
                        margin: "15px 0",
                        lineHeight: "20px",
                      }}
                    >
                      {`I confirm that mobile number - ${localCustomerPIIData?.phoneNumber} & email ID - ${localCustomerPIIData?.email} provided belong to me.`}
                    </Text>
                  </div>
                ) : null}
                {/* <Text
                primary
                fontSize={12}
                regular
                centered
                fontFamily={'Rubik'}
                style={{
                  lineHeight: '18px',
                }}
              >
                {sectionData?.note_header}
              </Text>
              <Text
                primary
                fontSize={12}
                regular
                centered
                fontFamily={'Rubik'}
                style={{
                  color: theme.palette.secondary.ebony,
                  margin: '20px 0',
                }}
              >
                {sectionQuery?.data.sections.note_desc}
              </Text> */}
                <Text
                  secondary
                  fontSize={10}
                  regular
                  fontFamily={"Rubik"}
                  style={{
                    color: theme.palette.secondary.darker,
                    lineHeight: "18px",
                  }}
                >
                  <img
                    src={secureIcon}
                    style={{ marginBottom: "-5px", marginRight: "6px" }}
                  />
                  {sectionQuery?.data.sections.secure_text}
                </Text>
              </Box>
              <Box>
                {getDocumentDownloadQuery?.data?.data?.data?.url ||
                  fileUploadData ? (
                  <Button
                    disabled={!userConsent}
                    style={{ ...googleLoginButton, width: inheritWidth.width, background: !userConsent ? "" : positionAbsolute.background }}
                    onClick={onSubmitHandler}
                  >
                    Next
                  </Button>
                ) : (
                  // <Button
                  //   primary
                  //   onClick={(file) => signUploadMobile("file")}
                  //   style={{ width: "100%" }}
                  // >
                  //   {isPdfSelected
                  //     ? "Retry"
                  //     : "Upload Signature"
                  //     //  sectionQuery?.data?.sections?.cta_text
                  //     }
                  // </Button>
                  <div style={{ marginTop: "50px" }}>
                    <input
                      type="file"
                      accept="image/*,application/pdf"
                      name="imageUpload"
                      id="imageUpload"
                      style={{ display: "none" }}
                      onChange={(file) => signUploadMobile(file)}
                    />
                    <label
                      htmlFor="imageUpload"
                      className="btn btn-large"
                      style={{
                        width: inheritWidth.width,
                        height: positionAbsolute.height,
                        display: fcAc.display,
                        justifyContent: positionRelative.alignItems,
                        alignItems: positionRelative.alignItems,
                        background: positionAbsolute.background,
                        color: positionRelative.background
                      }}
                    >
                      Upload Signature
                    </label>
                  </div>
                )}
              </Box>
            </div>

          </StyledBox>
        </div>
      )}
    </>
  );
};
export default Signature;
