export const resolve = async (promise) => {
    let resolved = {
      data: null,
      error: null,
    };
    try {
      resolved.data = await promise;
    } catch (e) {
      resolved.error = e.response;
  
      promise.catch((data) => {
        resolved = data;
        return resolved;
        // datadogLogs.logger.log(e,{data,error:resolved.error},'error');
      });
    }
    return resolved;
  };
  