import React from 'react';
import { Box } from '@material-ui/core';

import LinkTextSM from '../shared/LinkTextSM';
import Button from '../StyledComponents/Button';

const ErrorContactUs = ({
  secondaryCtaHandler,
  primaryCtaHandler,
  primaryCtaTxt,
  secondaryCtaTxt,
}) => {
  return (
    <>
      <Box onClick={secondaryCtaHandler}>
        <LinkTextSM style={{ margin: '10px' }}>{secondaryCtaTxt}</LinkTextSM>
      </Box>
      <Box>
        <Button onClick={primaryCtaHandler} style={{ width: '100%' }}>
          {primaryCtaTxt}
        </Button>
      </Box>
    </>
  );
};

export default ErrorContactUs;
