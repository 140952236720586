import React, { useEffect } from "react";

import { useTheme } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Box } from "@material-ui/core";

import { StyledBox } from "../StyledComponents/StyledBox";
import {
  fcAc,
  fcJsb,
  fonts,
  inheritHeight,
  inheritSize,
  tac,
  text14,
  text40,
} from "../../utilities/styles";
import Text from "../shared/Text";
import { sampleJson } from "../../mock-data/nmOnboardingJson";
import {
  accountActivationMapping,
  // forwardNavigationMapping,
} from "../../helper-methods/constants";
import {
  getCustomQuery,
  removeRejectedRemarks,
  setAccountActivationData,
  skipSection,
  updateSingleAccountActivationStatus,
} from "../../helper-methods";
import { useCustomQuery } from "../../hooks/fetchQuery";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";

const KycSuccessMsg = () => {
  const theme = useTheme();
  const { data: homeSectionQueryData } = useCustomQuery({ section: "home" });
  const { LivelinessCheck } =
    sampleJson.jsonstructure.sectionsObject.OnlineKyc.tabsObject;
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const { data: forwardNavigationMapping } = useCustomQuery({
    section: "forwardNavigationMapping",
  });
  const rejectionData = getCustomQuery({
    section: "rejectedRemarks",
    queryClient,
  })?.kycvideo;
  useEffect(() => {
    // Update online KYC Success data
    const { index: itemIndex } = accountActivationMapping["esign"];
    if (rejectionData) {
      removeRejectedRemarks({ keys: ["kycvideo"], queryClient });
      updateSingleAccountActivationStatus({
        itemIndex,
        homeSectionQueryData,
        queryClient,
        isCompleted: false,
        isDisabled: false,
        nextPath: forwardNavigationMapping["LivelinessCheck"],
        updateAllNextPath: true,
      });
    } else {
      setAccountActivationData({
        itemIndex: itemIndex,
        isCompleted: false,
        isDisabled: false,
        nextPath: `${forwardNavigationMapping["LivelinessCheck"]}${search}`,
        queryClient,
        homeSectionQueryData,
        initialUpdate: false,
      });
    }
    skipSection({ section: "LivelinessCheck", queryClient });
  }, []);

  return (
    <>
      <div style={{ ...inheritSize, ...fcAc }}>
        <StyledBox style={{ ...inheritHeight, ...fcJsb }}>
          <Box
            sx={{
              textAlign: tac.textAlign,
              marginTop: "20%",
            }}
          >
            <CheckCircleIcon
              style={{
                color: theme.palette.primary.successBlue,
                fontSize: text40.fontSize,
              }}
            />
            <Text
              style={{
                fontSize: text14.fontSize,
                fontFamily: fonts.lexend,
                textAlign: tac.textAlign,
                color: theme.palette.common.heading_black,
              }}
            >
              {LivelinessCheck?.kyc_success_msg}
            </Text>
          </Box>
        </StyledBox>
      </div>
    </>
  );
};

export default KycSuccessMsg;
