import React from 'react';
import Lottie from 'lottie-react-web';
import { Box } from '@material-ui/core';
import animation from '../../assets/doggyLoader.json';
import { useTheme } from '@material-ui/core';
import { fcAc, frJc, fonts } from '../../utilities/styles';
import timerIcon from '../../assets/timer.svg';
import CustomTimer from '../shared/CustomTimer';
import Text from '../shared/Text';
import WaitingIcon from '../../assets/waiting.png';
import LoadingAnimation from '../../assets/Loading.gif';

const GenericPollingUI = ({ subSection, messages, handleTimerOver }) => {
  const theme = useTheme();
  const displayItem = messages[subSection]['POLLING'];
  return (
    <div style={{ ...fcAc, margin: `20px 0` }}>
      <img src={LoadingAnimation} 
      style={{height:"200px"}}
      />
      <Text
        centered
        fontSize={14}
        style={{ color: theme.palette.common.darkBlack, margin: `10px 0px` }}
      >
        {displayItem.topNote}
      </Text>
      <Text fontSize={12} fontFamily={fonts.rubik.value}>
        {displayItem.bottomNote}
      </Text>
      <div style={{ ...frJc, margin: `10px 0px` }}>
        <img src={timerIcon} style={{ marginRight: '5px' }} />
        <Text fontSize={12} style={{ color: theme.palette.common.darkBlack }}>
          <CustomTimer handleTimerOver={handleTimerOver} />
        </Text>
      </div>
    </div>
  );
};
const KycVideoPolling = ({ subSection, messages }) => {
  const theme = useTheme();
  const displayItem = messages[subSection]['POLLING'];
  return (
    <>
      <Box style={{ height: '30vh', overflow: 'hidden' }}>
        <Lottie
          options={{
            animationData: animation,
          }}
          style={{
            border: '1px solid black',
            position: 'relative',
            zIndex: -1,
            transform: `scale(3)`,
          }}
        />
        <Text
          centered
          fontSize={14}
          style={{
            color: theme.palette.common.darkBlack,
            margin: `10px 0px`,
            position: 'absolute',
            bottom: '10%',
            left: '15%',
            zIndex: 2,
          }}
        >
          {displayItem?.description}
        </Text>
      </Box>
    </>
  );
};

const PollingUI = ({ subSection, messages, handleTimerOver }) => {
  const pollingObj = {
    livelinesscheck: (
      <KycVideoPolling subSection={subSection} messages={messages} />
    ),
  };

  return (
    pollingObj[subSection.toLowerCase()] || (
      <GenericPollingUI
        subSection={subSection}
        messages={messages}
        handleTimerOver={handleTimerOver}
      />
    )
  );
};

export default PollingUI;
