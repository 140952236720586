import React, { useEffect, useState } from 'react';
import { useCustomQuery, useSampleJson } from '../../hooks/fetchQuery';
import FullpageLoader from '../../components/shared/FullpageLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getSectionData,
  setAccountActivationData,
  setBackButtonPath,
  setCustomQuery,
  callEsignCallback,
  getOverlayComponent,
  refreshDetails,
} from '../../helper-methods';
import {
  accountActivationMapping,
  overlayStates,
} from '../../helper-methods/constants';
import { useQueryClient } from 'react-query';
import { postError } from '../../helper-methods/index';
const EsignRedirection = () => {
  const { data: customerId } = useCustomQuery({ section: 'customerId' });
  const {
    data: sampleJson,
    isLoading: isSampleJsonLoading,
    isFetching,
  } = useSampleJson();
  const { data: homeSectionQueryData } = useCustomQuery({ section: 'home' });
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [updatedData, _updatedData] = useState(null);
  const [overlayState, _overlayState] = useState(null);
  const { data: customerStatus } = useCustomQuery({ section: 'customerStatus' });
  const { data: forwardNavigationMapping } = useCustomQuery({
    section: 'forwardNavigationMapping',
  });
  console.log('href', window.location.href);
  console.log({ search });
  console.log({ urlParams });
  console.log("urlParams?.get('status')", urlParams?.get('status'));
  console.log("urlParams?.get('digio_doc_id')", urlParams?.get('digio_doc_id'));
  console.log(JSON.stringify({ updatedData }));
  console.log(JSON.stringify({ overlayState }));
  useEffect(() => {
    console.log('inside-sample-json-useEffect');
    if (isSampleJsonLoading || isFetching || homeSectionQueryData) return;
    const section = 'home';
    setCustomQuery({
      section: section,
      data: sampleJson
        ? getSectionData({ response: sampleJson, section: section })[section]
        : null,
      queryClient: queryClient,
    });
  }, [sampleJson]);

  useEffect(async () => {
    try {
      console.log('inside-esign-process-useEffect[]');
      setBackButtonPath({ data: `/home${search}`, queryClient });
      const { refreshValues, querystring } = await refreshDetails();
      console.log('refreshDetails', JSON.stringify(refreshValues));
      console.log('querystring', querystring);
      const payload = {
        customerId: refreshValues?.customerid,
        kycStep: 'esign',
        esignId: urlParams?.get('digio_doc_id'),
        status: urlParams?.get('status'),
      };
      console.log({ payload });
      const esignCallbackResponse = await callEsignCallback(payload);
      console.log({ esignCallbackResponse });
      _updatedData({ digioData: esignCallbackResponse, querystring });
    } catch (e) {
      console.log(e);
      _overlayState(overlayStates.EXTERNAL_API_ERROR);
    }
  }, []);

  useEffect(() => {
    if (updatedData?.digioData) {
      const digioData = updatedData?.digioData;
      const querystring = updatedData?.querystring;
      console.log('esign-data-use-effect');
      console.log({ currentDigioData: updatedData?.digioData });
      if (digioData?.data?.data?.status === 'Updated Status') {
        const { index: itemIndex } = accountActivationMapping['esign'];
        setAccountActivationData({
          itemIndex: itemIndex,
          isCompleted: false,
          isDisabled: false,
          nextPath: `${forwardNavigationMapping['Esign']}${querystring}`,
          queryClient,
          homeSectionQueryData,
          initialUpdate: false,
        });
        // navigate(`${forwardNavigationMapping['Digilocker']}${search}`);
        console.log('redirection-to-url:', `/${querystring}`);
        navigate(`/${querystring}`);
      } else {
        console.log('redirection-to-url:', `/home${querystring}`);
        navigate(`/home${querystring}`);
      }
    }
  }, [updatedData?.digioData]);

  const closeOverlay = () => {
    console.log('close-overlay-called');
    _overlayState(null);
  };

  console.log({ sampleJson });

  return (
    <>
      {overlayState &&
        getOverlayComponent({
          subSection: null,
          messages: sampleJson?.data?.data?.messages?.common,
          overlayState,
          closeOverlay,
          ctaHandler: closeOverlay,
        })}
      <FullpageLoader />
    </>
  );
};

export default EsignRedirection;
