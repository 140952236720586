// create an array for breadCrumb
import { isObjectEmpty, reverseDateFormat } from './index';
import { sampleJson } from '../mock-data/nmOnboardingJson';

const extractDisplayFields = (response) => {
  return response?.data?.data?.content?.data?.displayFields;
};

const extractEditScope = (response) => {
  return response?.data?.data?.content.data.editScope;
};

const extractInputData = (response) => {
  return response?.data?.data?.content?.data?.inputData;
};

const extractReviewDisplayFields = (response) => {
  return response?.data?.data?.content?.data?.review;
};

const extractDisplayHeaders = (response) => {
  const data =
    response?.data?.data?.content?.data?.inputFields?.tabsObject?.[
      response?.data?.data?.content?.data?.inputFields?.tabsList[0]
    ];

  return {
    tabName: data.tabName,
    tabKey: data.tabKey,
  };
};

const extractInfo = (response) => {
  return response?.data?.data?.content?.data?.info;
};
// --------------------------------------------------------------------------------------------------------

const extractBaseObjects = ({ response }) => {
  const jsonStructure = response?.data?.data?.jsonstructure;
  const sectionsList = jsonStructure?.sectionsList;
  const sectionsObject = jsonStructure?.sectionsObject;
  return {
    jsonStructure: jsonStructure,
    sectionsList: sectionsList,
    sectionsObject: sectionsObject,
  };
};

const extractSectionsMapNew = (response) => {
  const sectionsListMap = {};
  const { sectionsList } = extractBaseObjects({ response });
  sectionsList?.map((item, index) => {
    sectionsListMap[index] = item;
  });
  return sectionsListMap;
};

const extractSectionsDetailNew = (response) => {
  const sectionsDetail = {};
  const { sectionsList, sectionsObject } = extractBaseObjects({ response });
  sectionsList?.map((item) => {
    sectionsDetail[item] = sectionsObject[item];
  });
  return sectionsDetail;
};

const extractSectionDetailsNew = ({ section, response }) => {
  return extractSectionsDetailNew(response)[section];
};

const extractTabDetailsNew = ({ section, response }) => {
  const { sectionsObject } = extractBaseObjects({ response });
  let tabsFields;

  if (sectionsObject[section]['tabsList']) {
    const tabsList = sectionsObject[section]['tabsList'];
    tabsFields = extractTabsDetailNew({ tabsList, section, response });
  }
  return tabsFields;
};

const extractInputDetailsNew = ({ section, response }) => {
  const { sectionsObject } = extractBaseObjects({ response });
  let inputFields = {};

  if (sectionsObject[section]['tabsList']) {
    const tabsList = sectionsObject[section]['tabsList'];
    inputFields = extractTabsDetailNew({
      tabsList,
      section,
      response,
      getInputFields: true,
    });
  }
  return inputFields;
};

const extractFieldsDetailNew = (fieldsList, tab, section, response) => {
  const { sectionsObject } = extractBaseObjects({ response });
  let fieldsDetail = {};
  const fieldsObject =
    sectionsObject[section]['tabsObject'][tab]['fieldsObject'];
  fieldsList?.map((field) => {
    fieldsDetail[field] = fieldsObject[field];
  });
  return fieldsDetail;
};

const extractTabsDetailNew = ({
  tabsList,
  section,
  response,
  getInputFields = false,
}) => {
  const { sectionsObject } = extractBaseObjects({ response });
  const tabsDetail = {};
  const inputFields = {};
  const tabsObject = sectionsObject[section]['tabsObject'];
  tabsList.map((tab) => {
    tabsDetail[tab] = tabsObject[tab];
    if (tabsObject[tab]['fieldsList']) {
      if (getInputFields) {
        const fieldsList = tabsObject[tab]['fieldsList'];
        inputFields[tab] = extractFieldsDetailNew(
          fieldsList,
          tab,
          section,
          response
        );
      }
    }
  });
  return getInputFields ? inputFields : tabsDetail;
};

const extractUserDataNew = ({ response }) => {
  return response?.data?.data?.userData;
};

const extractScreenData = ({ response, screen }) => {
  const { jsonStructure } = extractBaseObjects({ response });
  return jsonStructure?.[screen];
};

const extractSectionMessages = ({ section, response }) => {
  const { jsonStructure } = extractBaseObjects({ response });
  return {
    ...(jsonStructure?.messages?.[section] || sampleJson?.messages[section]),
    ...(jsonStructure?.messages?.common || sampleJson?.messages.common),
  };
};

const extractAccountActivationData = ({ response }) => {
  const { sectionsList, sectionsObject } = extractBaseObjects({ response });

  return sectionsList.map((sectionName) => {
    const {
      displayName,
      iconUrl,
      sectionImageUrl,
      description,
      sectionDescription,
      cta_text,
      completed,
      disabled,
      onClickRedirection,
    } = sectionsObject?.[sectionName];
    return {
      displayName,
      iconUrl: iconUrl || sectionImageUrl,
      description: description || sectionDescription,
      cta_text,
      completed,
      disabled,
      onClickRedirection,
    };
  });
};

export {
  extractSectionsMapNew,
  extractSectionDetailsNew,
  extractInputDetailsNew,
  extractTabDetailsNew,
  extractUserDataNew,
  extractBaseObjects,
  extractScreenData,
  extractAccountActivationData,
  extractSectionMessages,
};
