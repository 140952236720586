import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user",
};

const WebcamCapture = ({CaptureOnBlink, CaptureOnBlinkFn}) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    // CaptureOnBlink(imageSrc)
  }, [webcamRef, setImgSrc]);

  useEffect(() => {
    if(CaptureOnBlink){
        CaptureOnBlinkFn(webcamRef.current.getScreenshot())
    }
  },[CaptureOnBlink])
  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        minScreenshotWidth={180}
        minScreenshotHeight={180}
        mirrored={true}
      />
      {/* <button onClick={capture}>Capture Photo</button>
      {imgSrc && <img src={imgSrc} alt="img" />} */}
    </>
  );
};

export default WebcamCapture