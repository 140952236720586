import React from "react";

import Text from "./Text";
import { text14, fonts } from "../../utilities/styles";

const SecondaryText = ({ children, style }) => {
  const customStyle = {
    ...text14,
    ...style,
  };

  return (
    <Text fontFamily={fonts.rubik.value} style={customStyle}>
      {children}
    </Text>
  );
};

export default SecondaryText;
