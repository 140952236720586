
import { v4 as uuidv4 } from 'uuid';

import { DeviceUUID } from "device-uuid";
import { Buffer } from "buffer";
import { useEffect, useRef } from "react";
import {
  overlayStates,
  customOverlayStates,
  navigateMapping,
  keyMapping,
} from "./constants";
import {
  extractSectionDetailsNew,
  extractSectionsMapNew,
  extractInputDetailsNew,
  extractTabDetailsNew,
  extractUserDataNew,
  extractBaseObjects,
  extractScreenData,
  extractAccountActivationData,
  extractSectionMessages,
} from "./extractor-methods";
import {
  getPreSingedUrlApi,
  postSaveDocumentsApi,
  saveDigilockerResponse,
  saveEsignResponse,
} from "../http-calls";
import PollingUI from "../components/CustomOverlay/PollingUI";
import ExternalServerErrorUI from "../components/CustomOverlay/ExternalServerErrorUI";
import OverlayPopup from "../components/shared/OverlayPopup";
import OverlayChildrenComponent from "../components/shared/OverlayChildrenComponent";
import NameMismatchOverlay from "../components/CustomOverlay/PanDetails/NameMismatch";
import ErrorContactUs from "../components/CustomOverlay/ErrorContactUs";
import SpinnerDateComponent from "../components/SpinnerDateComponent";
import axios from "axios";
import { decodeToken } from "react-jwt";

export const getAge = (dateString) => {
    // yyyy-mm-dd
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  
export const getYYYYMMDD = (date) => {
    return `${date.getUTCFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };


export const getTokenURL = () => {
  const url = window.location.href;
  return url.split("?token=")[1];
};

const authToken = getTokenURL();

  export const getHeaders = (args = null) => {
    const accessToken= sessionStorage.getItem("accessToken")
    const authToken= sessionStorage.getItem("authToken")
    const clientIp= sessionStorage.getItem("clientIp")
    let header = {
      "x-app-version":"1",
      "x-app-name":"niyo-money",
      "Content-Type":"application/json",
      "x-platform-id":"1",
      "x-correlation-id":"szrdxtfcyguvhibjnkmdd",
      "x-device-id":"c25f2b63-d975-4e59-8e32-59f78a3070e7",
      "x-access-token": `Bearer ${accessToken}`
    };
    header["Authorization"] = `Bearer ${accessToken}`
    header["X-CLIENT-IP"] = clientIp
    if (args) {
      header = { ...header, ...args };
    }
    return header;
  };


  export const checkValidEmail=(value)=>{
    var reg=new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return reg.test(value)
  }

  export const checkValidMobileNumber=(value)=>{
    var reg=new RegExp( /^[1-9][0-9]{9}$/)
    return reg.test(value)
  }

  export const checkFileExtension=(value)=>{
   return (value.substring(value.lastIndexOf(".") + 1)).toLowerCase()
  }

  
  export const uuid = () => {
    return uuidv4();
  };

  export const getError = (res) => {
    let error = null;
    if (res?.headers?.hasOwnProperty("x-nm-error-msg")) {
      error = {
        message: res?.headers["x-nm-error-msg"],
        data: res?.data,
        status: res?.status,
      };
    } else if (res?.data && !isObjectEmpty(res?.data)) {
      error = { message: res?.data?.message, data: res?.data };
    }
    return error;
  };

  // ---------------- Other Components -----------------------
  
  export const getAndSetDeviceId = (id = "") => {
    let deviceId = id;
    if (id.length) {
      sessionStorage.setItem("deviceId", id);
    } else {
      deviceId = sessionStorage.getItem("deviceId");
      if (!deviceId || !deviceId?.length) {
        deviceId = new DeviceUUID().get();
        sessionStorage.setItem("deviceId", deviceId);
      }
    }
  };
  
  export const getItemFromSessionStorage = (item = "") => {
    if (!item?.length) return null;
  
    const itemValue = sessionStorage.getItem(item);
    if (itemValue?.length) {
      return itemValue;
    }
    return null;
  };
  
  export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));
  
  export const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  
  export const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  
  export const transformArrayToObject = (sourceArray, objectKeyPropertyName) => {
    let resultantObject = {};
    if (sourceArray && sourceArray.length) {
      sourceArray.forEach((elem) => {
        resultantObject[elem[objectKeyPropertyName]] = elem;
      });
      return resultantObject;
    }
  };
  
  export const isObjectEmpty = (sourceObject = {}) => {
    return sourceObject && Object.keys(sourceObject).length === 0;
  };
  
  export const capitalizeEveryFirstLetter = (text = "") => {
    return text
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };
  
  export const calculateTotal = (arr, attr) => {
    let totalAmount = arr.reduce((acc, each) => {
      return (acc = Number(acc) + Number(each[attr]));
    }, 0);
    return totalAmount.toFixed(2);
  };
  
  export const sum = (a = 0, b = 0) => (Number(a) + Number(b)).toFixed(2);
  
  export const numberOfDigitsInteger = (number) => {
    return number.toString().length;
  };
  
  export const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  
  export function replaceRange(s, start, end, substitute, singleSubtitute) {
    if (singleSubtitute) {
      return s.substring(0, start) + substitute + s.substring(end);
    }
    let temp = "";
    for (let i = start; i <= end; i++) {
      temp += substitute;
    }
    return s.substring(0, start) + temp + s.substring(end);
  }
  
  export function isValidPhoneNumber(value) {
    return !isNaN(value) && value.length === 10;
  }
  
  export const calculateAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  
  export const reverseDateFormat = (date) => {
    const dateArr = date.split("-");
    dateArr.reverse();
    return dateArr.join("-");
  };
  
  export const trimText = (text, charLimit = 30) => {
    return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
  };
  
  export const reviewCardTitleGenerator = (title) => {
    return title.replace("Current ", "");
  };
  
  export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  

  
  export function getParamsFromUrl(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  
  export const base64ToImage = ({ base64String }) => {
    return Buffer.from(
      base64String.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
  };
  
  export const base64ToPdf = ({ base64String }) => {
    return Buffer.from(
      // data:application/pdf;base64
      base64String.replace(/^data:application\/\w+;base64,/, ""),
      "base64"
    );
  };

  export const blobToBase64=async(blob)=>{
    var reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = () => {
      console.log("The base64::",reader.result);
      return reader.result //base64encoded string
    };
    reader.onerror = error => {
      console.log("Error: ", error);

    };
  }
  
  export const useDebounce = (
    debounceFunction,
    monitoringVariables = [],
    debounceTime = 500
  ) => {
    const initialRender = useRef(true);
  
    useEffect(() => {
      if (initialRender.current) {
        initialRender.current = false;
        return () => {};
      } else {
        let timer = setTimeout(debounceFunction, debounceTime);
        return () => {
          clearTimeout(timer);
        };
      }
    }, monitoringVariables);
  };
  
  export const setCustomQuery = ({ section, data, queryClient }) => {
    queryClient.setQueryData(section, data);
  };
  
  export const getCustomQuery = ({ section, queryClient }) => {
    return queryClient.getQueryData(section);
  };
  
  export const formNewBreadcrumbPath = ({ pathname, breadcrumbRoute }) => {
    return pathname.split("/").slice(0, -1).join("/") + `/${breadcrumbRoute}`;
  };
  
  export const getSectionsList = ({ response }) => {
    const { sectionsList } = extractBaseObjects({ response });
    return [
      "home",
      "status",
      "registrationSuccessful",
      "review",
      ...sectionsList,
    ];
  };
  
  export const getSectionDataNew = ({ section, response }) => {
    if (!response) return null;
    return {
      sectionsMap: extractSectionsMapNew({ section, response }),
      sections: extractSectionDetailsNew({ section, response }),
      tabs: extractTabDetailsNew({ section, response }),
      inputFields: extractInputDetailsNew({ section, response }),
      userData: extractUserDataNew({ response }),
      messages: extractSectionMessages({ section, response }),
    };
  };
  
  export const getSectionData = ({ response, section }) => {
    if (!response) return null;
    const data = {
      home: {
        home: extractScreenData({ response, screen: section }),
        accountActivationData: extractAccountActivationData({ response }),
      },
      registrationSuccessful: extractScreenData({ response, screen: section }),
      status: extractScreenData({ response, screen: section }),
      review: extractScreenData({ response, screen: section }),
    };
    return data;
  };
  
  export function postError(error = null, metadata = null) {
    let mobile = false;
  
    const platform = sessionStorage.getItem("platform");
    let browserName = sessionStorage.getItem("browserName");
    let browserVersion = sessionStorage.getItem("browserVersion");
    let isMobileBrowser = sessionStorage.getItem("isMobileBrowser");
    let source = sessionStorage.getItem("source");
  
    // if (
    //   !browserName ||
    //   !browserName.length ||
    //   !browserVersion ||
    //   !browserVersion.length
    // ) {
    //   let { name, version } = getBrowser();
    //   browserName = name;
    //   browserVersion = version;
    //   isMobileBrowser = isScreenSizeMobile();
    // }
    if (platform === "ios" || platform === "android" || platform === "flutter") {
      mobile = true;
    }
    const errorData = {
      errorDetails: error,
      isMobileDevice: mobile,
      platforms: platform,
      source: source,
    };
    console.log("post error data", errorData);
    if (window._errs) {
      if (metadata) {
        window._errs.meta = metadata;
      }
      if (error) {
        try {
          window._errs.push(new Error(JSON.stringify(errorData)));
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
  
  export const getSubsectionOverlayData = ({
    messages,
    subSection,
    overlayState,
  }) => {
    const messageData =
      messages?.[subSection]?.[overlayState] ||
      messages?.[overlayState] ||
      messages?.common?.[overlayState];
  
    if (messageData)
      return {
        showIcon: messageData.showImage,
        imageSource: messageData.imageUrl,
        headerText: messageData.heading,
        secondaryText: messageData.description,
      };
  };
  
  export const getCustomOverlayComponent = ({
    section,
    subSection,
    messages,
    overlayState,
    handler,
    closeOverlay,
    secondaryCtaHandler,
    primaryCtaHandler,
    primaryCtaTxt,
    secondaryCtaTxt,
    dobCloseHandler,
  }) => {
    switch (overlayState) {
      case overlayStates.POLLING: {
        return (
          <PollingUI
            section={section}
            subSection={subSection}
            messages={messages}
            handleTimerOver={handler}
          />
        );
      }
      case overlayStates.NAME_MISMATCH_ERROR:
      case overlayStates.PAN_DOB_ERROR:
      case overlayStates.PAN_DOB_API_ERROR: {
        return (
          <NameMismatchOverlay
            section={section}
            subSection={subSection}
            messages={messages}
            primaryCtaHandler={primaryCtaHandler}
          />
        );
      }
      case overlayStates.EXTERNAL_API_ERROR:
        return (
          <ExternalServerErrorUI
            section={section}
            subSection={subSection}
            messages={messages}
            closeOverlay={closeOverlay}
          />
        );
      case overlayStates.DOB_SPINNER:
        return (
          <SpinnerDateComponent
            customHeader="Date of birth"
            confirmText="Confirm"
            cancelText="Cancel"
            section={section}
            subSection={subSection}
            cancelHandler={dobCloseHandler}
          />
        );
      case overlayStates.NO_CAMERA_FOUND:
      case overlayStates.UPLOAD_ERROR:
      case overlayStates.DIGILOCKER_DATA_NOT_FOUND:
      case overlayStates.CUSTOMER_PHOTO_AADHAAR_MISMATCH:
      case overlayStates.KYC_VIDEO_NOT_FOUND:
      case overlayStates.FACE_MATCH_FAILURE:
      case overlayStates.CAMERA_PERMISSION_DENIED:
      case overlayStates.ERROR_STARTING_CAMERA:
      case overlayStates.TIME_EXCEED: {
        return (
          <ErrorContactUs
            primaryCtaTxt={primaryCtaTxt}
            secondaryCtaTxt={secondaryCtaTxt}
            primaryCtaHandler={primaryCtaHandler}
            secondaryCtaHandler={secondaryCtaHandler}
          />
        );
      }
    }
  };
  
  export const getOverlayComponent = ({
    section,
    subSection,
    messages,
    overlayState,
    overlayStateSetter,
    closeOverlay,
    ctaHandler,
    secondaryCtaHandler,
    primaryCtaHandler,
    primaryCtaTxt,
    secondaryCtaTxt,
    dobCloseHandler,
  }) => {
    console.log({ overlayState });
  
    const overlayData =
      getSubsectionOverlayData({
        messages: messages,
        subSection: subSection,
        overlayState: overlayState,
      }) || {};
    return (
      overlayState &&
      (customOverlayStates[overlayState] ? (
        overlayState === overlayStates.EXTERNAL_API_ERROR ? (
          <OverlayPopup {...overlayData}>
            {getCustomOverlayComponent({
              section,
              subSection,
              messages,
              overlayState,
              closeOverlay,
              // handler will be called in <PollingUI/> once timer finishes
              handler: () => overlayStateSetter(overlayStates.EXTERNAL_API_ERROR),
            })}
          </OverlayPopup>
        ) : overlayState === overlayStates.DOB_SPINNER ? (
          <OverlayPopup>
            {getCustomOverlayComponent({
              section,
              subSection,
              messages,
              overlayState,
              overlayStateSetter,
              closeOverlay,
              ctaHandler,
              secondaryCtaHandler,
              primaryCtaHandler,
              primaryCtaTxt,
              secondaryCtaTxt,
              dobCloseHandler,
              handler: () => overlayStateSetter(overlayStates.EXTERNAL_API_ERROR),
            })}
          </OverlayPopup>
        ) : (
          <OverlayPopup>
            {getCustomOverlayComponent({
              section,
              subSection,
              messages,
              overlayState,
              overlayStateSetter,
              closeOverlay,
              ctaHandler,
              secondaryCtaHandler,
              primaryCtaHandler,
              primaryCtaTxt,
              secondaryCtaTxt,
              dobCloseHandler,
              handler: () => overlayStateSetter(overlayStates.EXTERNAL_API_ERROR),
            })}
          </OverlayPopup>
        )
      ) : (
        <OverlayPopup {...overlayData}>
          <OverlayChildrenComponent
            section={section}
            subSection={subSection}
            messages={messages}
            overlayState={overlayState}
            ctaHandler={ctaHandler}
            secondaryCtaHandler={secondaryCtaHandler}
            closeOverlay={closeOverlay}
            handleTimerOver={() =>
              overlayStateSetter(overlayStates.EXTERNAL_API_ERROR)
            }
          />
        </OverlayPopup>
      ))
    );
  };
  
  export const getPollingStatus = async ({ subSection, pollingAPI, payload }) => {
    let response;
  
    try {
      response = await pollingAPI(payload);
    } catch (e) {
      console.log({ e });
    }
    let pollingStatus;
    console.log("1211", response);
    if (subSection === "panDetails") {
      console.log("inside-panDetails-get-polling-status", subSection);
      console.log({ response });
      pollingStatus = {
        202: overlayStates.POLLING,
        200: overlayStates[response?.data?.data?.status?.toUpperCase()],
        400: overlayStates[response?.error?.data?.status?.toUpperCase()],
      };
      if (response?.data?.data?.status?.toUpperCase() === "FAILURE") {
        pollingStatus[200] =
          overlayStates[
            response?.data?.data?.data?.toUpperCase()
          ];
      }
    } else if (response?.data?.data?.data?.errorType) {
      pollingStatus = {
        200: overlayStates[
          response?.data?.data?.data?.errorType?.toUpperCase()
        ],
        202: overlayStates.POLLING,
        400: overlayStates[response?.error?.data?.status?.toUpperCase()],
      };
    } else {
      pollingStatus = {
        202: overlayStates.POLLING,
        200: overlayStates[response?.data?.data?.status?.toUpperCase()],
        400: overlayStates[response?.error?.data?.status?.toUpperCase()],
      };
    }
  
    console.log({ pollingStatus });
  
    const status =
      pollingStatus[response?.data?.status] ||
      pollingStatus[response?.error?.status] ||
      overlayStates.EXTERNAL_API_ERROR;
  
    console.log({ status });
  
    return { status: status, data: response };
  };
  
  export const setBackButtonPath = ({ data, queryClient }) => {
    const backPathData = getBackButtonPath({ queryClient });
    setCustomQuery({
      section: "backPath",
      data: { ...backPathData, backPath: data },
      queryClient,
    });
  };
  
  export const getBackButtonPath = ({ queryClient }) => {
    return getCustomQuery({ section: "backPath", queryClient });
  };
  
  export const setAccountActivationData = ({
    itemIndex,
    isCompleted,
    isDisabled,
    nextPath,
    homeSectionQueryData,
    queryClient,
    initialUpdate = true,
  }) => {
    if (
      !initialUpdate &&
      homeSectionQueryData?.accountActivationData[itemIndex].completed
    ) {
      return;
    }
    const newAccountActivationValue = {
      ...homeSectionQueryData?.accountActivationData[itemIndex],
      completed: isCompleted,
      disabled: isDisabled,
      ...(nextPath ? { nextPath: nextPath } : null),
    };
  
    const updatedData = {
      ...homeSectionQueryData,
      accountActivationData: homeSectionQueryData?.accountActivationData.map(
        (data, i) => {
          if (i === itemIndex) return newAccountActivationValue;
          if (!isCompleted && i > itemIndex)
            return { ...data, disabled: true, nextPath: null };
          if (i < itemIndex)
            return { ...data, disabled: false, completed: true, nextPath: null };
  
          return data;
        }
      ),
    };
  
    setCustomQuery({
      section: "home",
      data: updatedData,
      queryClient: queryClient,
    });
  };
  
  export const updateSingleAccountActivationStatus = ({
    homeSectionQueryData,
    itemIndex,
    queryClient,
    nextPath,
    isCompleted,
    isDisabled,
    returnValue = false,
    updateAllNextPath = false,
  }) => {
    const newAccountActivationValue = {
      ...homeSectionQueryData?.accountActivationData[itemIndex],
      completed: isCompleted,
      disabled: isDisabled,
      ...(nextPath ? { nextPath: nextPath } : null),
    };
  
    const updatedData = {
      ...homeSectionQueryData,
      accountActivationData: homeSectionQueryData?.accountActivationData.map(
        (data, i) => {
          if (i === itemIndex) {
            return newAccountActivationValue;
          }
          if (updateAllNextPath) return { ...data, nextPath: nextPath };
          return data;
        }
      ),
    };
    if (returnValue) return updatedData;
  
    setCustomQuery({
      section: "home",
      data: updatedData,
      queryClient: queryClient,
    });
  };
  
  export const setupForwardNavigation = (rejectedSections, queryClient) => {
    const newForwardNavMapping = getCustomQuery({
      section: "forwardNavigationMapping",
      queryClient,
    });
  
    rejectedSections.forEach((section, index) => {
      newForwardNavMapping[keyMapping[section]] =
        navigateMapping[rejectedSections[index + 1]] || "/registrationSuccessful";
    });
  
    setCustomQuery({
      section: "forwardNavigationMapping",
      data: newForwardNavMapping,
      queryClient,
    });
  };
  
  export const updateFormCache = ({
    inputFieldsData,
    queryClient,
    subSection,
  }) => {
    const oldCacheData = getCustomQuery({
      section: `${subSection}FormCache`,
      queryClient,
    });
  
    const newCacheData = { ...oldCacheData };
  
    const keys = Object.keys(inputFieldsData);
  
    keys.forEach((key) => {
      if (
        inputFieldsData[key]?.type === "date" &&
        inputFieldsData[key]?.value &&
        inputFieldsData[key]?.value?.length > 0
      ) {
        newCacheData[key] = reverseDateFormat(inputFieldsData[key].value);
      } else {
        newCacheData[key] = inputFieldsData[key].value;
      }
    });
  
    setCustomQuery({
      section: `${subSection}FormCache`,
      data: newCacheData,
      queryClient,
    });
  };
  
  export const removeRejectedRemarks = ({ keys, queryClient }) => {
    const rejectedRemarks = getCustomQuery({
      section: "rejectedRemarks",
      queryClient,
    });
  
    for (let key of keys) {
      delete rejectedRemarks[key];
    }
  
    setCustomQuery({
      section: "rejectedRemarks",
      data: rejectedRemarks,
      queryClient,
    });
  };
  
  export const skipDigilockerStep = (queryClient) => {
    const forwardNavigationMapping = getCustomQuery({
      section: "forwardNavigationMapping",
      queryClient,
    });
  
    if (forwardNavigationMapping["signature"] === navigateMapping["digilocker"]) {
      forwardNavigationMapping["signature"] =
        forwardNavigationMapping["digilocker"];
    }
  
    setCustomQuery({
      section: "forwardNavigationMapping",
      data: forwardNavigationMapping,
      queryClient,
    });
  };
  
  export const skipSection = ({ section, queryClient }) => {
    const forwardNavigationMapping = getCustomQuery({
      section: "forwardNavigationMapping",
      queryClient,
    });
  
    const currentNavigatioOrder = Object.keys(forwardNavigationMapping);
    const sectionIndex = currentNavigatioOrder.indexOf(section);
  
    forwardNavigationMapping[currentNavigatioOrder[sectionIndex - 1]] =
      forwardNavigationMapping[section];
  
    setCustomQuery({
      section: "forwardNavigationMapping",
      data: forwardNavigationMapping,
      queryClient,
    });
  };
  
  export const generatePresignedUrl = async ({
    customerId,
    document_type,
    format,
  }) => {
    const payload = {
      customerId,
      document_type,
      format,
    };
    console.log(`generating-presigned-url-payload:`, payload);
    const preSignedUrlResponse = await getPreSingedUrlApi(payload);
    console.log("presigned-url: ", preSignedUrlResponse);
    if (preSignedUrlResponse?.data?.status != 200)
      throw "API_ERROR_NO_PRESIGNED_URL_GENERATED";
    return preSignedUrlResponse;
  };
  
  export const uploadDataToS3 = async ({
    format,
    preSignedUrl,
    base64String,
    password,
  }) => {
    console.log(`uploading-data-to-s3`);
    console.log("s3 password", password?.toString());
    console.log("s3 presigned url :", preSignedUrl);
    console.log({
      format,
      preSignedUrl,
      base64String,
    });
    let headers;
    if (format == "pdf") {
      headers = password
        ? {
            "Content-Type": `application/${format}`,
            "x-amz-meta-password": password.toString(),
          }
        : {
            "Content-Type": `application/${format}`,
          };
    } else {
      headers = {
        "Content-Type": `image/${format}`,
        // "x-amz-meta-password":'12345'
  
        // 'Content-Encoding': 'base64',
      };
    }
    const dataToSend =
      format === "pdf"
        ? base64ToPdf({
            base64String,
          })
        : base64ToImage({
            base64String,
          });
    const payload = {
      method: "put",
      url: preSignedUrl,
      headers,
      data: dataToSend,
    };
    console.log(`s3-payload: `, payload);
    const s3UploadResponse = await axios(payload);
    console.log(`s3-response: `, s3UploadResponse);
    if (s3UploadResponse?.status != 200) throw "IMAGES_NOT_UPLOADED_TO_S3";
  };
  
  export const saveDataUpload = async ({ payload }) => {
    console.log("uploading-details", payload);
    const postDataResponse = await postSaveDocumentsApi(payload);
    if (postDataResponse?.data?.status != 200)
      throw "API_ERROR_DOCUMENTS_NOT_SAVED";
    console.log("post-data-response:", postDataResponse);
    console.log("data-successfully-posted");
  };
  
  export const callDiglockerCallback = async (payload) => {
    // {
    //   customerId: customerId,
    //   kycStep: 'digilocker',
    //   digio_id: urlParams?.get('digio_doc_id'),
    //   status: urlParams?.get('status'),
    // }
    const digilockerCallbackResponse = await saveDigilockerResponse(payload);
    if (digilockerCallbackResponse?.data?.status != 200)
      throw "DIGILOCKER_CALLBACK_ERROR";
    return digilockerCallbackResponse;
  };
  
  export const callEsignCallback = async (payload) => {
    // {
    //   customerId: customerId,
    //   kycStep: 'esign',
    //   digio_id: urlParams?.get('digio_doc_id'),
    //   status: urlParams?.get('status'),
    // }
    const digilockerCallbackResponse = await saveEsignResponse(payload);
    if (digilockerCallbackResponse?.data?.status != 200)
      throw "ESIGN_CALLBACK_ERROR";
    return digilockerCallbackResponse;
  };
  
  export const createSaveDetailsPayload = ({
    subSection,
    subSectionData,
    ifscFetchData,
  }) => {
    let payload = {};
    console.log("creating-payload", { subSectionData });
    Object.keys(subSectionData).map((key) => {
      if (
        (subSectionData[key]?.errors &&
          subSectionData[key]?.errors?.length > 0) ||
        subSectionData[key].value === "" ||
        subSectionData[key].value === undefined
      ) {
        return null;
      } else if (subSectionData[key]?.type === "date") {
        payload[key] = reverseDateFormat(subSectionData[key].value);
      } else {
        payload[key] = subSectionData[key].value?.trim();
      }
    });
    if (payload && ifscFetchData) {
      payload = {
        ...payload,
        ...ifscFetchData?.data?.data?.data[0],
      };
      delete payload["address"];
    }
    if (payload && subSection === "bankDetails") {
      payload = {
        ...payload,
        accountType: "SB",
        section: subSection,
      };
    }
    console.log("created-payload:", payload);
    return payload;
  };
  
  export const storeObjectinSession = (dataObject, excludeKeysObject) => {
    console.log("storing-in-session for:", JSON.stringify(dataObject));
    Object.keys(dataObject).map((key) => {
      console.log("is-setting-in-session?", key, !excludeKeysObject[key]);
      if (!excludeKeysObject[key]) {
        sessionStorage.setItem(key, dataObject?.[key]);
      }
    });
    console.log("upadating-in-session-storage-complete");
  };
  
  export const generateQueryString = (queryObj, excludeKeysObject) => {
    console.log("generating-query-string for:", JSON.stringify(queryObj));
    let querystring = "?";
    Object.keys(queryObj).map((key) => {
      console.log("is-included-in-querystring?", key, !excludeKeysObject[key]);
      if (!excludeKeysObject[key]) {
        querystring += `${key}=${queryObj[key]}&`;
      }
    });
    querystring = querystring.substring(0, querystring.length - 1);
    console.log("querystring : ", querystring);
    return querystring;
  };
  
  export const updateQueryParams = (queryObj, excludeKeysObject) => {
    console.log("updating-query-params for:", JSON.stringify(queryObj));
    console.log(window.location);
    const searchParams = new URLSearchParams(window.location.search);
    Object.keys(queryObj).map((key) => {
      console.log("is-updated-in-query-params", key, !excludeKeysObject[key]);
      if (!excludeKeysObject[key]) {
        searchParams.set(key, queryObj[key]);
        const updatedSearchParams = searchParams.toString();
        console.log("is-updated-in-search-params : ", updatedSearchParams);
        window.location.search = updatedSearchParams;
      }
    });
    console.log("upadating-in-query-params-complete");
  };
  
  export const refreshDetails = async () => {
    // window.getTokenForDigiLocker();
    // const tokenReceivedForDigiLockerResponse =
    //   await window.loadingTokenReceivedForDigiLocker();
    // console.log({ tokenReceivedForDigiLockerResponse });
    // if (typeof tokenReceivedForDigiLockerResponse === "string") {
    //   console.log(JSON.parse(tokenReceivedForDigiLockerResponse));
    // }
    // const parsedValue =
    //   typeof tokenReceivedForDigiLockerResponse === "string"
    //     ? JSON.parse(tokenReceivedForDigiLockerResponse)
    //     : tokenReceivedForDigiLockerResponse;
    // console.log({ parsedValue });
    const excludeKeysObject = {
      status: true,
      // customerid: true,
    };
    storeObjectinSession("eyJraWQiOiJYUTkzTlRNRE04V3ltRzZvS1VSMDJMWE9CV2RZOU54VSIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJpc3MiOiJodHRwczovL3VhdG9uZS5nb25peW8uY29tL2F1dGgvIiwiaWF0IjoxNjUxNTM5NDQ5LCJhdWQiOiJOaXlvIE1vbmV5Iiwic3ViIjoiTklZT1NCTV9CRVRBXzU5Q0E3RjkwQkYxRTExRUNCMjM4RkY5ODAzNzJEMDY2IiwiYXV0aF90ZW5hbnRJZCI6Ik5JWU9fU0JNIiwicGFydG5lckJhbmsiOiJTQk0iLCJhcHBVc2VySWQiOiJOSVlPU0JNX0JFVEFfNTlDQTdGOTBCRjFFODlFQ0IyMzhGRjk4MDM3MkQwNjYiLCJ3ZWFsdGhDdXN0b21lcklkIjoiY3VzdF9EakVITnQ2SG1wb0J4MjAiLCJqdGkiOiI5MDFjNmY4OS02ZDdkLTRhYjEtOWViOS00NGIxNjYyOWQyNTEiLCJleHAiOjE2NTE4Mzk0NDl9.3xfKuaNhBMwG5W6xsKNUlguyGTqzvKn2X7FDHLSR6RdMhniWYAwhaRbedws132gggHsbomDlUMpXW-Jh-3m50hVbeUiAzNxr4PcWgkYG7iGjTJMKjgy5FYQRHiBan1b9qklW3aUWF0-ufQW5CMeVN_gBwAV8oDBrh-xtd7ATFvAePk0MXvJ8LfuEHKQE7hSvYdxXNqHf3MiZhvLras95ckG1-isXpUKwtW_dW6OTUT1Sg8cYycLvoqOjsEeeskIdDfBS5rh1m9_Myk6j1ptwmrr0LZorB8EE6ZhXxJqNjAlb3wIvHA2a2_usiyBZXZvtqXZYHX9qfelCaEm56nv_zQ", excludeKeysObject);
    // updateQueryParams(parsedValue, excludeKeysObject);
    // if (parsedValue?.status && parsedValue?.status != "success")
    //   throw "ERROR_ENCOUNTERED_WHILE_GENERATING_REFRESH_TOKEN_FROM_NATIVE::GENERATE_TOKEN_API_FAILURE";
  
    return {
      refreshValues: "eyJraWQiOiJYUTkzTlRNRE04V3ltRzZvS1VSMDJMWE9CV2RZOU54VSIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJpc3MiOiJodHRwczovL3VhdG9uZS5nb25peW8uY29tL2F1dGgvIiwiaWF0IjoxNjUxNTM5NDQ5LCJhdWQiOiJOaXlvIE1vbmV5Iiwic3ViIjoiTklZT1NCTV9CRVRBXzU5Q0E3RjkwQkYxRTExRUNCMjM4RkY5ODAzNzJEMDY2IiwiYXV0aF90ZW5hbnRJZCI6Ik5JWU9fU0JNIiwicGFydG5lckJhbmsiOiJTQk0iLCJhcHBVc2VySWQiOiJOSVlPU0JNX0JFVEFfNTlDQTdGOTBCRjFFODlFQ0IyMzhGRjk4MDM3MkQwNjYiLCJ3ZWFsdGhDdXN0b21lcklkIjoiY3VzdF9EakVITnQ2SG1wb0J4MjAiLCJqdGkiOiI5MDFjNmY4OS02ZDdkLTRhYjEtOWViOS00NGIxNjYyOWQyNTEiLCJleHAiOjE2NTE4Mzk0NDl9.3xfKuaNhBMwG5W6xsKNUlguyGTqzvKn2X7FDHLSR6RdMhniWYAwhaRbedws132gggHsbomDlUMpXW-Jh-3m50hVbeUiAzNxr4PcWgkYG7iGjTJMKjgy5FYQRHiBan1b9qklW3aUWF0-ufQW5CMeVN_gBwAV8oDBrh-xtd7ATFvAePk0MXvJ8LfuEHKQE7hSvYdxXNqHf3MiZhvLras95ckG1-isXpUKwtW_dW6OTUT1Sg8cYycLvoqOjsEeeskIdDfBS5rh1m9_Myk6j1ptwmrr0LZorB8EE6ZhXxJqNjAlb3wIvHA2a2_usiyBZXZvtqXZYHX9qfelCaEm56nv_zQ",
      querystring: generateQueryString("eyJraWQiOiJYUTkzTlRNRE04V3ltRzZvS1VSMDJMWE9CV2RZOU54VSIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJpc3MiOiJodHRwczovL3VhdG9uZS5nb25peW8uY29tL2F1dGgvIiwiaWF0IjoxNjUxNTM5NDQ5LCJhdWQiOiJOaXlvIE1vbmV5Iiwic3ViIjoiTklZT1NCTV9CRVRBXzU5Q0E3RjkwQkYxRTExRUNCMjM4RkY5ODAzNzJEMDY2IiwiYXV0aF90ZW5hbnRJZCI6Ik5JWU9fU0JNIiwicGFydG5lckJhbmsiOiJTQk0iLCJhcHBVc2VySWQiOiJOSVlPU0JNX0JFVEFfNTlDQTdGOTBCRjFFODlFQ0IyMzhGRjk4MDM3MkQwNjYiLCJ3ZWFsdGhDdXN0b21lcklkIjoiY3VzdF9EakVITnQ2SG1wb0J4MjAiLCJqdGkiOiI5MDFjNmY4OS02ZDdkLTRhYjEtOWViOS00NGIxNjYyOWQyNTEiLCJleHAiOjE2NTE4Mzk0NDl9.3xfKuaNhBMwG5W6xsKNUlguyGTqzvKn2X7FDHLSR6RdMhniWYAwhaRbedws132gggHsbomDlUMpXW-Jh-3m50hVbeUiAzNxr4PcWgkYG7iGjTJMKjgy5FYQRHiBan1b9qklW3aUWF0-ufQW5CMeVN_gBwAV8oDBrh-xtd7ATFvAePk0MXvJ8LfuEHKQE7hSvYdxXNqHf3MiZhvLras95ckG1-isXpUKwtW_dW6OTUT1Sg8cYycLvoqOjsEeeskIdDfBS5rh1m9_Myk6j1ptwmrr0LZorB8EE6ZhXxJqNjAlb3wIvHA2a2_usiyBZXZvtqXZYHX9qfelCaEm56nv_zQ", excludeKeysObject),
    };
  };
  
  export const isRedirectionPath = (pathname) => {
    const redirecitonObj = {
      "/DigilockerRedirection": true,
      "/EsignRedirection": true,
    };
  
    return redirecitonObj?.[pathname] || null;
  };
  
  export const updateInputValue = ({ key, value, inputItem, _inputItem }) => {
    _inputItem({ ...inputItem, value: value });
  };
  
  export const setDisableButton = ({
    inputItem,
    disableSumbit,
    _disableSumbit,
  }) => {
    let isDisabled = false;
    const inputKeyValue = inputItem?.value;
    const inputKeyError = inputItem?.errors.length;
    if (
      inputKeyValue == "" ||
      inputKeyValue == undefined ||
      inputKeyValue == null ||
      inputKeyError
    ) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }
  
    if (disableSumbit !== isDisabled) {
      _disableSumbit(isDisabled);
    }
    return isDisabled;
  };
  
  export const updateErrorsValue = ({
    key,
    errorValue,
    inputItem,
    _inputItem,
  }) => {
    const errors = inputItem.errors;
    if (errorValue?.length) {
      const errorIndex = errors.findIndex((item) => item === errorValue);
      if (errorIndex === -1) {
        errors.push(errorValue);
      }
    } else {
      errors.pop();
    }
  
    _inputItem({ ...inputItem, errors: errors });
  };
  
  export const verifyInputRegex = ({
    key,
    regex,
    value,
    inputItem,
    _inputItem,
  }) => {
    if (!regex?.length) return false;
    const modifiedReg = new RegExp(regex?.substring(1).slice(0, -1));
    const regExp = new RegExp(modifiedReg);
    const isVerified = regExp.test(value);
    if (!isVerified)
      updateErrorsValue({
        key: key,
        errorValue: inputItem?.errormsg,
        inputItem,
        _inputItem,
      });
    return isVerified;
  };
  
  export const validateInputLength = ({
    key,
    value,
    minLength,
    maxLength,
    inputItem,
    _inputItem,
  }) => {
    let errorMessage = null;
    let isValidLength = true;
    if (minLength && value?.length < minLength) {
      errorMessage = `Minimum ${minLength} characters required`;
      isValidLength = false;
    } else if (maxLength && value?.length > maxLength) {
      errorMessage = `Maximum ${maxLength} characters allowed`;
      isValidLength = false;
    }
    updateErrorsValue({
      key: key,
      errorValue: errorMessage,
      inputItem,
      _inputItem,
    });
    return isValidLength;
  };
  
  export const valueUpdateHandler = ({ key, value, inputItem, _inputItem }) => {
    if (
      validateInputLength({
        key: key,
        value: value,
        minLength: inputItem.minLength,
        maxLength: inputItem.maxLength,
        inputItem,
        _inputItem,
      })
    ) {
      if (
        inputItem.regex &&
        verifyInputRegex({
          key: key,
          regex: inputItem.regex,
          value,
          inputItem,
          _inputItem,
        })
      ) {
        updateInputValue({ key, value, inputItem, _inputItem });
      }
    }
    updateInputValue({ key, value, inputItem, _inputItem });
  };
  
  
  //  Other components
  
  
  