
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    toolTiptext: {
        color: "green",
        textDecoration: "underline",
        textAlign:'left'
    }
}));

const TooltipComponent = ({ tooltipText, imgSrc, displayText, display,handleTooltipClick }) => {
    const classes = useStyles();
    return (
        <Tooltip title={tooltipText} arrow enterTouchDelay={0} placement="top" onClick={handleTooltipClick}>
            {imgSrc ? <img src={imgSrc} /> : display == "differentLine" ? <p className={classes.toolTiptext+ ' mb-24'}>
                {displayText}
            </p>
                : <span className={classes.toolTiptext}>{displayText}</span>}
        </Tooltip>
    )
}
export default TooltipComponent;
