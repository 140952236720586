// import React from 'react'
// import './App.css';
// import { BrowserRouter as Router, useLocation } from 'react-router-dom';

// function App() {
//   return
// }

// export default App;

//  ----------------- New code ----------------------------------------
import React, { useEffect, useRef } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
// import WebFont from 'webfontloader';
import MainRouter from './router/MainRouter';
import { lightTheme } from './utilities/theme';
import { useCustomQuery, useSampleJson } from './hooks/fetchQuery';
import {
  getSectionsList,
  setCustomQuery,
  refreshDetails,
  isRedirectionPath,
} from './helper-methods';
import { fcAllCenter } from './utilities/styles';
import FullpageLoader from './components/shared/FullpageLoader';
import Error from './components/shared/Error';
import Wrapper from './components/Wrapper';
import { useQueryClient } from 'react-query';
// import { useJwt } from 'react-jwt';
import { CompassCalibrationSharp } from '@material-ui/icons';

const __insp = window.__insp;

export const history = createBrowserHistory();
const theme = createTheme(lightTheme);
const App = () => {
  // const { decodedToken, isExpired } = useJwt(sessionStorage.getItem('token'));

  // if (decodedToken) {
  //   __insp?.push(['identify', decodedToken.userid]);
  //   __insp?.push(['tagSession', { uid: decodedToken.userid }]);
  // }
  const {
    isLoading,
    isError,
    data: response,
    isFetching,
    refetch,
  } = useSampleJson();
  let sectionsList = null;
  const { data: customerId } = useCustomQuery({ section: 'customerId' });
  const inputRef = useRef(null);
  const queryClient = useQueryClient();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlCustomerId = urlParams.get('customerid');
  const forwardNavigationMappingQuery = useCustomQuery({
    section: 'forwardNavigationMapping',
  });
  useEffect(async () => {
    // WebFont.load({
    //   google: {
    //     families: [
    //       'Rubik',
    //       'Lexend Deca',
    //       'Material Icons',
    //       'Darker Grotesque',
    //     ],
    //   },
    // });
    sessionStorage.setItem('platform', 'flutter');
    sessionStorage.setItem('source', 'NIYO_SBM');
    await refetch();

    if (urlCustomerId) {
      setCustomQuery({
        section: 'customerId',
        data: urlCustomerId,
        queryClient,
      });
    }

    setCustomQuery({
      section: 'forwardNavigationMapping',
      data: {
        panDetails: '/',
        basicDetails: '/accountDetails/kycDetails',
        kycDetails: '/accountDetails/nomineeDetails',
        nomineeDetails: '/accountDetails/bankDetails',
        bankDetails: '/cheque',
        Cheque: '/signature',
        Signature: '/onlineKyc/digilocker',
        Digilocker: '/onlineKyc/livelinessCheck',
        LivelinessCheck: '/onlineKyc/esign',
        Esign: '/registrationSuccessful',
      },
      queryClient,
    });
  }, []);

  if (
    isLoading ||
    isFetching 
    // ||
    // (!customerId && !isRedirectionPath(window.location.pathname))
  )
    return (
      <>
        <FullpageLoader />
      </>
    );
  if (isError) {
    return (
      <>
        <Error />
      </>
    );
  }

  if (response) {
    sectionsList = getSectionsList({ response });
  }

  return (
    <>
      {/* <button
        onClick={() => {
          const queryString = refreshDetails();
          console.log({ queryString });
        }}
      >
        click me
      </button> */}
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <div
            style={{
              height: '100vh',
              width: '100vw',
              overflowX: 'hidden',
            }}
          >
            <Wrapper sectionsList={sectionsList}>
              <MainRouter sectionsList={sectionsList} />
            </Wrapper>
          </div>
        </ThemeProvider>
      </Router>
    </>
  );
};
export default App;

