import React from 'react';
import Button from '../../components/StyledComponents/Button';
import { Box } from '@material-ui/core';
import EquityIcon from '../../assets/icons/equity-icon.svg';
import DebtIcon from '../../assets/icons/debt-icon.svg';
import UpArrowIcon from '../../assets/icons/upArrow.svg';
import DownArrowIcon from '../../assets/icons/downArrow.svg';
import SipPurchseIcon from '../../assets/icons/ellipse.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TimelineIcon from '../../assets/icons/timeline-icon.svg';

import {
  frJsb,
  frAc,
  fcAc,
  fcJs,
  frJc,
  frJsa,
  fcJc,
  text14,
  fonts,
  summaryTab1,
  frAs,
  text24,
  text12,
  fcJsb,
  fcJsa,
  text16,
  transactionTimelineBorder
} from '../../utilities/styles';
import LinkTextSM from '../shared/LinkTextSM';
import NipponIcon from '../../assets/icons/Nippon_Life_Insurance.svg';
import ExecutedIcon from '../../assets/icons/executed.svg';
import {Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot} from '@material-ui/lab';

const TransactionTimeline = ({ data }) => {
    let nipponIcon = <img src={NipponIcon} alt='' />
    let sipPurchaseIcon = <img src={SipPurchseIcon} alt='' />
    let executedIcon = <img src={ExecutedIcon} alt='' />
    let timelineIcon = <img src={TimelineIcon} alt='' />
  return (
    <>
        <Box style={{...fcJs, width: '50%', height: '120px', margin: '20px'}}>
            <Box style={{...frJsb}}>
                    <Box style={{...fcAc}}>
                    <Box style={{ ...frAs, width: '74%' }}>
                        <LinkTextSM style={{ textAlign: 'inherit' }}>{nipponIcon}</LinkTextSM>
                        <LinkTextSM  fontFamily={fonts.lexendNormal.value} style={{ ...text16, color:'#081320' }}>ICICI Prudential Large Cap Regular Growth Fund</LinkTextSM>
                    </Box>
                    <Box style={{ ...frAs }}>
                        <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6" }}>{sipPurchaseIcon}</LinkTextSM>
                        <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6" }}>SIP purchase</LinkTextSM>
                        <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6" }}>Folio #3663553782</LinkTextSM>
                    </Box>

                    </Box>
                    <Box style={{ ...fcAc, ...text14 }}>
                       <LinkTextSM  fontFamily={fonts.lexendNormal.value} style={{ ...text16, color:'#081320' }}>₹8,34,000</LinkTextSM>  
                        <Box style={{...frAs}}>
                            <LinkTextSM style={{ textAlign: 'inherit', color: "#1A936F" }}>{executedIcon}</LinkTextSM>
                            <LinkTextSM style={{ textAlign: 'inherit', color: "#1A936F" }}>Executed</LinkTextSM>
                        </Box>
                    </Box>
            
            </Box>
            <Box style={{...frAs}}>
                    <Box style={{...transactionTimelineBorder}}></Box>
            </Box>
            <Box style={{...frAs}}>
                <Timeline position='right'>
                <TimelineItem>
                    <TimelineOppositeContent style={{ flex: 0.1}}></TimelineOppositeContent>
                    <TimelineSeparator>
                    <TimelineDot  color="white" variant='outlined'>
                     <CheckCircleIcon size='large'  style={{ color: "green" }} />
                     {/* {timelineIcon} */}
                    </TimelineDot>
                        <TimelineConnector  />
                    </TimelineSeparator>
                    <TimelineContent  sx={{ py: '12px', px: 2 }}><Box style={{...frJsb, ...text14, color:'#081320'}} fontFamily={fonts.rubikNormal.value}>
                        <LinkTextSM>Transaction received</LinkTextSM>
                        <LinkTextSM>Jun 24, 2021  •  02:08 PM</LinkTextSM>
                        </Box></TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineOppositeContent style={{ flex: 0.1}}></TimelineOppositeContent>
                    <TimelineSeparator>
                    <TimelineDot  color="white" variant='filled'>
                    <AccessTimeIcon  style={{ color: "yellow" }}/>
                    </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent  sx={{ py: '12px', px: 2 }}><Box style={{...frJsb, ...text14, color:'#081320'}} fontFamily={fonts.rubikNormal.value}>
                        <LinkTextSM>Transaction sent to the mutual fund company(AMC)</LinkTextSM>
                        <LinkTextSM>Jun 25, 2021</LinkTextSM>
                        </Box></TimelineContent>
                </TimelineItem>
                

                <TimelineItem>
                     <TimelineOppositeContent style={{ flex: 0.1}}></TimelineOppositeContent>
                    <TimelineSeparator>
                    <TimelineDot  color="white" variant='filled'>
                    <AccessTimeIcon  style={{ color: "grey" }}/>
                    </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent  sx={{ py: '12px', px: 2 }}><Box style={{...frJsb, ...text14, color:'#9CA1A6'}} fontFamily={fonts.rubikNormal.value}>
                        <LinkTextSM>Transaction expected to be processed by the AMC (NAV date)</LinkTextSM>
                        <LinkTextSM>Jun 26, 2021</LinkTextSM>
                        </Box></TimelineContent>
                </TimelineItem>

                <TimelineItem>
                     <TimelineOppositeContent style={{ flex: 0.1}}></TimelineOppositeContent>
                    <TimelineSeparator>
                    <TimelineDot style={{margin: '2px'}} color="grey" variant='filled'>
                    <AccessTimeIcon  style={{ color: "grey" }}/>
                    </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent  sx={{ py: '12px', px: 2 }}><Box style={{...frJsb, ...text14, color:'##9CA1A6'}} fontFamily={fonts.rubikNormal.value}>
                        <LinkTextSM>Expected dashboard update</LinkTextSM>
                        <LinkTextSM>Jun 27, 2021</LinkTextSM>
                        </Box></TimelineContent>
                </TimelineItem>
                </Timeline>
            </Box>
        </Box>
    </>
  );
};

export default TransactionTimeline;