import React, { useState, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from "react-query";
import OtpInput from 'react-otp-input';
import {
    Box, Typography, useTheme
} from '@material-ui/core';

import { StyledBox } from '../../../components/StyledComponents/StyledBox';
import Text from '../../../components/shared/Text';
import {
    inheritHeight,
    inheritSize,
    fcJc,
    loginContainer,
    fcAllCenter,
    otpTextStyle,
    otpTextFocusStyle,
    otpContainer,
    frJe,
    googleLoginButton,
    inheritWidth
} from '../../../utilities/styles/index';
import Button from '../../../components/StyledComponents/Button';
import Loader from '../../../components/Loader';
import OtpTimer from '../../../components/OtpTimer';
import { VerifyEmailOTP, ResendEmailOTP, Login, useCustomerSync,CreateV2Customer } from '../../../hooks/fetchQuery';
import {
    getCustomQuery,
  } from '../../../helper-methods'

const OtpForm = ({ onSuccessFromGoogle, onFailureFromGoogle }) => {

    console.log("came to otp")
    const queryClient=useQueryClient()
    const location = useLocation();
    const navigate = useNavigate();
    const otpTimerData = getCustomQuery({ section: "otp_timer", queryClient });
    console.log("The timer",otpTimerData)
    const { mutate: verifyOTP, isLoading: isLoadingEmailOtp, isFetching: isFetchingEmailOtp,
        data: VerifyEmailOTPData } = VerifyEmailOTP()
    const { mutate: resendOTP, isLoading: isLoadingMobileOtp, isFetching: isFetchingMobileOtp,
        data: resendEmailOTPData } = ResendEmailOTP()
    const { mutate: login, data } = Login()

      const getCustomerSync =  useCustomerSync({});
    const { mutate: createCustomer, isLoading: isLoadingCreateCustomer, isFetching:isFetchingCreateCustomer,
        data: createV2CustomerData } = CreateV2Customer()

    const theme = useTheme();
    const [otpValue, setOtpValue] = useState('')
    const deviceToken = VerifyEmailOTPData?.headers['x-device-token']
    const phoneVerify = VerifyEmailOTPData?.headers['x-phone-verify']
    const emailVerify = VerifyEmailOTPData?.headers['x-email-verify']
    const accessToken = data?.headers['x-access-token']
    const [otpVerified, _otpVerified] = useState(false)
    const [buttonDisabled, _buttonDisabled] = useState(true)
    const [resendDisabled,_resendDisabled]=useState(true)
    const [resendClicked,_resendClicked]=useState(false)
    console.log("accessToken", accessToken)  

    const checkTimer = (timerStatus) => {
        _resendDisabled(timerStatus);
  };

    const handleChangeOtp = (value) => {
        if (value.length == 6) {
            _buttonDisabled(false)
        }
        else {
            _buttonDisabled(true)
        }
        setOtpValue(value)
    }
    const onSubmitHandlerOtp = () => {
        const data = {
            "requestId": location?.state?.GenerateEmailOTPData?.data?.client_transaction_id,
            "email": {
                "email": location?.state?.emailId
            },
            "otp": otpValue,
            "purpose": "EMAIL_VERIFICATION"
        }
        verifyOTP(data)
        _resendClicked(true)
    }

    useEffect(() => {
        if (data?.status === 200 && location.state.route != "signup") {
            // navigate('/')
        }
        if(data?.status === 200 && location.state.route === "login" && accessToken){
            sessionStorage.setItem("accessToken",accessToken)
            sessionStorage.setItem("authToken",accessToken)
            const data = {
              'x-access-token': "Bearer" +accessToken
            }
            getCustomerSync?.refetch(data)
        }
    }, [data])

    console.log('getCustomerSync', getCustomerSync?.data?.data?.status)
    useEffect(() => {
        if (deviceToken) {
            const data = {
                'x-device-token': deviceToken
            }
            login(data)
        }
    }, [deviceToken])

    const handleResendOTP = () => {
        const data = {
            "server_transaction_id": location?.state?.GenerateEmailOTPData?.data?.server_transaction_id,
        }
        resendOTP(data)
    }

    const navigateTo = (data) => {
        if(data === 'login')
       {
        //   navigate('/')
    }
      else {
        navigate('/signup')
      }
    }
    useEffect(() => {
        if (VerifyEmailOTPData?.status === 200) {
            _otpVerified(true)
        }
    }, [VerifyEmailOTPData])

    useEffect(() => {
        if (resendEmailOTPData?.status === 200) {
            _resendDisabled(true)
        }
    }, [resendEmailOTPData])


    useEffect(() => {
        if (otpVerified && location.state.route == "signup" && phoneVerify !== 'true') {
            navigate('/mobile-signup', { state: { isOtpVerifiedSignup: otpVerified } })
        }
        if (otpVerified && location.state.route == "login" && phoneVerify !== 'true') {
            navigate('/mobile-signup', { state: { isOtpVerifiedSignup: otpVerified } })
        }
    }, [otpVerified])

    useEffect(()=>{
     if(getCustomerSync?.data){
        if(!getCustomerSync?.data?.data?.data?.customerId && getCustomerSync?.data?.data?.status === 404){
            const data = {
                'x-access-token': accessToken
              }
            createCustomer(data)
        }
     } 
    },[getCustomerSync?.data])

    if (isLoadingEmailOtp || isLoadingMobileOtp || isFetchingEmailOtp || isFetchingMobileOtp ||isLoadingCreateCustomer)
        return (
            <>
                <Loader />
            </>
        );
    return (
        <div style={{ ...inheritSize, ...fcAllCenter }}>

            <StyledBox style={{ ...inheritHeight, ...fcJc, ...loginContainer }}>
                <Box>
                </Box>
                <Text
                    centered
                    bold
                    fontSize={20}
                    style={{
                        color: theme.palette.common.darkBlack,
                        margin: `20px 0px`,
                        bottom: '10%',
                        left: '15%',
                        zIndex: 2,
                    }}
                >
                    Please Enter Your OTP
                </Text>
                <Text
                    centered
                    fontSize={12}
                    style={{
                        color: theme.palette.common.darkBlack,
                        margin: `20px 0px`,
                        bottom: '10%',
                        left: '15%',
                        zIndex: 2,
                    }}
                >
                    We've sent an OTP to the email {location?.state?.emailId}. Please enter it below to complete verification.
                </Text>
                <Box style={{ ...otpContainer }}>
                    <OtpInput
                        inputStyle={{ ...otpTextStyle }}
                        focusStyle={{ ...otpTextFocusStyle }}
                        numInputs={6}
                        separator={<span>&nbsp;&nbsp;</span>}
                        onChange={(value) => handleChangeOtp(value)}
                        value={otpValue}
                        isInputNum={true}
                        shouldAutoFocus={true}
                    />
                </Box>
                <Box style={{ ...frJe, justifyContent: 'space-around' }} className="mb-32">
                    <Typography
                        style={{
                            color: '#800020'
                        }}
                    >
                        {VerifyEmailOTPData?.headers['x-nm-error-msg'] || data?.headers['x-nm-error-msg'] ||
                            (phoneVerify === 'true' && emailVerify === 'true' && location.state.route == "signup") &&
                            "Email and Phone already Verify Please login"}
                    </Typography>
                          {resendDisabled ? "" :
                                        <Typography
                                        style={{
                                            color: theme.palette.primary.main
                                        }}
                                    > <span
                                        style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                        onClick={handleResendOTP}
                                    > Resend Otp </span>
                                    </Typography>
                    }
             
             {resendClicked? <OtpTimer checkTimer={checkTimer}/>:null}
                </Box>
                <Box>
                    <Button
                        style={{...inheritWidth,...googleLoginButton}}
                        onClick={onSubmitHandlerOtp}
                        primary
                        disabled={buttonDisabled}
                    >
                        Continue
                    </Button>
                </Box>
                <Text
                    centered
                    fontSize={16}
                    style={{
                        margin: `20px 0px`,
                        bottom: '10%',
                        left: '15%',
                        zIndex: 2,
                    }}
                >
                    {location?.state?.route == "signup" ? <>Already signed up? <span
                        style={{ color: theme.palette.primary.main }}
                        onClick={() => navigateTo('login')}
                    >Login</span></> : <>
                        Don't have an account yet? <span
                            style={{ color: theme.palette.primary.main }}
                            onClick={() => navigateTo('signup')}
                        >Sign Up</span> </>}
                </Text>
            </StyledBox>
        </div>
    );
}

export default OtpForm