import React from 'react';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  React.useEffect(() => {
    let mobile = false;
    const platform = sessionStorage.getItem('platform');
    if (
      platform === 'ios' ||
      platform === 'android' ||
      platform === 'flutter'
    ) {
      mobile = true;
    }
    setMobile(mobile);
  }, []);

  return { isMobile };
}
export function getMobileOperatingSystem() {
  var userAgent =
    window.navigator.userAgent ||
    window.navigator.vendor ||
    window.window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'web';
}
export const getBrowserName = () => {
  try {
    let e;
    let f = e.width;
  } catch (e) {
    if (!e) {
      return 'unknown';
    }

    let err = e.toString();

    if (err.indexOf('not an object') !== -1) {
      return 'Safari';
    } else if (err.indexOf('Cannot read') !== -1) {
      return 'Chrome';
    } else if (err.indexOf('e is undefined') !== -1) {
      return 'Mozilla';
    } else if (
      err.indexOf(
        "Unable to get property 'width' of undefined or null reference"
      ) !== -1
    ) {
      if (!(false || !!document.documentMode) && !!window.StyleMedia) {
        return 'Edge';
      } else {
        return 'Internet Explorer';
      }
    } else if (err.indexOf('cannot convert e into object') !== -1) {
      return 'Opera';
    } else {
      return 'unknown';
    }
  }
};
export const getBrowser = () => {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: getBrowserName(), version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: getBrowserName(), version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: getBrowserName(),
    version: M[1],
  };
};
export const getOS = () => {
  let OSName = '';
  if (navigator.platform.indexOf('Win') != -1) OSName = 'Windows';
  if (navigator.platform.indexOf('Mac') != -1) OSName = 'MacOS';
  if (navigator.platform.indexOf('X11') != -1) OSName = 'UNIX';
  if (navigator.platform.indexOf('Linux') != -1) OSName = 'Linux';
  if (navigator.platform.indexOf('iPhone') != -1) OSName = 'iOS';
  if (navigator.platform.indexOf('Android') != -1) OSName = 'Android';
  return OSName;
};

export const isScreenSizeMobile = () => {
  return (
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf('Mobi') > -1
  );
};
export const ANDROID_DEVICE = 'Android';
export const IOS_DEVICE = 'iOS';
export const FLUTTER_DEVICE = 'Flutter';
export const MAC_DEVICE = 'MacOS';
export const WINDOWS_DEVICE = 'Windows';
export const UNIX_DEVICE = 'UNIX';
export const LINUX_DEVICE = 'LINUX';
