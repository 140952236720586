import React from 'react';

import Text from './Text';
import { text22b, fonts } from '../../utilities/styles';
import { useTheme } from '@material-ui/core';

const HeaderText = ({ children, style }) => {
  const theme = useTheme();
  const customStyle = {
    ...text22b,
    ...style,
    letterSpacing: '0.035em',
    fontWeight: 600,
    color: theme.palette.common.darkBlack,
  };

  return (
    <Text
      fontFamily={fonts.galano.value}
      margin={'0 0 10px 0'}
      style={customStyle}
    >
      {children}
    </Text>
  );
};

export default HeaderText;
