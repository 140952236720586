const lightTheme = {
    spacing: 8,
    typography: {
      fontFamily: `"Rubic", sans-serif`,
    },
    palette: {
      common: {
        lightBlack: 'rgba(14, 18, 38, 1)',
        darkBlack: 'rgba(0, 23, 20, 1)',
        main: 'rgba(0, 0, 17, 1)',
        light_blue: '#E6F2FF',
        heading_black: '#0E1226',
        white: '#ffffff',
        timer_black: '#001714',
      },
      primary: {
        //blue
        dark: 'rgba(56, 84, 225, 1)',
        main: 'rgba(38, 57, 153, 1)',
        light: 'rgba(56, 84, 225, 0.05)',
        skyBlue: '#CCF4EE',
        darkBlue: '#01988C',
        successBlue: '#00C6A8',
        toolTipBlue: '#111236',
      },
      secondary: {
        //gray
        lighter: 'rgba(250, 250, 250, 1)',
        light: 'rgba(234, 234, 234, 1)',
        main: 'rgba(14, 18, 38, 0.4)',
        dark: 'rgba(51, 69, 67, 0.6)',
        darker: 'rgba(14, 18, 38, 0.7)',
        gray: 'rgba(216, 216, 216, 1)',
        ebony: 'rgba(14, 18, 38, 1)',
        bullet_gray: 'rgb(204, 204, 204)',
        sentinal_gray: 'rgba(110, 113, 125, 1)',
      },
      error: {
        //red
        light: '#FFE2DB',
        main: '#D70000',
        darker: 'rgba(227, 69, 82, 1)',
        orangeDark: 'rgba(253, 100, 100, 0.1)',
        orangeLight: '#FFF0F0',
        orageLighter: '#FD6464',
      },
      success: {
        //green
        lighter: 'rgba(204, 244, 238, 1)',
        light: 'rgba(0, 198, 168, 1)',
        main: '#1A936F',
        dark: 'rgba(1, 152, 140, 1)',
        lightblue: 'rgb(106 123 253);'
      },
      warning: {
        //yellow
        lighter: 'rgba(255, 229, 181, 0.5)',
        light: 'rgba(255, 229, 181, 1)',
        main: '#FFB800',
      },
    },
  };
  
  export { lightTheme };
  