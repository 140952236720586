import React, { useState,useEffect } from 'react';

import {useLocation, useNavigate} from 'react-router-dom';
import OtpInput from 'react-otp-input';
import {
   Box, Typography, useTheme
} from '@material-ui/core';

import { StyledBox } from '../../../components/StyledComponents/StyledBox';
import Text from '../../../components/shared/Text';
import {
    inheritWidth,
    googleLoginButton,
    inheritHeight,
    inheritSize,
    fcJc,
    loginContainer,
    fcAllCenter,
    otpTextStyle,
    otpTextFocusStyle,
    otpContainer,
    frJe
} from '../../../utilities/styles/index';
import Button from '../../../components/StyledComponents/Button';
import Loader from '../../../components/Loader';
import OtpTimer from '../../../components/OtpTimer';
import { VerifyMobileOTP, ResendMobileOTP, Login } from '../../../hooks/fetchQuery'

const PhoneOtp = ({ onSuccessFromGoogle, onFailureFromGoogle }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { mutate: verifyOTP,isLoading, isFetching,
         data: VerifyMobileOTPData } = VerifyMobileOTP()
    const { mutate: resendOTP,
         data: resendMobileOTPData } = ResendMobileOTP()
    const { mutate: login, data: loginData } = Login()

    const theme = useTheme();
    const [otpValue, setOtpValue] = useState('')
    const deviceToken = VerifyMobileOTPData?.headers['x-device-token']
    const phoneVerify=VerifyMobileOTPData?.headers['x-phone-verify']
    const emailVerify=VerifyMobileOTPData?.headers['x-email-verify']
    const [otpVerified,_otpVerified]=useState(false)
    const [buttonDisabled,_buttonDisabled]=useState(true)
    const [resendDisabled,_resendDisabled]=useState(true)

    const checkTimer = (timerStatus) => {
        _resendDisabled(timerStatus);
  };

    const handleChangeOtp = (value) => {
        if(value.length==6){
            _buttonDisabled(false)
        }
        else{
            _buttonDisabled(true)
        }
        setOtpValue(value)

    }
    const onSubmitHandlerOtp = () => {
        const data = {
            "requestId": location?.state?.GenerateMobileOTPData?.data?.client_transaction_id,
            "phone": {
                code : '+91',
                number: location.state.phoneNumber
            },
            "otp": otpValue,
            "purpose": "PHONE_VERIFICATION"
        }
        verifyOTP(data)
    }
 
    useEffect(() => {
        if(loginData?.status === 200){
            navigate('/')
        }
    },[loginData?.status])

    console.log('loginData?.status', loginData)

    // useEffect(() => {
    //     if(deviceToken){
    //         const data = {
    //             'x-device-token' : deviceToken
    //         }
    //         login(data)
    //     }
    // },[deviceToken])

    const handleResendOTP = () => {
        const data = {
            "server_transaction_id": location?.state?.GenerateMobileOTPData?.data?.server_transaction_id,
        }
        resendOTP(data)
    }

    const navigateToSignup=()=>{
        navigate('/signup')
    }
    useEffect(() => {
        if( VerifyMobileOTPData?.status === 200){
         _otpVerified(true)
         if(location?.state?.route==="signUpGoogle"){
            if(deviceToken){
                const data = {
                    'x-device-token' : deviceToken
                }
                login(data)
            }
         }
        }
    },[VerifyMobileOTPData])

    // useEffect(() => {
    //   if(otpVerified && location.state.route=="signup"){
    //     navigate('/signup', {state:{isOtpVerifiedSignup:otpVerified}})
    //   }
    // },[otpVerified])

    if (isLoading || isFetching)
    return (
      <>
        <Loader />
      </>
    );

    return (
        <div style={{ ...inheritSize, ...fcAllCenter }}>
          
            <StyledBox style={{ ...inheritHeight, ...fcJc, ...loginContainer }}>
            <Box>
                {/* <img src={blob} /> */}
                </Box>
                <Text
                    centered
                    bold
                    fontSize={20}
                    style={{
                        color: theme.palette.common.darkBlack,
                        margin: `20px 0px`,
                        bottom: '10%',
                        left: '15%',
                        zIndex: 2,
                    }}
                >
                    Login with OTP
                </Text>
                <Text
                    centered
                    fontSize={12}
                    style={{
                        color: theme.palette.common.darkBlack,
                        margin: `20px 0px`,
                        bottom: '10%',
                        left: '15%',
                        zIndex: 2,
                    }}
                >
                    We've sent an OTP to the mobile number +91  {location?.state?.phoneNumber}. Please enter it below to complete verification.
                </Text>
                <Box style={{ ...otpContainer }}>
                    <OtpInput
                        inputStyle={{ ...otpTextStyle}}
                        focusStyle={{ ...otpTextFocusStyle }}
                        numInputs={6}
                        separator={<span>&nbsp;&nbsp;</span>}
                        onChange={(value) => handleChangeOtp(value)}
                        value={otpValue}
                        isInputNum={true}
                        shouldAutoFocus={true}
                    />
                </Box>
                <Box style={{ ...frJe,     justifyContent: 'space-around' }} className="mb-32">
                    <Typography
                            style={{
                                color: '#800020'
                            }}
                        >  {VerifyMobileOTPData?.headers['x-nm-error-msg'] || loginData?.headers['x-nm-error-msg']}
                        </Typography>
                        {resendDisabled ? "" :    
                    <Typography
                        style={{
                            color: theme.palette.primary.main
                        }}
                    > <span 
                    style={{color:theme.palette.primary.main, cursor: 'pointer'}}
                    onClick={handleResendOTP}
                    > Resend Otp </span>
                    </Typography>
                     }
                    <OtpTimer checkTimer={checkTimer}/>
                </Box>
                <Box>
                    <Button
                        style={{...inheritWidth,...googleLoginButton}}
                        onClick={onSubmitHandlerOtp}
                        primary
                        disabled={buttonDisabled}
                    >
                        Continue
                    </Button>
                </Box>
                <Text
                        centered
                        fontSize={16}
                        style={{
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                    >
                        Don't have an account yet ? <span 
                        style={{color:theme.palette.primary.main}}
                        onClick={navigateToSignup}
                        >Sign Up</span>
                    </Text>
            </StyledBox>
        </div>
    );
}

export default PhoneOtp