import React,{useState} from 'react';
import { Box } from '@material-ui/core';

import LinkTextSM from '../shared/LinkTextSM';
import Button from '../StyledComponents/Button';

const ExternalServerErrorUI = () => {
const [showOverlay,_showOverlay]=useState(false)
  const onClickHandler = () => {
    // window.exitFromPWA();
    _showOverlay(true)
  };
  return (
    <>
      <LinkTextSM style={{ margin: '10px' }}>Contact Us</LinkTextSM>
      <Box>
        <Button onClick={onClickHandler} style={{ width: '100%' }}>
          Okay
        </Button>
      </Box>
    </>
  );
};

export default ExternalServerErrorUI;
