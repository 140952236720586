import { withStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";

import { mainAppContainer } from "../../utilities/styles";


const MainAppContainer = withStyles((theme) => ({
    root: {
      marginTop: mainAppContainer.marginTop,
      height:"90%",
      ['@media (max-width:780px)']: {
        marginTop:'0px',
        height:"100%",
      },
    },
  }))(Box);

export default MainAppContainer;