import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box, Typography, useTheme } from '@material-ui/core';
import Text from './Text';
import {
  frAc,
  text12,
  frJsb,
  inheritWidth,
  frJc,
  positionRelative,
  text20,
  positionAbsolute
} from '../../utilities/styles';
import { fonts } from '../../utilities/styles';

const ControlComponent = ({ listItem, value, index, optionCount }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        position: positionAbsolute.position,
        left: 0,
        ...frJc,
        ...inheritWidth,
      }}
    >
      <Box
        style={{
          ...inheritWidth,
          border: positionRelative.border,
          padding: positionRelative.padding,
          borderRadius:  positionRelative.borderRadius,
          border: `1px solid ${
            listItem.value === value
              ? theme.palette.primary.dark
              : theme.palette.secondary.light
          }`,
          fontWeight: positionRelative.background,
          boxShadow: positionRelative.boxShadow,
          margin:
            index !== 1 && index !== optionCount
              ? '0 10px'
              : optionCount == 2 && index == 1
              ? `0 ${30 - index * 20}px 0 0`
              : '0 0',
        }}
      >
        <Text
          fontSize={14}
          fontFamily={fonts.lexend.value}
          style={{
            color: theme.palette.primary.dark,
             fontWeight: text20.fontWeight,
             textAlign: positionRelative.textAlign,
          }}
        >
          {listItem.label}
        </Text>
      </Box>
    </Box>
  );
};

const RadioOption = ({ listItem, value, index, optionCount }) => {
  return (
    <Box style={{ ...inheritWidth, margin: '14px 0px' }}>
      <FormControlLabel
        value={listItem.value}
        control={
          <ControlComponent
            listItem={listItem}
            value={value}
            index={index}
            optionCount={optionCount}
          />
        }
      />
    </Box>
  );
};

const RadioInput = ({
  required,
  value,
  displayName,
  list,
  errors,
  valueUpdateHandler,
}) => {
  const isError = errors?.length > 0;
  const theme = useTheme();

  return (
    <>
      <Box style={{ marginTop: '10px 0' }}>
        <Text
          fontSize={12}
          fontFamily={fonts.rubik.value}
          style={{
            color: theme.palette.secondary.dark,
             fontWeight: text20.fontWeight
          }}
        >
          {displayName}
          {` `}
          {required && '*'}
        </Text>
      </Box>
      <RadioGroup aria-label={displayName} name={displayName} value={value}>
        <Box style={{ ...inheritWidth, ...frJsb }}>
          {list.map((listItem, index) => (
            <Box
              key={index}
              style={{
                ...inheritWidth,
                position: positionRelative.position
              }}
              onClick={() => {
                valueUpdateHandler(listItem.value);
              }}
            >
              <RadioOption
                listItem={listItem}
                value={value}
                index={index + 1}
                optionCount={list.length}
              />
            </Box>
          ))}
        </Box>
      </RadioGroup>
      {isError && (
        <Box py={0.5} px={2} component='small' style={{ ...frAc }}>
          <Typography
            style={{
              ...text12,
              color: theme.palette.error.main,
              fontFamily: fonts.rubik.value,
               fontWeight: text20.fontWeight
            }}
          >
            {errors[0]}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RadioInput;
