import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
 Box,useTheme
} from '@material-ui/core';

import { StyledBox } from '../../../components/StyledComponents/StyledBox';
import TextInput from '../../../components/shared/Textinput';
import Text from '../../../components/shared/Text';
import {
    inheritHeight,
    inheritWidth,
    inheritSize,
    fcJc,
    loginContainer,
    fcAllCenter,
    googleLoginButton
} from '../../../utilities/styles/index';
import Button from '../../../components/StyledComponents/Button';
import Loader from '../../../components/Loader'
import GoogleLogin from "../../../components/GoogleLogin"
import { GenerateEmailOTP,VerifyGoogleLogin,Login } from '../../../hooks/fetchQuery'
import wave from '../../../assets/wave.svg'
import reverseWave from '../../../assets/reverseWave.svg'
import { uuid,checkValidEmail } from '../../../helper-methods'

const LoginForm = () => {
    const theme=useTheme()
    const navigate = useNavigate()
    const { mutate: generateOTP, isLoading, isFetching,data: GenerateEmailOTPData } = GenerateEmailOTP()
    const { mutate: verifyLoginGmail, isLoading:isLoadingGLogin, isFetching:isFetchingGlogin,data: VerifyGoogleLoginData } = VerifyGoogleLogin()
    const { mutate: login, data } = Login()
    const deviceToken = VerifyGoogleLoginData?.headers['x-device-token']
    const phoneVerifyGmail = VerifyGoogleLoginData?.headers['x-phone-verify']
    const emailVerifyGmail = VerifyGoogleLoginData?.headers['x-email-verify']

    const [emailId, setEmailID] = useState('')
    const [isLoginDisabled, setLoginDisabled]=useState(true)

    const onSubmitHandler = () => {
        const data = {
            "requestId": uuid(),
            "email": {
                "email": emailId
            },
            "purpose": "EMAIL_VERIFICATION"
        }
        generateOTP(data)
    }
    const onSuccessFromGoogle = (response) => {
        console.log("response from google::",response)
        if(response && response?.accessToken){
            const data={
                type : "google",
                userId:response?.profileObj?.email,
                token:response?.tokenId
            }
            verifyLoginGmail(data)
        }
    }

    const onFailureFromGoogle = (response) => {
        console.log('onSuccess ff', response)
    }

    const valueUpdateHandler = (response) => {
        console.log("The response ==",response)
        if(checkValidEmail(response)){
            setLoginDisabled(false)
        }
        else{
            setLoginDisabled(true)
        }

        setEmailID(response)
    };

    useEffect(() => {
        console.log("Generate email otp data ::",GenerateEmailOTPData?.status)
        if( GenerateEmailOTPData?.status === 200){
            console.log("inside generate otp")
        return navigate('/otp', {state:{emailId: emailId, GenerateEmailOTPData: GenerateEmailOTPData,route:"login" }})
        }
    },[GenerateEmailOTPData])

    useEffect(()=>{
  if(data){

        localStorage.setItem("user11","true")
        window.location.reload();
}
    },[data])

    useEffect(() => {
        console.log("After verify google login ",VerifyGoogleLoginData, phoneVerifyGmail, emailVerifyGmail)
        if( VerifyGoogleLoginData?.status === 200 && (phoneVerifyGmail === 'true' && emailVerifyGmail === 'true')){

            console.log("After verify google login ")
            const data = {
                'x-device-token': deviceToken
        }
        login(data)
        }
        if( VerifyGoogleLoginData?.status === 200 && (phoneVerifyGmail === 'false' && emailVerifyGmail === 'true')){
        navigate('/mobile-signup', {state: {emailId: emailId, GenerateEmailOTPData: GenerateEmailOTPData, route:"login" }})
    }
    },[VerifyGoogleLoginData])

    const navigateToSignup=()=>{
        navigate('/signup')
    }

    if (isLoading || isFetching || isLoadingGLogin||isFetchingGlogin)
    return (
      <>
        <Loader />
      </>
    );

    return (
        <div style={{ ...inheritSize, ...fcAllCenter }}>
            <StyledBox style={{ ...inheritHeight, ...fcJc, ...loginContainer }}>
                <Box style={{
                    width: '560px',
                    marginLeft: '-40px',
                    marginTop: '-40px',
                    position: 'relative',
                    borderRadius: '7px'
                }}>
                    <img src={reverseWave} alt=""/>
                </Box>
                <img style={{
                    height: '109px', width: '34%',
                    position: 'relative',
                    marginLeft: '-35px',
                    marginTop: '-194px',
                }}
                    src='https://www.niyomoney.com/gw-web-assets/img/niyo-money/Niyo_Money_white.png' alt="" />
                <Box>
                    <Text
                        centered
                        bold
                        fontSize={20}
                        style={{
                            // color: theme.palette.common.darkBlack,
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                    >
                        Login
                    </Text>
                    <Text
                        centered
                        fontSize={16}
                        style={{
                            // color: theme.palette.common.darkBlack,
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                    >
                        Or
                    </Text>
                    <Box style={{ ...inheritWidth }} className="mb-32" >
                        <GoogleLogin
                            onSuccess={onSuccessFromGoogle}
                            onFailure={onFailureFromGoogle}
                        />
                    </Box>
                </Box>
                <Box className='mb-32' style={{width: '97%'}}>
                    <TextInput
                        required={true}
                        autoFocus
                        fullWidth ={false}
                        label={"Email Id"}
                        valueUpdateHandler={valueUpdateHandler}
                    />
                </Box>
                <Box className='mb-32'>
                    <Button
                        style={{...inheritWidth, ...googleLoginButton}}
                        onClick={onSubmitHandler}
                        primary
                        disabled={isLoginDisabled}
                    >
                        Send OTP
                    </Button>
                </Box>
                <Box>
                    <Text
                        centered
                        fontSize={16}
                        style={{
                            // color: theme.palette.common.darkBlack,
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                    >
                        Don't have an account yet ? <span 
                        style={{color:theme.palette.primary.main}}
                        onClick={navigateToSignup}
                        >Sign Up</span>
                    </Text>
                </Box>
                <Box style={{
                    width: '560px',
                    marginLeft: '-40px',
                    borderRadius: '15px',
                    position: 'relative',
                    marginBottom: '-44px'
                }}>
                    <img src={wave} style={{ borderRadius: '7px' }} alt=""/>
                </Box>
            </StyledBox>

        </div>
    );
}

export default LoginForm