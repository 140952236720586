import React, { useEffect, useState } from 'react';
import { useTheme, FormControl } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { Box } from '@material-ui/core';
import { StyledBox } from '../StyledComponents/StyledBox';
import TextInput from '../shared/Textinput';
import SelectInput from '../shared/SelectInput';
import {
  getAge,
  getCustomQuery,
  reverseDateFormat,
  setCustomQuery,
} from '../../helper-methods';
import { useCustomQuery, useIFSCCheck } from '../../hooks/fetchQuery';
import {
  fcAc,
  fcJsb,
  fonts,
  inheritHeight,
  inheritSize,
  inheritWidth,
  googleLoginButton,
  loginContainer
} from '../../utilities/styles';
import { useDebounce } from '../../helper-methods';
import Button from '../StyledComponents/Button';
import HeaderText from '../shared/HeaderText';
import RadioInput from '../shared/RadioInput';
import SectonNote from '../SectionNote';
import Text from '../shared/Text';
const Form = ({
  dobOverlayTrigger,
  subSection,
  section,
  continueButtonHandler,
  secondaryCtaHandler,
}) => {
  const queryClient = useQueryClient();
  const { data } = useCustomQuery({ section });
  const ifscQuery = useIFSCCheck(
    data?.inputFields?.bankDetails?.bankIfscCode?.value
  );
  const theme = useTheme();
  const inputFieldsData = data?.inputFields[subSection];
  const ifscFieldData = data?.inputFields['bankDetails']['bankIfscCode'];
  const [disableSumbit, _disableSumbit] = useState(true);
  const [guardianRequired, _guardianRequired] = useState(false);
  const cachedData = getCustomQuery({
    section: `${subSection}FormCache`,
    queryClient,
  });

  useEffect(() => {
    setGuardianRequired();
    setDisableButton();
  });

  useEffect(() => {
    if (cachedData) {
      const inputFieldKeys = Object.keys(inputFieldsData);
      const mapped = inputFieldKeys.map((key) => {
        if (
          inputFieldsData[key]?.type === 'date' &&
          cachedData[key] &&
          cachedData[key]?.length > 0
        )
          return {
            [key]: {
              ...inputFieldsData[key],
              value: reverseDateFormat(cachedData[key]),
            },
          };
        return { [key]: { ...inputFieldsData[key], value: cachedData[key] } };
      });
      const updatedInputedFields = Object.assign({}, ...mapped);
      const newData = {
        ...data,
        inputFields: {
          ...data.inputFields,
          [subSection]: updatedInputedFields,
        },
      };

      setCustomQuery({ section, data: newData, queryClient });
    }
  }, [subSection]);

  useDebounce(
    async () => {
      if (
        subSection == 'bankDetails' &&
        inputFieldsData['bankIfscCode']?.errors.length == 0 &&
        ifscFieldData?.value !== ''
      ) {
        await ifscQuery.refetch();
      }
    },
    [ifscFieldData?.value],
    1000
  );

  const setGuardianRequired = () => {
    let isGuardianRequired = false;
    if (subSection === 'nomineeDetails') {
      const nomineeDob = inputFieldsData['nomineeDob']?.value;
      const nomineeAge = getAge(nomineeDob);
      if (nomineeAge < 18) {
        isGuardianRequired = true;
      } else {
        isGuardianRequired = false;
      }
    }
    if (guardianRequired != isGuardianRequired) {
      _guardianRequired(isGuardianRequired);
    }
    return isGuardianRequired;
  };

  const setDisableButton = () => {
    let isDisabled = false;
    const inputKeys = inputFieldsData && Object.keys(inputFieldsData);
    for (let i = 0; i < inputKeys?.length; i++) {
      const inputKey = inputKeys[i];
      const inputKeyReqiured =
        inputKey === 'guardianName'
          ? guardianRequired
          : inputFieldsData[inputKey]?.required;
      const inputKeyValue = inputFieldsData[inputKey]?.value;
    console.log('disableSumbit', inputFieldsData[inputKey])

      const inputKeyError =
        inputFieldsData[inputKey]?.errors &&
        inputFieldsData[inputKey]?.errors.length;
      if (
        inputKeyReqiured &&
        (inputKeyValue == '' ||
          inputKeyValue == undefined ||
          inputKeyValue == null ||
          inputKeyError)
      ) {
        isDisabled = true;
        break;
      } else if (
        inputKey === 'bankIfscCode' &&
        (!ifscQuery?.data ||
          ifscQuery?.data?.data?.data?.message ||
          ifscQuery?.data?.data?.data.length > 1)
      ) {
        isDisabled = true;
        break;
      } else if (
        inputKey === 'dob' &&
        getAge(inputFieldsData['dob']?.value) < 18
      ) {
        isDisabled = true;
        break;
      } else {
        isDisabled = false;
      }
    }
    if (disableSumbit !== isDisabled) {
      _disableSumbit(isDisabled);
    }
    return isDisabled;
  };

  const updateInputValue = ({ key, value }) => {
    setCustomQuery({
      section: section,
      data: {
        ...data,
        inputFields: {
          ...data.inputFields,
          [subSection]: {
            ...data.inputFields[subSection],
            [key]: {
              ...data.inputFields[subSection][key],
              value: value,
            },
          },
        },
      },
      queryClient: queryClient,
    });
  };

  const updateErrorsValue = ({ key, errorValue }) => {
    const errors = inputFieldsData[key].errors; //error array for the specified key
    if (errorValue?.length) {
      const errorIndex = errors.findIndex((item) => item === errorValue);
      if (errorIndex === -1) {
        errors.push(errorValue);
      }
      if (key == 'bankIfscCode') {
        setCustomQuery({ section: 'ifscCheck', data: null, queryClient });
      }
    } else {
      errors.pop();
    }

    setCustomQuery({
      section: section,
      data: {
        ...data,
        inputFields: {
          ...data.inputFields,
          [subSection]: {
            ...data.inputFields[subSection],
            [key]: {
              ...data.inputFields[subSection][key],
              errors: errors,
            },
          },
        },
      },
      queryClient: queryClient,
    });
  };

  const validateInputLength = ({ key, value, minLength, maxLength }) => {
    let errorMessage = null;
    let isValidLength = true;
    if (minLength && value?.length < minLength) {
      errorMessage = `Minimum ${minLength} characters required`;
      isValidLength = false;
    } else if (maxLength && value?.length > maxLength) {
      errorMessage = `Maximum ${maxLength} characters allowed`;
      isValidLength = false;
    }
    updateErrorsValue({ key: key, errorValue: errorMessage });
    return isValidLength;
  };

  const verifyInputRegex = ({ key, regex, value }) => {
    if (!regex?.length) return false;
    const modifiedReg = new RegExp(regex?.substring(1).slice(0, -1));
    const regExp = new RegExp(modifiedReg);
    const isVerified = regExp.test(value);
    if (!isVerified)
      updateErrorsValue({
        key: key,
        errorValue: inputFieldsData[key].errormsg,
      });
    return isVerified;
  };

  const valueUpdateHandler = ({ key, value }) => {
    const inputItem = inputFieldsData[key];

    if (inputItem.type === 'date') {
      updateInputValue({ key, value });
    } else if (inputItem.Type === 'radio') {
      updateInputValue({ key, value });
    } else if (
      validateInputLength({
        key: key,
        value: value,
        minLength: inputItem.minLength,
        maxLength: inputItem.maxLength,
      })
    ) {
      if (
        inputItem.regex &&
        verifyInputRegex({ key: key, regex: inputItem.regex, value })
      ) {
        updateInputValue({ key, value });
      }
    }
    updateInputValue({ key, value });
  };

  return (
    <>
      {data && (
        <div style={{ ...inheritSize, ...fcAc }}>
          <StyledBox style={{ ...inheritHeight, ...fcJsb, ...loginContainer }}>
            <div>
              <HeaderText
                style={{
                  color: theme.palette.common.darkBlack,
                  margin: '0 0 20px 0',
                  fontWeight: 900,
                }}
              >
                {data?.tabs?.[subSection]?.displayName}
              </HeaderText>
              {data?.tabs?.[subSection]?.sectionNote && (
                <SectonNote>{data?.tabs?.[subSection]?.sectionNote}</SectonNote>
              )}
              {Object.keys(inputFieldsData).map((inputKey, index) => {
                const currentInputData = inputFieldsData[inputKey];
                const inputType = inputFieldsData[inputKey]['type'];
                return (
                  <Box key={index}>
                    <Box key={index} style={{ margin: '50px 0px' }}>
                      <FormControl
                        component='fieldset'
                        style={{ ...inheritWidth }}
                      >
                        {inputType == 'radio' ? (
                          <RadioInput
                            {...currentInputData}
                            valueUpdateHandler={(value) => {
                              valueUpdateHandler({
                                key: inputKey,
                                value: value,
                              });
                            }}
                          />
                        ) : inputType == 'select' ? (
                          <SelectInput
                            {...currentInputData}
                            label={currentInputData.displayName}
                            valueUpdateHandler={(value) => {
                              valueUpdateHandler({
                                key: inputKey,
                                value: value,
                              });
                            }}
                          />
                        ) : inputKey == 'guardianName' ? (
                          <TextInput
                            inputKey={inputKey}
                            {...currentInputData}
                            required={guardianRequired}
                            autoFocus={index === 0}
                            label={currentInputData.displayName}
                            valueUpdateHandler={(value) => {
                              valueUpdateHandler({
                                key: inputKey,
                                value: value,
                              });
                            }}
                          />
                        ) : currentInputData['type'] == 'date' ? (
                          <TextInput
                            inputKey={inputKey}
                            {...currentInputData}
                            disabled={
                              inputKey == 'dob' &&
                              cachedData?.[inputKey] &&
                              cachedData?.[inputKey] != ''
                                ? true
                                : false
                            }
                            autoFocus={index === 0}
                            label={currentInputData.displayName}
                            dobOverlayTrigger={dobOverlayTrigger}
                            valueUpdateHandler={(value) => {
                              valueUpdateHandler({
                                key: inputKey,
                                value: value,
                              });
                            }}
                          />
                        ) : (
                          <TextInput
                            inputKey={inputKey}
                            {...currentInputData}
                            fullWidth = {true}
                            disabled={
                              (inputKey == 'pan' || inputKey == 'panName') &&
                              cachedData?.[inputKey] &&
                              cachedData?.[inputKey] != ''
                                ? false
                                : false
                            }
                            autoFocus={index === 0}
                            label={currentInputData.displayName}
                            valueUpdateHandler={(value) => {
                              valueUpdateHandler({
                                key: inputKey,
                                value: value,
                              });
                            }}
                          />
                        )}
                        {inputKey === 'bankIfscCode' &&
                          !inputFieldsData['bankIfscCode']?.errors.length &&
                          inputFieldsData['bankIfscCode']?.value && (
                            <Box
                              fontSize={12}
                              fontFamily={fonts.rubik.value}
                              style={{
                                color: theme.palette.common.lightBlack,
                                marginLeft: theme.spacing(2),
                                marginTop: '10px',
                                marginBottom: '10px',
                                lineHeight: '18px',
                                fontWeight: 400,
                                fontFamily: `${fonts.rubik.value}`,
                                letterSpacing: `0.002em`,
                              }}
                            >
                              {ifscQuery?.data ? (
                                <>
                                  {ifscQuery?.data?.data?.data.length > 1 ||
                                  ifscQuery?.data?.data?.data?.message ? (
                                    <> No IFSC Found...</>
                                  ) : (
                                    <>
                                      {ifscQuery?.data?.data?.data[0]['bank']}
                                      <br />
                                      {ifscQuery?.data?.data?.data[0]['branch']}
                                      <br />
                                      {ifscQuery?.data?.data?.data[0]['state']}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>Loading...</>
                              )}
                            </Box>
                          )}
                      </FormControl>
                    </Box>
                  </Box>
                );
              })}
            </div>
            <Box>
              {data['tabs'][subSection]['section_terms_header'] &&
                data['tabs'][subSection]['section_terms_description'] &&
                data['tabs'][subSection]['section_terms_header'] != '' &&
                data['tabs'][subSection]['section_terms_description'].length >
                  0 && (
                  <Box style={{ margin: '20px 0' }}>
                    {/* <Text
                      primary
                      fontSize={12}
                      regular
                      centered
                      fontFamily={fonts.rubik.value}
                      style={{
                        lineHeight: '18px',
                        textAlign: 'left',
                        marginLeft: '8px',
                      }}
                    >
                      {data['tabs'][subSection]['section_terms_header']}
                    </Text> */}

                    {/* <Box>
                      {data['tabs'][subSection][
                        'section_terms_description'
                      ].map((item) => (
                        <Text
                          fontSize={12}
                          fontFamily={fonts.rubik.value}
                          style={{
                            color: theme.palette.common.lightBlack,
                            marginLeft: '16px',
                            margin: '5px',
                            textAlign: 'left',
                            fontWeight: 400,
                          }}
                        >
                          <p
                            style={{
                              display: 'inline',
                              margin: '4px',
                              color: theme.palette.success.main,
                            }}
                          >
                            &#8226;
                          </p>{' '}
                          {item}
                        </Text>
                      ))}
                    </Box> */}
                  </Box>
                )}
              <Button
                // disabled={ subSection=="bankDetails"?true:disableSumbit}
                disabled={disableSumbit}
                onClick={continueButtonHandler}
                style={{ width: '100%', ...googleLoginButton, background : disableSumbit ? "#fff" :"#6b7afd",
                border: '0.5px solid rgb(196 196 196)'
               }}
              >
                {data['tabs'][subSection]['ctaText']}
              </Button>
            </Box>
          </StyledBox>
        </div>
      )}
    </>
  );
};

export default Form;
