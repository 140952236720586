import React, { useEffect, useState } from "react";

import CustomTimer from "./shared/CustomTimer";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import Text from "../components/shared/Text";
import timerImg from "../assets/timer.svg";
import { frJse, fonts, taf, text12 } from "../utilities/styles";

export const LoaderList = ({
  text,
  showTimerInitial,
  showError,
  handler,
  errorOverlay,
}) => {
  const base_url =
    "https://iee00qgi51.execute-api.ap-south-1.amazonaws.com/dev/nm/onboarding/v1/";
  const [varData, setVarData] = useState([]);
  const [showTimer, setShowTimer] = useState(showTimerInitial);
  const [error, setError] = useState(showError);
  const theme = useTheme();

  useEffect(() => {
    setShowTimer(showTimerInitial);
  }, [showTimerInitial]);

  useEffect(() => {
    setError(showError);
  }, [showError]);

  const errorSideEffect = () => {
    setError(true);
    setShowTimer(false);
  };

  useEffect(async () => {
    handler(errorSideEffect);
  }, []);

  return (
    <>
      {showTimer ? (
        <Text
          fontSize={text12.fontSize}
          fontFamily={fonts.lexend}
          style={{
            color: theme.palette.common.timer_black,
            position: "absolute",
            left: "0%",
          }}
        >
          <img src={timerImg} />
          <span style={{ position: "absolute", top: "-1px", left: "22px" }}>
            {" "}
            <CustomTimer handleTimerOver={errorOverlay} />{" "}
          </span>
        </Text>
      ) : (
        <Text
          fontSize={text12.fontSize}
          fontFamily={fonts.lexend.value}
          style={{
            color: theme.palette.common.timer_black,
            position: "absolute",
            left: "0%",
          }}
        >
          {!error ? (
            <CheckCircleIcon
              style={{ color: theme.palette.primary.successBlue }}
            />
          ) : (
            <ErrorOutlineIcon style={{ color: theme.palette.error.darker }} />
          )}
        </Text>
      )}
      <Box
        sx={{
          textAlign: taf.textAlign,
          display: frJse.display,
        }}
      >
        <Text
          fontSize={text12.fontSize}
          fontFamily={fonts.rubik.value}
          style={{
            color: "#000",
          }}
        >
          {text}
        </Text>
      </Box>
    </>
  );
};
