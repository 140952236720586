import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTheme, Box } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCustomQuery, useSampleJson, useGetDocumentDownload } from '../../hooks/fetchQuery';
import useDeviceDetect from '../../helper-methods/UseDeviceDetect';
import {
  setCustomQuery,
  getSectionDataNew,
  getOverlayComponent,
  setBackButtonPath,
  getBackButtonPath,
  setAccountActivationData,
  generatePresignedUrl,
  uploadDataToS3,
  saveDataUpload,
  getCustomQuery,
  updateSingleAccountActivationStatus,
  removeRejectedRemarks,
  refreshDetails,
  checkFileExtension
} from '../../helper-methods';
import {
  imageContainer,
  fcAc,
  fcJsb,
  inheritHeight,
  inheritSize,
  frAllCenter,
  documentPassword,
  fonts,
  EditDocUpload,
  pdfError,
  imageContainerSignature,
  loginContainer,
  googleLoginButton,
  positionAbsolute,
  inheritWidth
} from '../../utilities/styles';
import { makeStyles } from '@material-ui/core/styles';
import { StyledBox } from '../../components/StyledComponents/StyledBox';
import Button from '../../components/StyledComponents/Button';
import Text from '../../components/shared/Text';
import HeaderText from '../../components/shared/HeaderText';
import FullpageLoader from '../../components/shared/FullpageLoader';
import NoteComponent from '../../components/NoteComponent'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {
  overlayStates,
  // forwardNavigationMapping,
  accountActivationMapping,
  bankDocFileType
} from '../../helper-methods/constants';
import { allowedSourcesBankDocNative } from '../../utilities/Constants';
import EditIcon from '../../assets/editIcon.svg';
import ChequePlaceHolderImage from '../../assets/BankImage.jpg'
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
var AES = require('crypto-js/aes');
var SHA256 = require('crypto-js/sha256');
var CryptoJS = require('crypto-js');
// import aes256 from 'aes256'
// var aes256 = require('aes256');
const useStyles = makeStyles(() => ({
  labelEdit: {
    color: 'blue',
    cursor: 'pointer',
    paddingLeft: '5px',
  },
}));

const Cheque = ({ section, uploadType }) => {
  const { data: customerId } = useCustomQuery({ section: 'customerId' });
  const { data: homeSectionQueryData } = useCustomQuery({ section: 'home' });
  const { data: customerStatus } = useCustomQuery({ section: 'customerStatus' });
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const { isMobile } = useDeviceDetect();
  const { isLoading, data, isFetching, refetch } = useSampleJson();
  const getDocumentDownloadQuery = useGetDocumentDownload({
    section,
    payload: {
      customerId: customerId,
      document_type: uploadType,
    },
  });
  const sectionQuery = useCustomQuery({ section: section });
  const [requirePassword, setRequirePassword] = useState(false);
  const [overlayState, setOverlayState] = useState(null);
  const [password, setPassword] = useState('');
  const [fileUploadData, _mobileUploadData] = useState();
  const sectionData = sectionQuery?.data?.sections;
  const [uploadingData, _uploadingData] = useState(0);
  const [onLoadSuccessPdf, _setOnLoadSuccessPdf] = useState(false);
  const [extensionOfFile, __setExtensionOfFile] = useState(null)
  const messages = sectionQuery?.data?.messages;
  const { data: forwardNavigationMapping } = useCustomQuery({
    section: 'forwardNavigationMapping',
  });
  const rejectionData = getCustomQuery({
    section: 'rejectedRemarks',
    queryClient,
  })?.[section.toLowerCase()];
  const [disableButton, setDisableButton] = useState(false);
  /**
   * uploadingData = -1; upload-error
   * uploadingData = 0; not_started
   * uploadingData = 1; processing
   * uploadingData = 2; success
   */
  console.log("query download ::", getDocumentDownloadQuery?.data?.data?.data?.url)
  useEffect(async () => {
    const backPathData = getBackButtonPath({ queryClient });
    if (backPathData?.redirectToHomeFrom === uploadType) {
      setBackButtonPath({ data: '/home', queryClient });
    }
    console.log('rejectionData', rejectionData);
    if (rejectionData && rejectionData?.remarks) {
      console.log('rejection-remarks found, query removed');
      getDocumentDownloadQuery?.remove();
      console.log({ getDocumentDownloadQuery: getDocumentDownloadQuery });
    } else {
      console.log('no-rejection-remarks-found, api called');
      getDocumentDownloadQuery?.refetch();
      console.log({ getDocumentDownloadQuery: getDocumentDownloadQuery });
    }
    if (data) return;
    await refetch();
  }, []);

  useEffect(() => {
    if (isLoading || isFetching || sectionQuery.data) return;
    setCustomQuery({
      section: section,
      data: data ? getSectionDataNew({ section, response: data }) : null,
      queryClient: queryClient,
    });
  }, [data]);

  useEffect(async () => {
    switch (uploadingData) {
      case -1:
        // upload-error
        setOverlayState(overlayStates.UPLOAD_ERROR);
        _uploadingData(0);
        break;
      case 0:
        // not_started
        break;
      case 1:
        // processing
        try {
          let dataToSend = null;
          if (fileUploadData) {
            console.log({ fileUploadData });
            const preSignedUrl = await generatePresignedUrl({
              customerId,
              document_type: uploadType,
              format: extensionOfFile,
            });
            console.log("The presigned url ==", preSignedUrl)
            await uploadDataToS3(
              !requirePassword
                ? {
                  format: extensionOfFile,
                  preSignedUrl: preSignedUrl?.data?.data?.data?.url,
                  base64String: fileUploadData,
                }
                : {
                  format: extensionOfFile,
                  preSignedUrl: preSignedUrl?.data?.data?.data?.url,
                  base64String: fileUploadData,
                  password: CryptoJS.AES.encrypt(
                    password,
                    preSignedUrl?.data?.data?.data?.url?.split('?')[0]
                  ),
                }
            );
            dataToSend = {
              customerId: customerId,
              document_type: uploadType,
              documentUrl: preSignedUrl?.data?.data?.data?.url.split('?')[0],
              doc_name: `${uploadType}_${customerId}`,
              format: extensionOfFile,
            };
          } else if (getDocumentDownloadQuery?.data?.data?.data?.url) {
            dataToSend = {
              customerId: customerId,
              document_type: uploadType,
              documentUrl:
                getDocumentDownloadQuery?.data?.data?.data?.url.split('?')[0],
              doc_name: `${uploadType}_${customerId}`,
              format: getDocumentDownloadQuery?.data?.data?.data?.format,
            };
          }
          if (dataToSend) {
            console.log({ dataToSend });
            await saveDataUpload({ payload: dataToSend });
          } else {
            console.log('no data to send');
          }
          _uploadingData(2);
        } catch (e) {
          console.log('error : ', e);
          _uploadingData(-1);
        }
        break;
      case 2:
        // success
        console.log('all-documents-uploaded-successfully');
        setBackButtonPath({ data: null, queryClient });
        const { index: itemIndex } =
          accountActivationMapping[section.toLowerCase()];
        if (rejectionData) {
          removeRejectedRemarks({ keys: [section.toLowerCase()], queryClient });
          updateSingleAccountActivationStatus({
            itemIndex,
            homeSectionQueryData,
            queryClient,
            isCompleted: true,
            isDisabled: false,
            nextPath: forwardNavigationMapping[section],
            updateAllNextPath: true,
          });
        } else {
          setAccountActivationData({
            itemIndex: itemIndex + 1,
            isCompleted: false,
            isDisabled: false,
            nextPath: `${forwardNavigationMapping[section]}${search}`,
            queryClient,
            homeSectionQueryData,
            initialUpdate: false,
          });
        }
        _mobileUploadData(null);
        console.log('getDocumentDownloadQuery cleared');
        getDocumentDownloadQuery?.remove();
        navigate(`${forwardNavigationMapping[section]}${search}`);
        break;
    }
  }, [uploadingData]);

  const bankDocUploadMobile = async (event) => {
    let imgtype = event.target.files[0].name;
    __setExtensionOfFile(checkFileExtension(imgtype))

    if (bankDocFileType.includes(checkFileExtension(imgtype))) {
      console.log("inside bank upload")
      setDisableButton(false);
      console.log('disable button:', disableButton);
      setRequirePassword(false);
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        _mobileUploadData(reader.result)
      };
    }
  };
  const EditMobileDocUpload = async (event) => {

    let imgtype = event.target.files[0].name;
    __setExtensionOfFile(checkFileExtension(imgtype))

    if (bankDocFileType.includes(checkFileExtension(imgtype))) {
      console.log("inside bank upload")
      setDisableButton(false);
      console.log('disable button:', disableButton);
      setRequirePassword(false);
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        _mobileUploadData(reader.result)
      };
    }
  };

  // const onPdfPasswordCallback = () => {
  //   if (!requirePassword) {
  //     setRequirePassword(true);
  //   }
  // };
  const onPdfPasswordCallback = (callback, reason) => {
    console.log('Its a password protected pdf');
    const platformId = sessionStorage.getItem("platformId");
    const callbackProxy = (password) => {
      setPassword(password);
      callback(password);
    };

    if (reason == 1) {
      const password = getPassword();
      setRequirePassword(true);
      if (password) {
        console.log('if password condition:', password);
        callbackProxy(password);
        return;
      }
    } else {
      // setBottomSheetOpen(true);
      setRequirePassword(true);
      if (password) {
        console.log('if password condition:', password);
        callbackProxy(password);
        return;
      }
    }
    return;
  };
  const loadingPasswordProtectedPdf = () => {
    setDisableButton(true);
    return (
      <div style={{ ...pdfError }}>
        Please retry and enter correct password
      </div>
    );
  };
  const onErrorPdfFile = () => {
    console.log('Error pdf');
    setDisableButton(true);
    return (
      <div style={{ ...pdfError }}>
        Please retry and enter correct password.PDF Cannot be loaded{' '}
      </div>
    );
  };

  const onPasswordErrorPdf = () => {
    return (
      <div style={{ ...pdfError }}>
        Please retry and enter  correct password.{' '}
      </div>
    );
  }
  const onSuccessPdf = () => {
    console.log('Password on success ::', password);
    _setOnLoadSuccessPdf(true);
    setDisableButton(false);
  };
  const getPassword = () => {
    if (
      getDocumentDownloadQuery?.data?.data?.data?.metaData.password &&
      getDocumentDownloadQuery?.data?.data?.data?.url &&
      !fileUploadData
    ) {
      var DecryptPwd = CryptoJS.AES.decrypt(
        getDocumentDownloadQuery?.data?.data?.data?.metaData.password,
        getDocumentDownloadQuery?.data?.data?.url?.split('?')[0]
      );

      var pwdToSring = DecryptPwd.toString(CryptoJS.enc.Utf8);
      return pwdToSring;
    } else {
      var passwordFromPrompt = prompt('Please enter your password', '');
      return passwordFromPrompt;
    }
  };
  const closeOverlay = () => {
    console.log('close-overlay-called');
    setOverlayState(null);
  };

  const onSubmitHandler = async () => {
    console.log('Inside submit handler');
    _uploadingData(1);
    setBackButtonPath({ data: null, queryClient });
  };

  console.log(
    'docs_query',
    getDocumentDownloadQuery,
    getDocumentDownloadQuery?.isLoading,
    getDocumentDownloadQuery?.data
  );
  if (
    isLoading ||
    isFetching ||
    getDocumentDownloadQuery?.isLoading ||
    uploadingData == 1 ||
    uploadingData == 2
  )
    return (
      <>
        <FullpageLoader />
      </>
    );
  return (
    <>
      {overlayState &&
        getOverlayComponent({
          subSection: null,
          messages: messages,
          overlayState,
          closeOverlay,
          ctaHandler: closeOverlay,
        })}

      {sectionData && (
        <div style={{ ...inheritSize, ...fcAc }}>
          <StyledBox style={{ ...inheritHeight, ...fcJsb, ...loginContainer }}>
            <HeaderText
              style={{
                color: theme.palette.common.darkBlack,
              }}
            >
              {sectionData?.displayName}
            </HeaderText>

            {rejectionData?.remarks && (
              <Box
                style={{
                  background: theme.palette.error.orangeLight,
                  padding: '12px',
                  borderRadius: '2px',
                  margin: '12px 0 ',
                }}
              >
                <Text
                  style={{
                    color: theme.palette.error.orageLighter,
                    fontFamily: fonts.rubik.value,
                    fontSize: '10px',
                    textAlign: 'left',
                  }}
                >
                  {/* {LivelinessCheck.rejectedText} */}
                  Document Rejected
                </Text>
                <Text
                  style={{
                    color: theme.palette.common.main,
                    fontFamily: fonts.rubik.value,
                    fontSize: '12px',
                    textAlign: 'left',
                    lineHeight: '14px',
                    fontWeight: '400',
                  }}
                >
                  {rejectionData?.remarks}
                </Text>
              </Box>
            )}
            <Box>
              {extensionOfFile === 'pdf' ||
                fileUploadData?.includes('application/pdf') ||
                (getDocumentDownloadQuery?.data?.data?.data?.format == 'pdf' &&
                  !fileUploadData) ? (
                <div style={{ ...frAllCenter }}>
                  <Document
                    file={
                      // fileUploadData?.data
                      getDocumentDownloadQuery?.data?.data?.data?.url &&
                        !fileUploadData
                        ? getDocumentDownloadQuery?.data.data?.data?.url
                        : fileUploadData
                    }
                    className='pdf-view'
                    noData='Bank Document'
                    onPassword={onPdfPasswordCallback}
                    error={onPasswordErrorPdf}
                    onLoadError={onErrorPdfFile}
                    loading={
                      requirePassword
                        ? loadingPasswordProtectedPdf
                        : 'Loading...'
                    }
                    onLoadSuccess={onSuccessPdf}
                  >
                    <Page width={230} pageNumber={1} />
                  </Document>
                </div>
              ) : (
                <Box>
                  <img
                    src={
                      getDocumentDownloadQuery?.data?.data?.data?.url &&
                        !fileUploadData
                        ? getDocumentDownloadQuery?.data.data?.data?.url
                        : fileUploadData
                          ? fileUploadData
                          : ChequePlaceHolderImage
                    }
                    style={{ ...imageContainerSignature }}
                    className='pb-24 pt-24'
                  />
                  <img src={getDocumentDownloadQuery?.data?.data?.url} />
                </Box>
              )}
              {/* {requirePassword && (
                <div style={{ ...documentPassword }}>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    style={{ ...documentPassword.documentPasswordInput }}
                    type={sectionData?.pdfPassword?.type}
                    placeholder={sectionData?.pdfPassword?.placeholder}
                  />
                </div>
              )} */}

              {(getDocumentDownloadQuery?.data?.data?.data.status ===
                'SUCCESS' || fileUploadData) && (
                <div
                  style={{ ...EditDocUpload }}
                >
                  <span>
                    <img src={EditIcon} />
                  </span>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    name="imageUploadEdit"
                    id="imageUploadEdit"
                    style={{ display: "none" }}
                    onChange={(file) => EditMobileDocUpload(file)}
                  />
                  <label htmlFor='imageUploadEdit' className={classes.labelEdit}>
                    {sectionData?.edit_text}
                  </label>
                </div>
              )}
              
            </Box>
            <Box style={{ margin: '20px 0' }}>
              {/* <Text
                primary
                fontSize={12}
                regular
                centered
                fontFamily={fonts.rubik.value}
                style={{
                  lineHeight: '18px',
                }}
              >
                {sectionData?.note_header}
              </Text> */}
              {/* <Text
                primary
                fontSize={12}
                regular
                centered
                fontFamily={fonts.rubik.value}
                style={{
                  color: theme.palette.secondary.ebony,
                  margin: '20px 0',
                }}
              >
                {sectionQuery?.data.sections.note_desc}
              </Text> */}
              <NoteComponent
                textValue={sectionQuery?.data.sections.note_desc}
                style={{
                  fontSize: "12px",
                  lineHeight: "1.5",
                  margin: "20px 0 0 0"
                }}
              />
              <Text
                secondary
                fontSize={10}
                regular
                centered
                fontFamily={'Font Awesome 6 Pro'}
                style={{
                  color: theme.palette.secondary.darker,
                }}
              >
                {sectionQuery?.data.sections.secure_text}
              </Text>
            </Box>
            <Box>
              {getDocumentDownloadQuery?.data?.data?.data?.url ||
                fileUploadData ? (
                <Button
                  style={{ ...googleLoginButton, width: inheritWidth.width, background: disableButton ? "" : positionAbsolute.background }}
                  onClick={onSubmitHandler}
                  disabled={disableButton}
                >
                  Next
                </Button>
              ) : (
                <div style={{ marginTop: "50px" }}>
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    name="imageUpload"
                    id="imageUpload"
                    style={{ display: "none" }}
                    onChange={(file) => bankDocUploadMobile(file)}
                  />
                  <label
                    htmlFor="imageUpload"
                    className="btn btn-large"
                    style={{
                      width: "100%",
                      height: "56px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#56a0ed",
                      color: "white"
                    }}
                  >
                    {/* {rejectedScreen
                    ? "Re-submit Bank Document"
                    : "Upload Bank Document"} */}
                    {sectionData?.cta_text}
                  </label>
                </div>
              )}
            </Box>
          </StyledBox>
        </div>
      )}
    </>
  );
};
export default Cheque;
