import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from "react-query";

import Form from './Form';
import {
  accountActivationMapping,
  overlayStates,
  selectApiPath,
  navigationKND
} from '../../helper-methods/constants';
import { usePolling, useCustomQuery } from '../../hooks/fetchQuery';
import {
  getOverlayComponent,
  getPollingStatus,
  setBackButtonPath,
  getBackButtonPath,
  setAccountActivationData,
  updateFormCache,
  setCustomQuery,
  getCustomQuery
} from "../../helper-methods/index";

const AsyncFormItemContainer = ({
  subSection,
  section,
  onSubmitPostHandler,
  pollingAPI,
}) => {
  const [overlayState, setOverlayState] = useState(null);
  const sectionData = useCustomQuery({
    section,
  });
  const asyncPostQuery = useCustomQuery({
    section: selectApiPath[subSection],
  });
  const queryClient = useQueryClient();
  const messages = sectionData.data.messages;
  const navigate = useNavigate();
  const { search } = useLocation();
  const { data: homeSectionQueryData } = useCustomQuery({ section: 'home' });
  const inputFieldsData = sectionData?.data?.inputFields[subSection];
  const { data: customerStatus } = useCustomQuery({ section: 'customerStatus' });

  const pollingQueryFn = async () => {
    console.log('polling-query-function', asyncPostQuery);
    if (
      asyncPostQuery?.isLoading ||
      !asyncPostQuery?.data?.data?.data?.requestId
    ) {
      return null;
    } else {
      return await getPollingStatus({
        subSection,
        overlayState,
        pollingAPI,
        payload: {
          requestId: asyncPostQuery?.data?.data?.data?.requestId,
        },
      });
    }
  };

  const pollingQuery = usePolling({
    queryKey: `${selectApiPath[subSection]}Polling`,
    queryFn: pollingQueryFn,
    currentState: overlayState,
    pollingStopSideEffect: ({ status }) => {
      setOverlayState(status);
    },
  });

  console.log("The polling query data ==",pollingQuery.data)

  useEffect(()=>{
    console.log("Inside the polling query component did update")
    if(pollingQuery?.data?.status==="SUCCESS"){
      setCustomQuery({
        section: "flow",
        data: pollingQuery?.data?.data?.data?.data?.flow,
        queryClient,
      });
    }

  },[pollingQuery.data])

  useEffect(async () => {
    if (overlayState == overlayStates.POLLING) {
      await onSubmitPostHandler();
    }
    if (overlayState === overlayStates.SUCCESS) {
      updateFormCache({ inputFieldsData, queryClient, subSection });
    }
    if(overlayState === overlayStates.FAILURE && subSection=='bankDetails'){

      console.log("Bank Details Failure polling")

    }
    console.log("The overlay states====",overlayState)
    if (subSection === "panDetails" && overlayState === overlayStates.SUCCESS) {
      const flowData = getCustomQuery({
        section: `flow`,
        queryClient,
      });
      console.log("The flow data >>>>",flowData)
      console.log(`Came here ::,${search}`)
      
      // navigate(`${navigationKND[flowData?.completedTasks[flowData.completedTasks.length -1]]}`)
      // return navigate(`/${search}`);
    }
  }, [overlayState]);

  useEffect(() => {
    const backPathData = getBackButtonPath({ queryClient });
    if (backPathData?.redirectToHomeFrom === subSection) {
      setBackButtonPath({ data: '/home', queryClient });
    }
  }, [subSection]);

  const closeOverlay = () => {
    pollingQuery?.remove();
    setOverlayState(null);
  };

  const continueHandler = async () => {
    setOverlayState(overlayStates.POLLING);
  };

  const updateAccountActivationData = ({ itemIndex, isCompleted }) => {
    setAccountActivationData({
      itemIndex,
      isCompleted,
      isDisabled: false,
      nextPath: `${messages[subSection][overlayState]?.nextAction}${search}`,
      queryClient,
      homeSectionQueryData,
      initialUpdate: false,
    });
  };

  const ctaHandler = () => {
    if (overlayState === overlayStates.SUCCESS) {
      const { index: itemIndex } =
        accountActivationMapping[
          subSection === 'bankDetails' ? 'cheque' : subSection
        ];

      updateAccountActivationData({
        itemIndex: itemIndex + 1,
        isCompleted: false,
      });
      asyncPostQuery.remove();
      setBackButtonPath({ data: null, queryClient });
      navigate(`${messages[subSection][overlayState]?.nextAction}${search}`);
    } else {
      asyncPostQuery.remove();
      closeOverlay();
    }
  };

  const secondaryCtaHandler = () => {
    if (
      subSection === 'bankDetails' &&
      overlayState === overlayStates.FAILURE
    ) {
      const { index: itemIndex } = accountActivationMapping[subSection];

      updateAccountActivationData({
        itemIndex: itemIndex + 1,
        isCompleted: false,
      });
    }
    asyncPostQuery.remove();
    setBackButtonPath({ data: null, queryClient });
    navigate(`${messages[subSection][overlayState]?.nextAction}${search}`);
  };

  const dobOverlayTrigger = () => {
    setOverlayState(overlayStates.DOB_SPINNER);
  };

  const dobCloseHandler = () => {
    setOverlayState(null);
  };

  const _overlayStateSetter = (state) => setOverlayState(state);
  return (
    <>
      {overlayState &&
        getOverlayComponent({
          section,
          subSection,
          messages,
          overlayState,
          overlayStateSetter: _overlayStateSetter,
          closeOverlay,
          ctaHandler: ctaHandler,
          primaryCtaHandler: continueHandler,
          secondaryCtaHandler: secondaryCtaHandler,
          dobCloseHandler,
        })}
      <Form
        subSection={subSection}
        section={section}
        continueButtonHandler={continueHandler}
        dobOverlayTrigger={dobOverlayTrigger}
      />
    </>
  );
};

export default AsyncFormItemContainer;
