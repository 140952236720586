import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from '../pages/Login/LoginForm'
import SignupForm from '../pages/Login/SignupForm'
import OtpForm from '../pages/Login/EmailOtpForm'
import PhoneOtp from '../pages/Login/PhoneOtpForm';
import MobileOtpForm from '../pages/Login/SignupForm/MobileOtpForm';
import MainDashboard from '../pages/Dashboard';
import TransactionTimeLine from '../components/dashboardComponent/TransactionTimeline';
import FundInfo from '../pages/Invest/FundInfo';
import InvestmentOptions from '../pages/Invest/InvestmentOptions';
import SipInvest from '../pages/Invest/SipInvest';

export const DashboardRoutes =()=> {
  return (
    <Router>
    <Routes>
    <Route path = "/mainDashboard" element = { <MainDashboard />} />
    <Route path = "/invest" element = { <TransactionTimeLine />} />
    <Route path = "/timeline" element = { <TransactionTimeLine />} />
    <Route path = "/fundInfo" element = { <FundInfo />} />
    <Route path = "/investmentOptions" element = { <InvestmentOptions />} />
    <Route path = "/sip-invest" element = { <SipInvest />} />
    </Routes>
    </Router>
  );
}