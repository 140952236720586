import axios from 'axios'
import {
  decryptByAES,
  encryptByAES,
  encryptByRSA,
  generateAESKey,
  getInitializationVector,
  encryptHeaderByRSA
} from "./Encryt";
import {API_URL} from '../ApiConfig';

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance
  .interceptors
  .request
  .use(function (config) {

    if (sessionStorage.getItem('accessToken') && sessionStorage.getItem('accessToken') !== 'undefined') {

      config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('accessToken')
    }

    return config;
  }, (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

instance
  .interceptors
  .request
  .use(function (config) {

    const reqData = JSON.stringify(config.data);
    const publicKey = sessionStorage.getItem('publicKey')
    const publicKey_id = sessionStorage.getItem('publicKey_id')

    if (publicKey && publicKey_id) {
      const generatedAesKey = generateAESKey();
      const generatedIV = getInitializationVector();

      if (reqData) {
        config.data = encryptByAES(reqData, generatedAesKey, generatedIV);

      }

      if (config.headers['x-login-pin']) {
        config.headers['x-login-pin'] = encryptHeaderByRSA(config.headers['x-login-pin'], publicKey);
      }

      config.headers['x-custom-key'] = encryptByRSA(generatedAesKey, publicKey);
      config.headers['x-custom-iv'] = generatedIV;
      config.headers['x-crypto-version'] = 'v2';
      config.headers['x-key-id'] = publicKey_id;

      const keyInfo = {
        generatedAesKey,
        generatedIV
      }
      config['KEY_INFO'] = keyInfo;

    }

    // return Promise.reject(config);
    return config;
  }, (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

instance
  .interceptors
  .response
  .use(function (response) {

    if (response.data && response.config['KEY_INFO']) {
      const {generatedAesKey, generatedIV} = response.config['KEY_INFO'];

      response.data = decryptByAES(response.data, generatedAesKey, generatedIV);

    }

    return response;
  }, error => {
    

    if (error.response && error.response.status === 401) {
      if(!(window.location.href && window.location.href.includes('login'))){
      logout()
    }
  }
    if (error.response) {
      const response = error.response;

      if (response.data && response.config['KEY_INFO']) {
        const {generatedAesKey, generatedIV} = response.config['KEY_INFO'];

        response.data = decryptByAES(response.data, generatedAesKey, generatedIV);

      }
      return response;
    }

  
    return Promise.reject(error);
  });

function logout() {
  sessionStorage.clear();
  // store.dispatch({type: "LOG_OUT"})
  window
    .location
    .assign("/user/login?sessiontimeout=1")
}

export default instance
