import { lightTheme } from "../theme";

export const text10 = {
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "15px",
};
export const text12 = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20px",
};
export const text12lh15 = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "15px",
};
export const text12b = {
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "20px",
};
export const text14 = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
};
export const text14b = {
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",
};
export const text14ab = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "15px",
};

export const text14fw500 = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
}

export const text16 = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "25px",
};

export const text16ln20 = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
};

export const text16b = {
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "25px",
};
export const text20 = {
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "20px",
};
export const text20b = {
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "20px",
};
export const text22b = {
  fontSize: "22px",
  fontWeight: 600,
  lineHeight: "20px",
};
export const text23 = {
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20px",
}

export const text24 = {
  fontSize: "30px",
  fontWeight: 500,
  lineHeight: "35px"
}
export const text40 = {
  fontSize: "40px",
};

export const text25 = {
  fontSize: "25px",
};
export const br8 = {
  borderRadius: "8px",
};
export const br16 = {
  borderRadius: "16px",
};
export const br24 = {
  borderRadius: "24px",
};
export const br32 = {
  borderRadius: "32px",
};
export const fcAc = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export const fcJc = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
export const fcJs = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
};
export const fcJe = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
};
export const fcJsb = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
export const fcJsa = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
};
export const fcJse = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
};
export const fcAllCenter = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
export const frAc = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
export const frAs = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
};
export const frJc = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};
export const frJs = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
};
export const frJe = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
};
export const frJsb = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
export const frJsa = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
};
export const tac = {
  textAlign: "center",
};
export const taf = {
  textAlign: "left",
};

export const mAdB = {
  margin: "auto",
  display: "block"
}

export const frJse = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
};
export const frAllCenter = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

export const inheritSize = {
  height: "100%",
  width: "100%",
};

export const inheritWidth = {
  width: "100%",
};

export const inheritWidthImp = {
  width: "100% !important",
  color: "#6b7afd !important"
};
export const addMargin = {
  marginBottom: "10px",
}
export const height60 = {
  height: "60px",
};
export const inheritHeight = {
  // height: "100%",
};
export const paddingLeft20 = {
  paddingLeft: '20px'
}
export const displayNone = {
  display: 'none'
}
export const paddingLeft12 = {
  paddingLeft: '12px'
}
export const paddingLeft0 = {
  paddingLeft: '0px'
}
export const inputBorder = {
  border: '0.5px solid rgb(196 196 196)',
  boxShadow: 'rgb(0 0 0 / 9%) 0px 0px 15.4px 0.6px',
}
export const positionRelative = {
  position: "relative",
  background: '#ffffff',
  boxShadow: 'rgb(255 255 255) 0px 0px 15.4px 0.6px',
  border: '1px solid black',
  padding: '8px 0px',
  borderRadius: '30px',
  textAlign: 'center',
  alignItems: 'center'
};

export const positionAbsolute = {
  position: "absolute",
  boxShadow: '0 0 15.4px 0.6px rgb(0 0 0 / 9%)',
  borderRadius: '8px',
  height: '60px',
  background: '#6b7afd',
  height: "56px"
};

export const fonts = {
  lexend: {
    value: `"Lexend Deca", sans-serif`,
    style: { fontFamily: `"Lexend Deca", sans-serif` },
  },
  lexendNormal: {
    value: `"Lexend Deca", normal`,
    style: { fontFamily: `"Lexend Deca", normal` },
  },
  rubik: {
    value: `"Rubik", sans-serif`,
    style: { fontFamily: `"Rubic", sans-serif` },
  },
  galano: {
    value: `'Darker Grotesque', sans-serif`,
    style: { fontFamily: `'Darker Grotesque', sans-serif` },
  },
  rubikNormal: {
    value: `"Rubik", normal`,
    style: { fontFamily: `"Rubic", normal` },
  },
};

export const wrapperStyle = {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "row",
};

export const fr = {
  display: "flex",
  flexDirection: "row",
};

export const fc = {
  display: "flex",
  flexDirection: "column",
};

export const flex1 = {
  flex: 1,
};

export const trackingDataContainer = {
  backgroundColor: "#FFF1CE",
};
export const imageContainer = {
  display: "block",
  margin: "auto",
  width: "230px",
  height: "230px",
  maxWidth: "230px",
  maxHeight: "230px",
};
export const imageContainerSignature = {
  display: "block",
  margin: "auto",
  maxHeight: "230px",
  // width: '100%',
  // border: '0.5px solid #EAEAEA',
  marginTop: '20px',
  marginBottom: '20px',
  borderRadius: '7px'
};
export const imageContainerInitialLoader={
  display: "block",
  margin: "auto",
  maxHeight: "230px",
  width: '100%',
  border: '0.5px solid #EAEAEA',
  marginTop: '20px',
  marginBottom: '20px',
  borderRadius: '7px'
}
export const EditDocUpload = {
  display: "flex",
  justifyContent: "flex-end",
  lineHeight: "16px",
};
export const pdfError = {
  padding: "0px 0px 30px 0px",
  color: "red",
  fontSize: "18px",
};
export const documentPassword = {
  width: "100%",
  display: "flex",
  padding: "8px 10px",
  borderRadius: "2px",
  marginBottom: "32px",
  alignItems: "flex-start",
  backgroundColor: "#FAFAFA",
  border: "0.5px solid #EAEAEA",
  justifyContent: "space-between",
  documentPasswordInput: {
    width: "100%",
    height: "100%",
    border: "none",
    backgroundColor: "#FAFAFA",
  },
};
export const digilockerNoteContainer = {
  background: "rgb(229 245 231)",
  border: " 0.5px solid #eaeaea",
  boxSizing: "border-box",
  borderRadius: "7px",
  padding: "20px",

  toolTipInner: {
    backgroundColor: lightTheme.palette.primary.toolTipBlue,
  },
  descriptionHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between",
    color: "red",
  },
  descriptionItem: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },

  //  Need to modify this
  descriptionItemNumber: {
    border: "1px solid #0e1226",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    marginRight: "12px",
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const eSignNoteContainer = {
  background: lightTheme.palette.warning.lighter,
  border: `0.5px solid {lightTheme.palette.warning.light}`,
  boxSizing: "border-box",
  borderRadius: "2px",
  padding: "10px",
  marginBottom: "40px",
};


export const loginContainer = {
  boxShadow: "0 0 15.4px 0.6px rgb(0 0 0 / 30%)",
  padding: "40px",
}

export const otpContainer = {
  display: "flex",
  justifyContent: "center",
  padding: "40px 0"
}


export const otpTextStyle = {
  width: "40px",
  outline: 'none',
  outlineStyle: 'none',
  borderRight: "none",
  borderLeft: "none",
  borderTop: "none",
  borderBottom: "2px solid grey",
}

export const otpTextFocusStyle = {
  width: "40px",
  outline: 'none',
  outlineStyle: 'none',
  borderRight: "none",
  borderLeft: "none",
  borderTop: "none",
  borderBottom: "2px solid #009E82",
}

export const googleLoginButton = {
  boxShadow: "0 0 15.4px 0.6px rgb(0 0 0 / 9%)",
  border: "none",
  background: "white"
}

export const lightBlueShodow = {
  boxShadow: "0 0 15.4px 0.6px rgb(0 0 0 / 9%)",
  border: "none",
  background: "#6b7afd", 
  // boxShadow: "rgb(0 0 0 / 70%) 0px 0px 15.4px 0.6px"
}
export const fileUploadButton = {

}

export const noteBoxStyle = {
  padding: "20px",
  background: "#F4F6F5",
  borderRadius: "10px"
}

export const mainAppContainer = {
  marginTop: '64px'
}

export const recommendationContainer = {
  width: "40%"
}

export const recommendationMainContainer = {
  width: "5%"
}

export const quickActionContainer = {
  width: "38%",
  height: '75px'
}

export const quickActionSecondContainer = {
  width: "46%",
  height: '75px'
}

export const importButton = {
  width: '100px',
  height: '30px',
  margin: '10px',
  background: '#027AFF1A',
  borderRadius: '5px'
}

export const quickIcons = {
  margin: '10px', 
  lineHeight: '11px'
}

export const iconContainer = {
  width: "20%"
}

export const smarterSavingsButton = {
  height: '21px',
  border: `0.5px solid #EAEAEA`,
  borderRadius: '14px',
  padding: '1px',
  width: '121px',
  padding: '3px'
}

export const tabSelectionBox = {
  height: '30px',
  border: `0.5px solid #F9F9F9`,
  borderRadius: '10px',
  padding: '5px',
  marginBottom: '20px',
  background: '#F9F9F9'
}

export const dashboardTabs = {
  width: '30%',
  textAlign: 'center',
  height: '10px',
  borderRadius: '10px',
  paddingBottom: '14px',
  marginBottom: '20px',
}

export const summaryTab1 = {
  marginTop: '25px'
}

export const transactionTimelineBorder = {
  borderRadius: '5px', 
  border: '1px solid #EAEAEA', 
  background: '#EAEAEA', 
  width: '100%',
  marginLeft: '48px'
}

export const investFlowOption = {
  height: '90px',
  border: `0.5px solid #F9F9F9`,
  borderRadius: '10px',
  padding: '5px',
  boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
  width: '380px',
  margin: '100px'
}


export const sipInvestBox = {
  height: '319px',
  border: `0.5px solid #F9F9F9`,
  borderRadius: '10px',
  padding: '5px',
  boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
  width: '620px',
  margin: '60px'
}

export const sipInvestSummaryBox = {
  height: '162px',
  border: `0.5px solid #F9F9F9`,
  borderRadius: '10px',
  padding: '5px',
  boxShadow: '0px 0px 10px rgb(0 0 0 / 10%)',
  width: '300px',
  marginLeft: '56px',
  marginTop: '60px'
}

export const sipInvestButton = {
  marginLeft:'60px',
  marginTop: '20px', 
  width: '300px',
  height: '50px',
  padding: '15px 108px'
}

export const margin9 = {
  textAlign: 'left', 
  margin: '9px'
}

export const amountExample = {
  height: '16px',
  border: `0.5px solid #F9F9F9`,
  borderRadius: '14px',
  padding: '1px',
  width: '59px',
  background: '#E6F2FF',
  padding: '3px'
} 

export const cursorPointer = {
  cursor: 'pointer'
}

export const fundCategoryInfo = {
  height: '16px',
  border: `0.5px solid #E6F2FF`,
  borderRadius: '14px',
  padding: '1px',
  width: '59px',
  padding: '3px',
  marginLeft: '12px',
  marginTop: '20px'
} 

export const fundDetailsBlock = {
  textAlign: 'left',
  marginLeft: '60px',
  color: '#081320'
}

export const fundDetailsLine = {
  color: '#CCCCCC', 
  marginLeft: '60px', 
  borderRadius: '3px', 
  border: '0.5px solid #CCCCCC', 
  background: '#081320', 
  width: '300px'
}