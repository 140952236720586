import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Box, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import FullpageLoader from "../../components/shared/FullpageLoader";
import Error from "../../components/shared/Error";
import Button from "../../components/StyledComponents/Button";
import { StyledBox } from "../../components/StyledComponents/StyledBox";
import HeaderText from "../../components/shared/HeaderText";
import { fcJc, fonts } from "../../utilities/styles";
import Text from "../../components/shared/Text";
import SectionNote from "../../components/SectionNote";
import TooltipComponent from "../../components/TootipComponent";
import {
  useCustomQuery,
  useEsignInitiation,
  usePolling,
  useDigilockerInitiation,
} from "../../hooks/fetchQuery";
import { overlayStates } from "../../helper-methods/constants";
import {
  getPollingStatus,
  getOverlayComponent,
  getCustomQuery,
} from "../../helper-methods";
import { getPollingDetailsApi } from "../../http-calls/index";
import {
  fcAc,
  fcJsb,
  inheritHeight,
  inheritSize,
  loginContainer,
  googleLoginButton,
  inheritWidth,
  positionAbsolute,
  imageContainerSignature,
  digilockerNoteContainer
} from "../../utilities/styles";
import { PWA_URL } from "../../config/ApiConfig";
import { useQueryClient } from "react-query";
import esignSvg from '../../assets/esign.png'
import esignImage from '../../assets/esignImage.png'

const useStyles = makeStyles((theme) => ({
  img: {
    width: "170px",
    height: "170px",
    display: "block",
    margin: "auto",
  },
  ["@media (max-width:380px)"]: {
    width: "120px",
    height: "120px",
  },
}));
const Esign = ({ subSection, section }) => {
  const { data: customerId } = useCustomQuery({ section: "customerId" });
  // const customerId = 'CUSTf4472320-b4a1-4471-b667-a76ff992ab24'
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [overlayState, setOverlayState] = useState(null);
  const [overlayStateDigi, setOverlayStateDigi] = useState(null);
  const [redirectionUrl, _redirectionUrl] = useState(null);
  const {
    isLoading: isLoadingSectionData,
    isError: isErrorSectionData,
    error: errSectionsData,
    data: sectionData,
    isFetching: isFetchingSectionsData,
  } = useCustomQuery({
    section: section,
  });
  const queryClient = useQueryClient();
  const { data: customerStatus } = useCustomQuery({
    section: "customerStatus",
  });

  const _overlayStateSetter = (val) => {
    setOverlayState(val);
  };
  //  Initiate Esign
  const {
    mutate: esignInitiateMutate,
    data: esignRequestId,
    isLoading: isLoadingEsignInitiation,
    reset: esignRequestIdRemove,
    isPaused,
    status: esignReqIdstatus,
    isIdle,
  } = useEsignInitiation();

  useEffect(async () => {
    if (esignRequestId?.data?.data["requestId"]) {
      _overlayStateSetter(overlayStates.POLLING);
    }
  }, [esignRequestId]);

  const rejectionData = getCustomQuery({
    section: "rejectedRemarks",
    queryClient,
  })?.esign;

  const pollingQueryFn = async () => {
    if (
      isLoadingEsignInitiation ||
      !esignRequestId ||
      overlayState === overlayStates.DIGILOCKER_DATA_NOT_FOUND
    ) {
      return null;
    } else {
      return await getPollingStatus({
        overlayState,
        pollingAPI: getPollingDetailsApi,
        payload: {
          requestId: esignRequestId?.data?.data["requestId"],
          path: "EsignPolling",
        },
      });
    }
  };

  console.log(
    "Esign request id :",
    esignRequestId,
    isPaused,
    esignReqIdstatus,
    isIdle
  );
  const pollingQuery = usePolling({
    queryKey: `${subSection}Polling`,
    queryFn: pollingQueryFn,
    currentState: overlayState,
    pollingStopSideEffect: ({ status }) => {
      setOverlayState(status);
      esignRequestIdRemove();
    },
  });

  console.log("Polling esign data ::", pollingQuery);

  useEffect(() => {
    console.log({ queryData: pollingQuery?.data });
    if (
      pollingQuery?.data?.data?.data?.data?.data?.errorType?.toUpperCase() ===
      overlayStates.DIGILOCKER_DATA_NOT_FOUND
    ) {
      console.log("Hello Waiting screen");
      navigate(`/waitingScreen${search}`);
    } else if (
      pollingQuery.data &&
      pollingQuery?.data?.data?.data?.data?.message?.status === "SUCCESS"
    ) {
      const url =
        pollingQuery?.data?.data?.data?.data?.message?.data?.applicationURL;
      console.log("esign-redirection-url", url);
      pollingQuery?.remove();
      _redirectionUrl(url);
    }
  }, [pollingQuery.data]);

  useEffect(() => {
    if (redirectionUrl) {
      console.log("esign-redirectionUrl : ", redirectionUrl);
      window.open(redirectionUrl, "_PARENT");
    }
  }, [redirectionUrl]);

  const closeOverlay = () => {
    pollingQuery?.remove();
    setOverlayState(null);
  };

  console.log("use effect !11", pollingQuery?.data?.data?.data?.data?.message);

  const mockInitiateData = {
    customerId,
    redirectUrl: {
      baseUrl: PWA_URL,
      path: "EsignRedirection",
      queryParams: {
        customerid: customerId,
        platform: "flutter",
        source: "NIYO_SBM",
        token: "",
      },
    },
  };

  const _esignProceedHandler = async () => {
    await esignInitiateMutate(mockInitiateData);
  };

  //  initiate digilocker

  const mockInitiateDataDigi = {
    customerId,
    redirectUrl: {
      baseUrl: PWA_URL,
      path: "DigilockerRedirection",
      // queryParams: {
      //   customerid: customerId,
      //   platform: 'flutter',
      //   source: 'NIYO_SBM',
      //   token: '',
      // },
    },
  };

  const {
    mutate: digioInitiateMutate,
    data: digioRequestId,
    isLoading: isLoadingDigioInitiation,
  } = useDigilockerInitiation();

  useEffect(async () => {
    if (digioRequestId?.data?.data["requestId"]) {
      setOverlayStateDigi(overlayStates.POLLING);
    }
  }, [digioRequestId]);

  const pollingQueryFnDigi = async () => {
    if (isLoadingDigioInitiation) {
      return null;
    } else {
      if (!digioRequestId?.data?.data["requestId"]) return;
      return await getPollingStatus({
        overlayState,
        pollingAPI: getPollingDetailsApi,
        payload: {
          requestId: digioRequestId?.data?.data["requestId"],
          path: "DigilockerPolling",
        },
      });
    }
  };
  const pollingQueryDigi = usePolling({
    queryKey: `${subSection}Polling`,
    queryFn: pollingQueryFnDigi,
    currentState: overlayStateDigi,
    pollingStopSideEffect: ({ status }) => setOverlayStateDigi(status),
  });

  console.log({ pollingQueryDigi: pollingQueryDigi.data });

  useEffect(() => {
    if (!overlayStateDigi) {
      pollingQueryDigi.remove();
    }
  }, [overlayStateDigi]);

  useEffect(() => {
    console.log({ queryData: pollingQueryDigi?.data });
    if (pollingQueryDigi.data) {
      const url =
        pollingQueryDigi?.data?.data?.data?.data?.data?.applicationURL;
      console.log("digio-redirection-url", url);
      pollingQueryDigi?.remove();
      _redirectionUrl(url);
    }
  }, [pollingQueryDigi.data]);

  const theme = useTheme();

  if (
    isLoadingSectionData ||
    isFetchingSectionsData ||
    isLoadingEsignInitiation
  )
    return (
      <>
        <FullpageLoader />
      </>
    );
  if (isErrorSectionData) {
    return (
      <>
        <Error />
      </>
    );
  }

  const ctaHandler = async () => {
    // if (overlayState === overlayStates.DIGILOCKER_DATA_NOT_FOUND) {
    //   console.log('inside digilocker not found ');
    //   esignRequestIdRemove();
    //   setOverlayState(null);
    //   closeOverlay();
    // } else
    if (
      overlayState === overlayStates.PAN_NAME_MISMATCH_ERROR ||
      overlayState === overlayStates.PAN_NUMBER_MISMATCH_ERROR
      //  ||
      // overlayState === overlayStates.NAME_MISMATCH_ERROR ||
      // overlayState === overlayStates.DOB_MISMATCH_ERROR ||
      // overlayState === overlayStates.PAN_DOB_ERROR
    ) {
      await digioInitiateMutate(mockInitiateDataDigi);
      closeOverlay();
    } else if (
      overlayState === overlayStates.CUSTOMER_PHOTO_AADHAAR_MISMATCH ||
      overlayState === overlayStates.KYC_VIDEO_NOT_FOUND ||
      overlayState === overlayStates.FACE_MATCH_FAILURE
    ) {

      navigate(
        `${sectionData?.messages[subSection][overlayState]?.nextAction}${search}`
      );
    } else {
      closeOverlay();
    }
  };

  return (
    <>
      {overlayState &&
        getOverlayComponent({
          section,
          subSection,
          messages: sectionData?.messages,
          overlayState,
          overlayStateSetter: _overlayStateSetter,
          closeOverlay,
          ctaHandler: ctaHandler,
          // secondaryCtaHandler: secondaryCtaHandler,
        })}
      {overlayStateDigi &&
        getOverlayComponent({
          section,
          subSection: "Digilocker",
          messages: sectionData?.messages,
          overlayState: overlayStateDigi,
          overlayStateSetter: (val) => setOverlayStateDigi(val),
          ctaHandler: ctaHandler,
        })}
      {sectionData && (
        <div style={{ ...inheritSize, ...fcAc }}>
          <StyledBox style={{ ...inheritHeight, ...fcJsb, ...loginContainer, height: '100%' }}>
            <div>
              <HeaderText
                style={{
                  color: theme.palette.common.darkBlack,
                  margin: "10px 0 20px 0",
                  fontWeight: 900,
                }}
              >
                {sectionData?.tabs?.[subSection]?.displayName}
              </HeaderText>
              {rejectionData?.remarks && (
                <Box
                  style={{
                    background: theme.palette.error.orangeLight,
                    padding: "12px",
                    borderRadius: "2px",
                    margin: "12px 0 ",
                  }}
                >
                  <Text
                    style={{
                      color: theme.palette.error.orageLighter,
                      fontFamily: fonts.rubik.value,
                      fontSize: "10px",
                      textAlign: "left",
                    }}
                  >
                    {/* {LivelinessCheck.rejectedText} */}
                    Esign Rejected
                  </Text>
                  <Text
                    style={{
                      color: theme.palette.common.main,
                      fontFamily: fonts.rubik.value,
                      fontSize: "12px",
                      textAlign: "left",
                      lineHeight: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {rejectionData?.remarks}
                  </Text>
                </Box>
              )}
              <Box>
                <img 
                // src={esignSvg}
                src={esignImage}
                style={{ ...imageContainerSignature, height: '100%' }}
                 className={classes.img}
                 />
              </Box>

              
            </div>
 
            <Box>
            {sectionData?.tabs[subSection]?.note &&
                sectionData?.tabs[subSection]?.note.text && (
                  <Box>
                    <SectionNote>
                      <Text primary fontSize={12}>
                        {sectionData?.tabs[subSection]?.note?.text}
                      </Text>
                      <TooltipComponent
                        tooltipText={
                          sectionData?.tabs?.[subSection]?.note.helpText
                        }
                        displayText={
                          sectionData?.tabs?.[subSection]?.note.cta_text
                        }
                        display="differentLine"
                      ></TooltipComponent>
                    </SectionNote>
                  </Box>
                )}
              <Button onClick={_esignProceedHandler}
            style={{ ...googleLoginButton, width: inheritWidth.width, background: positionAbsolute.background }}
              >
                {sectionData?.tabs[subSection]?.cta_text}
              </Button>
            </Box>
          </StyledBox>
        </div>
      )}
    </>
  );
};

export default Esign;
