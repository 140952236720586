import React from 'react';
import SipDueIcon from '../../assets/icons/recom-sipdue.svg';
import Button from '../../components/StyledComponents/Button';
import { StyledBox } from '../StyledComponents/StyledBox';
import ImportMfIcon from '../../assets/icons/importMf.svg';
import TaxToolIcon from '../../assets/icons/recom-taxtool.svg';
import { Box } from '@material-ui/core';
import {
  frJsb,
  frAc,
  paddingLeft12,
  text14b,
  text14,
  fcAc,
  fcJse,
  recommendationContainer,
  importButton
} from '../../utilities/styles';
import LinkTextSM from '../shared/LinkTextSM';
import HeaderText  from '../shared/HeaderText';

const Recommendations = ({ type, onClickHandler, header, body }) => {
  console.log("Recommendation is called"+type);
  let content = {};
  let importMfIcon = <img src={ImportMfIcon} alt='' />
  switch (type) {
    case 'SIP payment':
      content.icon = <img src={SipDueIcon} alt='' />;
      break;
    case 'Tax tool':
      content.icon =  <img src={TaxToolIcon} alt='' />;
      break;
    default:
  }

  return (
    <>
    <StyledBox style = {{...recommendationContainer}}>
     <Box>
        <HeaderText  style={{...text14b, ...paddingLeft12}}>{header}</HeaderText>
      </Box>
      <Box style = {{...frAc}}>
        <LinkTextSM style={{ ...text14, fontFamily: `"Rubik", normal`, margin: '10px', textAlign: 'inherit', color: 'rgba(14, 18, 38, 0.6)' }}>{body}</LinkTextSM>
        <LinkTextSM  style={{...text14b, ...paddingLeft12}}>{content.icon}</LinkTextSM>
      </Box>
      <Box  style={{ ...fcJse, ...frAc }}>
        <Button style={{ ...importButton  }} icon= {importMfIcon}>
          Import
        </Button>
        <Button style={{ ...importButton  }}>
          Dismiss
        </Button>
      </Box>
    </StyledBox>
    </>
  );
};

export default Recommendations;