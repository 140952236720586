import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {
 Box, useTheme
} from '@material-ui/core';
import { StyledBox } from '../../../components/StyledComponents/StyledBox';
import TextInput from '../../../components/shared/Textinput';
import Text from '../../../components/shared/Text';
import reverseWave from '../../../assets/reverseWave.svg';
import wave from '../../../assets/wave.svg'
import {

    inheritHeight,
    inheritWidth,
    inheritSize,
    fcJc,
    loginContainer,
    fcAllCenter,
    googleLoginButton
} from '../../../utilities/styles/index';
import Button from '../../../components/StyledComponents/Button';
import Loader from '../../../components/Loader';
import GoogleLogin from "../../../components/GoogleLogin";
import { uuid,checkValidMobileNumber } from '../../../helper-methods'
import { GenerateMobileOTP} from '../../../hooks/fetchQuery'

const MobileOtpForm = ({ }) => {
    const location=useLocation()
    const navigate=useNavigate()
    const theme=useTheme()
    const { mutate: generateMobileOTP, isLoading:isLoadingMobileOtp,isFetching:isFetchingMobileOtp,
        data: GenerateMobileOTPData } = GenerateMobileOTP()    
    // GenerateMobileOTP
    const[phoneNumber,setPhoneNumber]=useState('')
    const [isLoginDisabled, setLoginDisabled]=useState(true)

    console.log("the location state ::",location.state)

    const onSubmitHandlerPhoneNumber=()=>{
        const data = {
            "requestId": uuid(),
            "phone": {
                "code":"+91",
                "number":phoneNumber 
            },
            "purpose": "PHONE_VERIFICATION"
          }
          generateMobileOTP(data)
    }

    const navigateOnLogin=()=>{
        navigate("/")
    }
    const valueUpdateHandlerMobile=(response)=>{
        console.log("mobile number",response)
        if(checkValidMobileNumber(response)){
            setLoginDisabled(false)
        }
        else{
            setLoginDisabled(true)  
        }
        setPhoneNumber(response)
    }

    useEffect(() => {

     if(GenerateMobileOTPData?.status === 200){
        if(location.state.route === "signUpGoogle"){
            navigate('/phone-otp', {state:{phoneNumber: phoneNumber, GenerateMobileOTPData: GenerateMobileOTPData,route:"signUpGoogle" }}) 
        }
        else navigate('/phone-otp', {state:{phoneNumber: phoneNumber, GenerateMobileOTPData: GenerateMobileOTPData,route:"signup" }})
        }
    })

    if (isLoadingMobileOtp ||isFetchingMobileOtp
        )
    return (
      <>
        <Loader />
      </>
    );
    return (
        <div style={{ ...inheritSize, ...fcAllCenter }}>

            <StyledBox style={{ ...inheritHeight, ...fcJc, ...loginContainer }}>
                <Box style={{
                    width: '560px',
                    marginLeft: '-40px',
                    borderRadius: '15px',
                    marginTop: '-40px',
                    position: 'relative',
                    borderRadius: '7px'
                }}>
                    <img src={reverseWave} alt = ""/>
                </Box>
                <img style={{
                    height: '109px', width: '34%',
                    position: 'relative',
                    marginLeft: '-35px',
                    marginTop: '-194px',
                }}
                    src='https://www.niyomoney.com/gw-web-assets/img/niyo-money/Niyo_Money_white.png' alt = "" />

                 <>
                    <Box className='mb-32' style={{marginTop:'20%', width: '97%'}}>

                     <Text
                        bold
                        fontSize={16}
                        style={{
                            // color: theme.palette.common.darkBlack,
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                        
                    >
                        Enter Mobile Number
                    </Text>   
                    <TextInput
                        required={true}
                        autoFocus
                        label={"Mobile Number"}
                        valueUpdateHandler={valueUpdateHandlerMobile}
                    />
                </Box>
                <Box className='mb-32'>
                    <Button
                        style={{...inheritWidth,...googleLoginButton}}
                        onClick={onSubmitHandlerPhoneNumber }
                        primary
                        disabled={isLoginDisabled}
                    >
                          Send Otp
                    </Button>
                    </Box>
                </>
                <Box style={{
                    width: '560px',
                    marginLeft: '-40px',
                    borderRadius: '15px',
                    position: 'relative',
                    marginBottom: '-44px'
                }}>
                    <img src={wave} style={{ borderRadius: '7px' }} alt = ""/>
                </Box>

            </StyledBox>

        </div>
    );
}

export default MobileOtpForm