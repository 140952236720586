import React, { useEffect, useState } from 'react';
import Error from '../../components/shared/Error';
import Button from '../../components/StyledComponents/Button';
import { StyledBox } from '../../components/StyledComponents/StyledBox';
import HeaderText from '../../components/shared/HeaderText';
import {
  fcJc,
  digilockerNoteContainer,
  inheritWidth,
  loginContainer,
  lightBlueShodow,
  imageContainerSignature
} from '../../utilities/styles';
import { useCustomQuery } from '../../hooks/fetchQuery';
import { Box, useTheme } from '@material-ui/core';
import {
  getPollingStatus,
  getOverlayComponent,
  getBackButtonPath,
  setBackButtonPath,
  getCustomQuery,
} from '../../helper-methods';
import { useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../../components/shared/Text';
import TooltipComponent from '../../components/TootipComponent';
import InfoIcon from '../../assets/infoIcon.svg';
import { getPollingDetailsApi } from '../../http-calls/index';
import FullpageLoader from '../../components/shared/FullpageLoader';
import { useDigilockerInitiation, usePolling } from '../../hooks/fetchQuery';
import {
  fcAc,
  fcJsb,
  inheritHeight,
  inheritSize,
  fonts,
} from '../../utilities/styles';
import DigilockerImage from '../../assets/DigilockerImage.png'
import { overlayStates } from '../../helper-methods/constants';
import { PWA_URL } from '../../config/ApiConfig';
import NoteComponent from '../../components/NoteComponent';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '170px',
    height: '170px',
    display: 'block',
    margin: 'auto',
  },
  ['@media (max-width:380px)']: {
    width: '120px',
    height: '120px',
  },
}));

const Digilocker = ({ subSection, section }) => {
  const { data: customerId } = useCustomQuery({ section: 'customerId' });
  const classes = useStyles();
  const theme = useTheme();
  const [overlayState, setOverlayState] = useState(null);
  const [redirectionUrl, _redirectionUrl] = useState(null);
  const {
    isLoading: isLoadingSectionData,
    isError: isErrorSectionData,
    error: errorSectionData,
    data: sectionData,
    isFetching: isFetchingsectionData,
  } = useCustomQuery({
    section: section,
  });
  const isLoading = isLoadingSectionData;
  const isError = isErrorSectionData;
  const isFetching = isFetchingsectionData;
  const queryClient = useQueryClient();
  const { data: customerStatus } = useCustomQuery({
    section: 'customerStatus',
  });

  const _overlayStateSetter = (val) => {
    setOverlayState(val);
  };
  useEffect(() => {
    const { redirectToHomeFrom } = getBackButtonPath({ queryClient });
    if (
      redirectToHomeFrom === subSection ||
      redirectToHomeFrom === 'photo' ||
      redirectToHomeFrom === 'poa' ||
      redirectToHomeFrom === 'poa_back'
    ) {
      setBackButtonPath({ data: '/home', queryClient });
    }
  }, []);
  //  initiate digilocker
  const {
    mutate: digioInitiateMutate,
    data: digioRequestId,
    isLoading: isLoadingDigioInitiation,
    reset: digioInitiateReset,
  } = useDigilockerInitiation();
  useEffect(async () => {
    if (digioRequestId?.data?.data['requestId']) {
      _overlayStateSetter(overlayStates.POLLING);
    }
  }, [digioRequestId]);
  const pollingQueryFn = async () => {
    if (isLoadingDigioInitiation) {
      return null;
    } else {
      if (
        !digioRequestId?.data?.data['requestId'] ||
        (pollingQuery?.data &&
          pollingQuery?.data?.data?.data?.data?.data?.applicationURL)
      )
        return;
      return await getPollingStatus({
        overlayState,
        pollingAPI: getPollingDetailsApi,
        payload: {
          requestId: digioRequestId?.data?.data['requestId'],
          path: 'DigilockerPolling',
        },
      });
    }
  };
  const pollingQuery = usePolling({
    queryKey: `${subSection}Polling`,
    queryFn: pollingQueryFn,
    currentState: overlayState,
    pollingStopSideEffect: ({ status }) => setOverlayState(status),
  });

  console.log("1111111111111111",{ pollingRes: pollingQuery.data });

  useEffect(() => {
    console.log({ queryData: pollingQuery?.data });
    if (pollingQuery.data) {
      const redirectionUrl =
        pollingQuery?.data?.data?.data?.data?.data?.applicationURL;
      console.log('digio-redirection-url', redirectionUrl);
      if (redirectionUrl) {
        digioInitiateReset();
        pollingQuery?.remove();
        window.open(redirectionUrl, '_PARENT');
      }
    }
  }, [pollingQuery.data]);

  const closeOverlay = () => {
    setOverlayState(null);
  };


  if (isLoading || isFetching || isLoadingDigioInitiation)
    return (
      <>
        <FullpageLoader />
      </>
    );
  if (isError) {
    return (
      <>
        <Error />
      </>
    );
  }

  const rejectionData = getCustomQuery({
    section: 'rejectedRemarks',
    queryClient,
  });
  const rejectionMessages = [];
  if (rejectionData?.poa?.remarks) {
    rejectionMessages.push({
      name: 'POA',
      reason: rejectionData?.poa?.remarks,
    });
  }
  if (rejectionData?.pan?.remarks) {
    rejectionMessages.push({
      name: 'PAN',
      reason: rejectionData?.pan?.remarks,
    });
  }
  if (rejectionData?.photo?.remarks) {
    rejectionMessages.push({
      name: 'PHOTO',
      reason: rejectionData?.photo?.remarks,
    });
  }
  const mockInitiateData = {
    customerId,
    redirectUrl: {
      baseUrl: PWA_URL,
      path: 'DigilockerRedirection',
      // queryParams: {
      //   customerid: customerId,
      //   platform: 'flutter',
      //   source: 'NIYO_SBM',
      //   token: '',
      // },
    },
  };
  const _digilockerProceedHandler = async () => {
    await digioInitiateMutate(mockInitiateData);
  };

  return (
    <>
      {overlayState &&
        getOverlayComponent({
          section,
          subSection,
          messages: sectionData?.messages,
          overlayState,
          overlayStateSetter: _overlayStateSetter,
          closeOverlay,
          ctaHandler: closeOverlay,
          // secondaryCtaHandler: secondaryCtaHandler,
        })}
      {sectionData && (
        <div 
        style={{ ...inheritSize, ...fcAc }}
        >
          <StyledBox style={{ ...inheritHeight, 
            ...fcJc,
             ...loginContainer,
             height:'auto' 
             }}>
            <div>
              <HeaderText
                style={{
                  color: theme.palette.common.darkBlack,
                  margin: '10px 0 20px 0',
                  fontWeight: 900,
                }}
              >
                {sectionData?.tabs?.[subSection]?.displayName}
              </HeaderText>
              {rejectionMessages.length > 0 && (
                <Box
                  style={{
                    background: theme.palette.error.orangeLight,
                    padding: '12px',
                    borderRadius: '2px',
                    margin: '12px 0 ',
                  }}
                >
                  <Text
                    style={{
                      color: theme.palette.error.orageLighter,
                      fontFamily: fonts.rubik.value,
                      fontSize: '10px',
                      textAlign: 'left',
                    }}
                  >
                    {/* {LivelinessCheck.rejectedText} */}
                    Kyc Documents Rejected
                  </Text>
                  <Text
                    style={{
                      color: theme.palette.common.main,
                      fontFamily: fonts.rubik.value,
                      fontSize: '10px',
                      textAlign: 'left',
                      lineHeight: '14px',
                      fontWeight: '400',
                    }}
                  >
                    {rejectionMessages.map((item) => (
                      <div
                        style={{
                          ...inheritWidth,
                          margin: '2px 0',
                          padding: '0 8px',
                          wordWrap: 'break-word',
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            marginRight: '4px',
                          }}
                        >
                          <h5 style={{ textAlign: 'left' }}>
                            {item.name} Rejected :
                          </h5>
                          <p style={{ textAlign: 'left' }}>{item.reason}</p>
                        </div>
                        <br />
                      </div>
                    ))}
                  </Text>
                </Box>
              )}
              <div style={{ ...fcJc }} className='mv-24'>
                <img
                src={DigilockerImage}
                style={{ ...imageContainerSignature }}
                  // src={sectionData?.tabs[subSection]?.niyoxPlaceholderImage}
                  className={classes.img}
                />
              </div>
              <Box style={{ ...digilockerNoteContainer }}>
                <div style={{ display: 'flex' }}>
                  <div className='mb-24'>
                    <span className='text-14'>
                      {sectionData?.tabs?.[subSection]?.description.title}
                    </span>
                  </div>

                  <div>
                    <TooltipComponent
                      tooltipText={
                        sectionData?.tabs?.[subSection]?.description.helpText
                      }
                      imgSrc={InfoIcon}
                    ></TooltipComponent>
                  </div>
                </div>
                <div className='fc-ac'>
                  {sectionData?.tabs[subSection]?.description.items &&
                    sectionData?.tabs[subSection]?.description.length !== 0 &&
                    sectionData.tabs[subSection].description.items.map(
                      (descriptionItem, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              ...digilockerNoteContainer.descriptionItem,
                            }}
                          >
                            <div
                              style={{
                                ...digilockerNoteContainer.descriptionItemNumber,
                              }}
                            >
                              {index + 1}
                            </div>
                            <div className='text-12'>{descriptionItem}</div>
                          </div>
                        );
                      }
                    )}
                </div>
                <div className='mb-24'>
                  <span className='text-14'>
                      {sectionData?.tabs?.[subSection]?.description.subTitle}
                    </span>
                  </div>
              </Box>
              {sectionData?.tabs[subSection]?.note &&
                sectionData?.tabs[subSection]?.note.text && (
                  <NoteComponent
                  style={{margin:"20px 0 20px 0"}}
                  >
                    <div className='pv-24'>
                      <Text primary fontSize='14px'>
                        {sectionData?.tabs[subSection]?.note.text}
                        <TooltipComponent
                          tooltipText={
                            sectionData?.tabs?.[subSection]?.note.helpText
                          }
                          displayText={
                            sectionData?.tabs?.[subSection]?.note.cta_text
                          }
                        ></TooltipComponent>
                      </Text>
                    </div>
                  </NoteComponent>
                )}
            </div>
            <Box>
              <Button
               style={{...inheritWidth, ...lightBlueShodow}}
                onClick={_digilockerProceedHandler}
              >
                {sectionData?.tabs[subSection]?.cta_text}
              </Button>
            </Box>
          </StyledBox>
        </div>
      )}
    </>
  );
};

export default Digilocker;
