// import { useTheme } from '@material-ui/styles';
import { useTheme } from "@material-ui/core";
import React from 'react';
import CustomButton from '../StyledComponents/Button';

const OverlayChildrenComponent = ({
  messages,
  subSection,
  overlayState,
  ctaHandler,
  secondaryCtaHandler,
  children,
}) => {
  const message =
    messages?.[subSection]?.[overlayState] || messages?.[overlayState];
  const theme = useTheme();
  return (
    <>
      {children}
      {message?.secondaryCta && (
        <CustomButton
          onClick={secondaryCtaHandler}
          style={{ marginTop: '15px', background: 'none' }}
          textStyle={{
            color: theme.palette.primary.main,
          }}
        >
          {message?.secondaryCta}
        </CustomButton>
      )}
      {message?.ctaText && (
        <CustomButton onClick={ctaHandler} style={{ marginTop: '15px' }}>
          {message?.ctaText}
        </CustomButton>
      )}
    </>
  );
};

export default OverlayChildrenComponent;
