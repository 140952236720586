import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {
 Box, useTheme
} from '@material-ui/core';
import { StyledBox } from '../../../components/StyledComponents/StyledBox';
import TextInput from '../../../components/shared/Textinput';
import Text from '../../../components/shared/Text';
import reverseWave from '../../../assets/reverseWave.svg';
import wave from '../../../assets/wave.svg'
import {

    inheritHeight,
    inheritWidth,
    inheritSize,
    fcJc,
    loginContainer,
    fcAllCenter,
    googleLoginButton
} from '../../../utilities/styles/index';
import Button from '../../../components/StyledComponents/Button';
import Loader from '../../../components/Loader';
import GoogleLogin from "../../../components/GoogleLogin";
import { uuid,checkValidEmail } from '../../../helper-methods'
import { GenerateEmailOTP ,VerifyGoogleLogin,Login} from '../../../hooks/fetchQuery'

const SignUpForm = ({ }) => {
    const location=useLocation()
    const navigate=useNavigate()
    const theme=useTheme()
    const { mutate: generateOTP, isLoading:isLoadingEmailOtp, isFetching:isFetchingEmailOtp,
         data: GenerateEmailOTPData } = GenerateEmailOTP()
    const { mutate: verifyLoginGmail, isLoading:isLoadingGLogin, isFetching:isFetchingGlogin,data: VerifyGoogleLoginData } = VerifyGoogleLogin()
    const { mutate: login,isLoading:isLoadingLogin,isFetching:isFetchingLogin, data } = Login()
    // GenerateMobileOTP
    const [emailId, setEmailID] = useState('')
    const [isLoginDisabled, setLoginDisabled]=useState(true)

    const googleLoginHeaders=VerifyGoogleLoginData?.headers

    console.log("The headers :", googleLoginHeaders)

    console.log("the location state ::",location.state)
    const onSubmitHandler = () => {
        const data = {
            "requestId": uuid(),
            "email": {
                "email": emailId
            },
            "purpose": "EMAIL_VERIFICATION"
          }
          generateOTP(data)        
    }
    const responseGoogle = (response) => {
        console.log(response);
    }
    const navigateOnLogin=()=>{
        navigate("/")
    }
    const valueUpdateHandler = (response) => {
        console.log("The response ==",response)
        if(checkValidEmail(response)){
            setLoginDisabled(false)
        }
        else{
            setLoginDisabled(true)
        }

        setEmailID(response)
    };

    useEffect(() => {
        if( GenerateEmailOTPData?.status === 200){
        navigate('/otp', {state:{emailId: emailId, GenerateEmailOTPData: GenerateEmailOTPData,route:"signup" }})
        }
    })

    const onSuccessFromGoogle = (response) => {
        console.log('onSuccess112233', response)
        if(response && response?.accessToken){
            const data={
                type : "Google",
                userId:response?.profileObj?.email,
                token:response?.tokenId

            }
            verifyLoginGmail(data)
        }
    }
    const onFailureFromGoogle=(response)=>{
        console.log("Failure from google !!")
    }
    useEffect(() => {
        if( VerifyGoogleLoginData?.status === 200){

            if(googleLoginHeaders["x-phone-verify"]==="false"){
                navigate("/mobile-signup", { state: {route:"signUpGoogle"}})
            }
            else if(googleLoginHeaders["x-phone-verify"] === "true" &&
             googleLoginHeaders["x-email-verify"] === "true" ){

                const data = {
                    'x-device-token': googleLoginHeaders["x-device-token"]
            }
            login(data)
            }
        }
    },[VerifyGoogleLoginData])

    if (isLoadingEmailOtp  || isFetchingEmailOtp 
        ||isLoadingGLogin||isFetchingGlogin ||
        isLoadingLogin|| isFetchingLogin
        )
    return (
      <>
        <Loader />
      </>
    );
    return (
        <div style={{ ...inheritSize, ...fcAllCenter }}>

            <StyledBox style={{ ...inheritHeight, ...fcJc, ...loginContainer }}>
                <Box style={{
                    width: '560px',
                    marginLeft: '-40px',
                    borderRadius: '15px',
                    marginTop: '-40px',
                    position: 'relative',
                    borderRadius: '7px'
                }}>
                    <img src={reverseWave} alt = ""/>
                </Box>
                <img style={{
                    height: '109px', width: '34%',
                    position: 'relative',
                    marginLeft: '-35px',
                    marginTop: '-194px',
                }}
                    src='https://www.niyomoney.com/gw-web-assets/img/niyo-money/Niyo_Money_white.png' alt = "" />

                <>
                <Box>
                    <Text
                        centered
                        bold
                        fontSize={20}
                        style={{
                            // color: theme.palette.common.darkBlack,
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                    >
                        Lets sign you up !
                    </Text>
                    <Text
                        centered
                        fontSize={16}
                        style={{
                            // color: theme.palette.common.darkBlack,
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                    >
                        Or
                    </Text>
                    <Box style={{ ...inheritWidth }} className="mb-32" >
                        <GoogleLogin
                            onSuccess={onSuccessFromGoogle}
                            onFailure={onFailureFromGoogle}
                        />
                    </Box>
                </Box>
                <Box className='mb-32' style={{width: '97%'}}>
                    <TextInput
                        required={true}
                        autoFocus
                        label={"Email Id"}
                        valueUpdateHandler={valueUpdateHandler}
                    />
                </Box>
                <Box className='mb-32'>
                    <Button
                        style={{...inheritWidth,...googleLoginButton}}
                        onClick={ onSubmitHandler}
                        primary
                        disabled={isLoginDisabled}
                    >
                         Sign Up
                    </Button>

                </Box>
                <Box>
                    <Text
                        centered
                        fontSize={16}
                        style={{
                            // color: theme.palette.common.darkBlack,
                            margin: `20px 0px`,
                            bottom: '10%',
                            left: '15%',
                            zIndex: 2,
                        }}
                    >
                        Already signed up ? <span 
                        style={{color:theme.palette.primary.main}}
                        onClick={navigateOnLogin}
                        >Login</span>
                    </Text>
                </Box>
                </>

                <Box style={{
                    width: '560px',
                    marginLeft: '-40px',
                    borderRadius: '15px',
                    position: 'relative',
                    marginBottom: '-44px'
                }}>
                    <img src={wave} style={{ borderRadius: '7px' }} alt = ""/>
                </Box>

            </StyledBox>

        </div>
    );
}

export default SignUpForm