import { TextField, withStyles } from '@material-ui/core';
import {
  fonts, fc, frAllCenter, inputBorder, br8,
  positionRelative, paddingLeft20, paddingLeft12, paddingLeft0,
  text10, text14, height60, displayNone
} from '../../utilities/styles/index';

export const StyledTextField = withStyles((theme) => ({
  root: {
    display: (props) => (props.required ? fc.display : displayNone.display),
    alignItems: frAllCenter.alignItems,
    border: inputBorder.border,
    boxShadow: inputBorder.boxShadow,
    borderRadius: br8.borderRadius,
    background: positionRelative.background,
    paddingLeft: (props) => (props.fullWidth ? paddingLeft12.paddingLeft : paddingLeft0.paddingLeft),
    height: height60.height,
    "& label": {
      paddingLeft: (props) => (props.fullWidth ? paddingLeft20.paddingLeft : paddingLeft0.paddingLeft),
    },
    '& .MuiInputLabel-root': {
      fontSize: text14.fontSize,
      fontFamily: fonts.lexend.value,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
    },
    '& .MuiInputLabel-shrink': {
      fontSize: text10.fontSize,
      fontWeight: text10.fontWeight,
      marginTop: theme.spacing(1),
      color: theme.palette.secondary.darker,
      fontFamily: fonts.rubik.value,
    },
    '& .MuiInput-input': {
      fontSize: text14.fontSize,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      fontFamily: fonts.lexend.value,
      color: (props) =>
        props.inputKey === 'dob' && props.error && theme.palette.error.main,
    },
    '& .MuiInput-underline:hover::before': {
      display: displayNone.display
    },
    '& .MuiInput-underline::before': {
      display: displayNone.display
    },
    '& .MuiInput-underline::after': {
      display: displayNone.display
    },
  },
}))(TextField);
