import React, { useState } from "react";
import Modal from '@material-ui/core/Modal';
import { Container } from "@material-ui/core";
import "./ModalPopup.css";

const useStyles = (props) => ({
  root: {
    height: props.height ? props.height : "100vh",
    width: "100vw",
    padding: "7% 6.7%",
    borderRadius: "10px",
  },
});

const ModalPopup = (props) => {
  const { show, toggle } = props;
  const classes = useStyles(props);
  let [startPos, updateStartPos] = useState(null);
  return (
    <Modal
      appendCancelBtn={false}
      open={true}
    >
      <Container
        onClick={toggle}
        // style={classes.root}
        style={{
          background:"white",
          padding:"40px",
          maxWidth:"700px",
          position:"absolute",
          top:"50%",
          left:"50%",
          marginRight:"-50%",
          transform: "translate(-50%, -50%)",
          borderRadius:"15px",
          maxHeight:"300px",
          height:"100%"
        }}
        onMouseDown={(event) => {
          if (props.closeOnPull) {
            if (event.stopPropagation) event.stopPropagation();
            if (event.preventDefault) event.preventDefault();
            event.cancelBubble = true;
            event.returnValue = false;
            // startPos = e.pageY;
            updateStartPos(event.pageY);
          }
        }}
        onMouseMove={(event) => {
          if (props.closeOnPull) {
            if (event.stopPropagation) event.stopPropagation();
            if (event.preventDefault) event.preventDefault();
            event.cancelBubble = true;
            event.returnValue = false;
            if (startPos && startPos + 500 >= event.pageY) {
              if (props.onClose) {
                props.onClose();
              } else {
                window.history.back();
              }
              updateStartPos(null);
            }
          }
        }}
        onMouseUp={(event) => {
          updateStartPos(null);
          if (event.stopPropagation) event.stopPropagation();
          if (event.preventDefault) event.preventDefault();
          event.cancelBubble = true;
          event.returnValue = false;
        }}
      >
        {props.children}
      </Container>
    </Modal>
  );
};
export default ModalPopup;
