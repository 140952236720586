import React from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

import { fr, inheritSize } from "../utilities/styles/index";
import MainAppContainer from './StyledComponents/MainAppContainer'
import WrapperContainer from './StyledComponents/WrapperContainer'
// import AppBarTop from './AppBarTop'

const Wrapper = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <WrapperContainer
      style={{
        ...inheritSize,
        ...fr,
      }}
      matches={matches}
    >
      {/* <AppBarTop /> */}
      <MainAppContainer
        open={true}
        matches={matches}
        style={{ width: "100%", ...fr }}
      >
        {children}
      </MainAppContainer>
    </WrapperContainer>
  );
};

export default Wrapper;
