import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import {
    fcAc,
    fcJsb,
    fonts,
    inheritHeight,
    inheritSize,
    inheritWidth,
    noteBoxStyle
  } from '../../utilities/styles/index';

const NoteComponent=(props)=>{
    return(
        <Box style={{ ...noteBoxStyle, ...props.style}} className="className">
            
            {props.textValue}

            {props.children?
            props.children:null
        }
        </Box>
    ) 
} 

export default NoteComponent;