import { Box, withStyles } from '@material-ui/core';

export const StyledBox = withStyles((theme) => ({
    root: {
      borderRadius: `${theme.spacing(1)}px`,
      padding: `${theme.spacing(0)}px`,
      maxWidth: `${theme.spacing(60)}px`,
      margin: `${theme.spacing(1.2)}px`,
      width: `85%`,
      ['@media (max-width:780px)']: {
        width:' 75%',
        // overflow: 'hidden',
        height:'100%'
      },
    },
  }))(Box);