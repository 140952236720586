import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    inheritSize,
    fcAllCenter,
} from '../../utilities/styles/index';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
      margin:"auto"
    },
  },
}));

export default function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ ...inheritSize, ...fcAllCenter }}>
      <CircularProgress
      style={{margin:"auto"}}
      
      />
    </div>
  );
}