import React,{useEffect} from 'react';
import GoogleLogin from 'react-google-login';

// import { GOOGLE_CLIENT_ID } from '../../config/key-config';
import { gapi } from "gapi-script";
import LoginButton from '../shared/LoginButton';
const LoginWithGoogle = ({ onSuccess, onFailure }) => {
  const GOOGLE_CLIENT_ID = "887012426450-e49juc8bnu95kf6ocjeh3hv7c8sqnnf0.apps.googleusercontent.com";

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);
  return (
    <>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        render={(renderProps) => (
          <LoginButton onClick={renderProps.onClick} type='google'/>
        )}
      />
    </>
  );
};

export default LoginWithGoogle;
