import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box, useTheme
} from '@material-ui/core';
import { fundInfoDetailsJson } from '../../mock-data/fundInfoJson';
import LinkTextSM from '../../components/shared/LinkTextSM';
import {
    frJsb,
    frJc,
    frJs,
    fonts,
    text14b,
    text14,
    fcJs,
    sipInvestBox,
    sipInvestSummaryBox,
    text16ln20,
    text24,
    text14fw500,
    frAs,
    sipInvestButton,
    margin9,
    amountExample,
    cursorPointer
} from '../../utilities/styles';
import { getFundInfoDetails } from '../../http-calls';
import Button from '../../components/StyledComponents/Button';
import StartSIPIcon from '../../assets/icons/sipStartIcon.svg'; 
import NipponIcon from '../../assets/icons/Nippon_Life_Insurance.svg'; 
import DeleteIcon from '../../assets/icons/deleteIcon.svg'; 
import TextInput from '../../components/shared/Textinput';
import { overlayStates } from '../../helper-methods/constants';
import { getPaymentRefId, getPaymentDetails, postSuccessPayment } from '../../http-calls';
import useRazorpay from "react-razorpay";

const SipInvest = () => {
    const Razorpay = useRazorpay();
    const { fundInfoDetails } = fundInfoDetailsJson;
    let navigate = useNavigate();
    let paymentRefID = null;
    const [amount, setAmount] = useState(0);
    const [sipDate, setSipDate] = useState(0);
    const [overlayState, setOverlayState] = useState(null);
    const [amountError, setAmountError] = useState(false);
    let onClickHandler = (event) => {
        if(event === 'sip'){
            navigate('/sip-invest');
        }else{
            navigate('/lumpsum-invest');
        }
    }

    const dobOverlayTrigger = () => {
        console.log('overlayyyyyy');
        setOverlayState(overlayStates.DOB_SPINNER);
      };

    let amountUpdateHandler = (amount) => {
        console.log("Amount Entered is"+amount)
        if(amount >= 1000){
            setAmount(amount);
        }else{
            setAmountError(true);
        }

    }

    let dateUpdateHandler = (sipDate) => {
        console.log("Date Entered is"+sipDate)
        setSipDate(sipDate);
    }
    let startSipIcon = <img src={StartSIPIcon} alt='' />
    let nipponIcon = <img src={NipponIcon} alt='' />
    let deleteIcon = <img src={DeleteIcon} alt='' />

    const onContinueHanlder = async() => {
        console.log('inside on continue click handler');
        let payload = {
            "amounts": amount,
            "funds": [
              {
                "mfId": "36",
                "amount": amount,
                "isNewFolio": true,
                "nomineeId": "097677eb-17a7-45b8-a9ad-5e6446ae00e7",
                "units": null,
                "redemptionType": null
              }
            ],
            "bankId": "Account_58493924-78bc-43fa-a269-23b5ac6e07d6",
            "consentId": "5467",
            "portfolioId": "",
            "trxnType": "PURCHASE",
            "paymentMode": "NETBANKING",
            "paymentType": "PAYMENT_CHEQUE",
            "notes": {
              "test": "test"
            },
            "successRedirectUrl": "web.com",
            "failureRedirectUrl": "Web",
            "ipAddress": "444",
            "investmentType": "ONETIME"
          }
        const paymentRefIdResponse = await getPaymentRefId(payload);
        console.log('payment ref id'+JSON.stringify(paymentRefIdResponse));
        let status = null
        let responseData = null
        if(paymentRefIdResponse?.status === 202 && paymentRefIdResponse?.data?.paymentRefId){
              paymentRefID = paymentRefIdResponse?.data?.paymentRefId;
            let payload = {
                paymentRefId: paymentRefID
            }
            while(status !== 200 && !responseData?.data?.paymentAccessKey){
                const paymentDetailsResponse = await getPaymentDetails(payload);
                status = paymentDetailsResponse?.status;
                responseData = paymentDetailsResponse;
                if(status == 200 || responseData?.data?.paymentAccessKey){
                    // [{"tenantId":"GLOBAL_ONEAPP","paymentRefId":"payment_ccWlC58DbcofGZfOi","createdAt":"2023-02-06T13:22:45.092Z","updatedAt":"2023-02-06T13:22:45.092Z","createdBy":"GLOBAL_ONEAPP","updatedBy":"PaymentManager","razorpayOrderId":"order_LDApEMfe9oLUPI","razorpayAccessKey":"rzp_test_xAgwDjeWhe9VdL","mode":"netbanking","amount":"2000.0","amountPaid":"0","amountDue":"2000.0","currency":"INR","status":"order_created","bankDetails":{"accountNumber":"603372627181","ifsc":"ICIC0006033","name":"ICICI Bank"},"attempts":0,"notes":{"test":"test"},"orderCreatedAtRazorpay":1675689764,"successRedirectUrl":"web.com","failureRedirectUrl":"Web","paymentItems":[{"payItemRefId":"payitem_95YKxfdzydN2lXvFHzgO5c","razorpayTransferId":"trf_LDApEQ0hKsSPtw","status":"transfer_created","source":"order_LDApEMfe9oLUPI","recipient":"acc_Jk572ehO2BoWe0","currency":"INR","amount":"2000.00","amountReversed":"0.00","notes":{"mfId":"36","trxnId":"trxn_1675689784341sGj6bTMaTW"},"onHold":false,"transferCreatedAtRazorpay":1675689765,"productDetails":{"type":"mutual_fund","plan":"Regular","option":"Growth","scheme":"1191","receipt":"36","mfMemberId":"ARN-103141","mfUserId":"user_id99758865-fe7f-4f9a-b72d-f6f6195606a3","mfPartner":"cams","mfInvestmentType":"L","mfAmcCode":"P"},"error":{"id":"trf_LDApEQ0hKsSPtw"}}],"amountRefunded":"0","captured":false,"acquirerData":{},"error":{}}]
                  //return responseData.data
                  break;
                  }
            }
            console.log("checking returned data"+JSON.stringify(responseData));
            if(status == 200 || responseData?.data?.paymentAccessKey){
                const options = {
                    key: responseData?.data?.paymentAccessKey,
                    amount: amount,
                    currency: "INR",
                    name: "Acme Corp",
                    description: "Test Transaction",
                    image: "https://www.niyomoney.com/gw-web-assets/img/niyo-money/Niyo_Money_white.png",
                    order_id: responseData?.data?.paymentOrderId,
                    handler: (res) => successPayment(res),
                    prefill: {
                      name: "Piyush Garg",
                      email: "youremail@example.com",
                      contact: "9999999999",
                    },
                    notes: {
                      address: "Razorpay Corporate Office",
                    },
                    theme: {
                      color: "#3399cc",
                    },
                  };
                
                //open razorpay
                
                const rzpay = new Razorpay(options);
                rzpay.on("payment.failed", function (response) {
                    console.log('payment failed',JSON.stringify(response))
                });
                rzpay.open();
            }
        }
    }

    const successPayment = async(res) => {
        console.log('razorpay res',res);
        let payload = {
            "paymentRefId": paymentRefID,
            "paymentOrderId": res.razorpay_order_id,
            "paymentSignature": res.razorpay_signature
        }
        const response = await postSuccessPayment(payload);
        return response;
      }

    useEffect(async () => {
        const response = await getFundInfoDetails({ "mfId": 8643 })
    }, []);

    return (
        <div>
            <Box style={{ ...frJc }}>
                <Box style={{ width: '50%' }}>
                    <Box style={{...fcJs,...sipInvestBox, border: `2.5px solid #F9F9F9`}}>

                        <Box style={{...frJsb}}>
                            <Box style={{...frAs}}>
                                    <LinkTextSM style={{...text14b, color: '#081320', margin:'15px', textAlign: 'left'}}>{nipponIcon}</LinkTextSM>   
                                    <LinkTextSM style={{...text14b, color: '#081320', marginTop: '20px'}} fontFamily={fonts.rubikNormal.value}>Nippon India Liquid Fund Direct Plan Growth Plan</LinkTextSM>
                            </Box>
                        <LinkTextSM style={{marginTop: '20px', marginRight: '10px'}}>{deleteIcon}</LinkTextSM>
                        </Box>
                        <Box style={{...frJs}}>
                            <LinkTextSM style={{...text14, color: '#9CA1A6', marginLeft:'17px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}>Folio</LinkTextSM>   
                            <LinkTextSM style={{...text14, color: '#006EED', marginLeft:'5px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}>878666561</LinkTextSM>   
                        </Box>
                        <Box style={{...frJs}}>
                            <LinkTextSM style={{...text16ln20, color: '#081320', marginLeft:'17px', marginTop:'50px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}>SIP Date</LinkTextSM> 
                            <Box style={{marginTop:'25px', marginLeft: '95px'}}>
                                <TextInput
                                required={true}
                                autoFocus
                                fullWidth ={false}
                                label={"Enter DD-MM-YY"}
                                dobOverlayTrigger={dobOverlayTrigger}
                                type='date'
                                valueUpdateHandler={dateUpdateHandler}
                                />
                            </Box>  
                        </Box>
                        <Box style={{...frJs}}>
                            <LinkTextSM style={{...text16ln20, color: '#081320', marginLeft:'17px', marginTop:'50px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}>Enter Amount</LinkTextSM>
                            <Box style={{marginTop:'25px', marginLeft: '60px'}}>
                                <TextInput
                                required={true}
                                autoFocus
                                fullWidth ={false}
                                label={"Enter Amount"}
                                value= {amount === 0 ? "" : amount}
                                valueUpdateHandler={amountUpdateHandler}
                                />
                            </Box> 
                            <LinkTextSM style={{...text14, color: '#9CA1A6', marginLeft:'25px', marginTop:'50px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}>Min SIP: ₹ 1,000 </LinkTextSM>
                        </Box>
                        <Box style={{...frJs, marginLeft: '150px'}}>
                            <LinkTextSM onClickHandler={() => amountUpdateHandler(500)} style={{...text14, ...amountExample, color: '#9CA1A6', marginLeft:'25px', ...cursorPointer}} fontFamily={fonts.rubikNormal.value}>+ ₹500</LinkTextSM>
                            <LinkTextSM onClickHandler={() => amountUpdateHandler(1000)} style={{...text14, ...amountExample, color: '#9CA1A6', marginLeft:'25px', ...cursorPointer}} fontFamily={fonts.rubikNormal.value}>+ ₹1000</LinkTextSM>
                            <LinkTextSM onClickHandler={() => amountUpdateHandler(5000)} style={{...text14, ...amountExample, color: '#9CA1A6', marginLeft:'25px', ...cursorPointer}} fontFamily={fonts.rubikNormal.value}>+ ₹5000</LinkTextSM>
                        </Box>

                    </Box>
                </Box>
                <Box style={{ width: '50%' }}>
                    <Box style={{...fcJs}}>
                        <Box style={{...fcJs,...sipInvestSummaryBox, border: `2.5px solid #F9F9F9`}}>
                            <LinkTextSM style={{...text16ln20, color: '#6B7179', textAlign: 'left', margin: '15px'}} fontFamily={fonts.lexend.value}>Total SIP Investments</LinkTextSM>
                            <Box style={{...frAs, margin: '10px'}}>
                                <LinkTextSM style={{...text14fw500, color: '#081320', ...margin9}} fontFamily={fonts.rubikNormal.value}>₹</LinkTextSM>
                                <LinkTextSM style={{...text24, color: '#081320', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}>{amount}</LinkTextSM>
                                <LinkTextSM style={{...text14fw500, color: '#081320', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}>00</LinkTextSM>
                            </Box>
                            <Box style={{...frAs, margin: '10px'}}>
                                <LinkTextSM style={{...text14fw500, color: '#6B7179', ...margin9}} fontFamily={fonts.rubikNormal.value}>No. of funds:</LinkTextSM>
                                <LinkTextSM style={{...text14fw500, color: '#081320', ...margin9}} fontFamily={fonts.rubikNormal.value}>1</LinkTextSM>
                            </Box>
                            
                        </Box>
                        <Button style={{...sipInvestButton}} onClick={() => {onContinueHanlder()}}>Continue</Button>
                        <Button style={{...sipInvestButton}}>Add more</Button>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default SipInvest;