import React, { useState } from 'react';

import DatePicker from 'react-mobile-datepicker';

import HeaderText from './shared/HeaderText';
import Text from './shared/Text';
import {
  tac,
  inheritHeight,
  inheritSize,
  fcAc,
  fcJsb,
  fonts,
  frAc,
} from '../utilities/styles';
import { StyledBox } from './StyledComponents/StyledBox';
import CustomButton from './StyledComponents/Button';
import { getCustomQuery, getYYYYMMDD, setCustomQuery } from '../helper-methods';
import { useQueryClient } from 'react-query';
import { dateSpinnerKey } from '../helper-methods/constants';
import {  Box } from "@material-ui/core";

const SpinnerDateComponent = ({
  section,
  subSection,
  customHeader,
  confirmText,
  cancelText,
  cancelHandler,
  getDOB
}) => {
  const queryClient = useQueryClient();
  const data = getCustomQuery({ section, queryClient });
  const [date, _setDate] = useState(new Date());
  const handleSelect = (date) => {
    _setDate(date);
    const value = getYYYYMMDD(date);
    getDOB(value)
    // setCustomQuery({
    //   section: section,
    //   data: {
    //     ...data,
    //     inputFields: {
    //       ...data.inputFields,
    //       [subSection]: {
    //         ...data.inputFields[subSection],
    //         [dateSpinnerKey[subSection]]: {
    //           ...data.inputFields[subSection][dateSpinnerKey[subSection]],
    //           value: value,
    //         },
    //       },
    //     },
    //   },
    //   queryClient: queryClient,
    // });
    cancelHandler();
  };
  const monthMap = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const dateConfig = {
    date: {
      format: 'DD',
      caption: 'Day',
      step: 1,
    },

    month: {
      format: (value) => (
        <Text
          fontFamily={fonts.lexend.value}
          style={{ textAlign: tac.textAlign }}
        >
          {monthMap[value.getMonth() + 1]}
        </Text>
      ),
      caption: 'Mon',
      step: 1,
    },
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
  };

  return (
    <div
      style={{
        height: 'fit-content',
        ...fcAc,
      }}
    >
      <StyledBox style={{ height: `145px`,
       ...fcJsb,
        width: '400px',
         position: 'relative',
     }}
     sx ={{
      ['@media (max-width:780px)']: {
       width : '100% !important',
       overflow: 'visible !important'
     }}}
     >
        <DatePicker
          dateConfig={dateConfig}
          value={date}
          isOpen={true}
          onSelect={handleSelect}
          onCancel={cancelHandler}
          min={new Date('01-01-1900')}
          max={new Date()}
          customHeader={
            <HeaderText
              style={{
                textAlign: tac.textAlign,
                fontFamily: fonts.galano.value,
                paddingTop: '15px'
              }}
            >
              {customHeader}
            </HeaderText>
          }
          isPopup={false}
          confirmText={<CustomButton style={{ width: '150px'}}>{confirmText}</CustomButton>}
          cancelText={
            <CustomButton
              style={{
                background: 'white',
                border: '1px solid #EEEEEE',
                marginRight: '25px',
                width: '150px'
              }}
            >
              <span style={{ color: '#273998' }}> {cancelText} </span>
            </CustomButton>
          }
        />
      </StyledBox>
    </div>
  );
};

export default SpinnerDateComponent;
