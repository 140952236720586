import React from 'react';
import ImportMfIcon from '../../assets/icons/importMf.svg';
import InvestIcon from '../../assets/icons/invest.svg';
import RedeemIcon from '../../assets/icons/redeem.svg';
import AutoPayIcon from '../../assets/icons/autopay.svg';
import RebalanceIcon from '../../assets/icons/rebalance.svg';
import PaymentSettingsIcon from '../../assets/icons/paymentsettings.svg';
import Button from '../../components/StyledComponents/Button';
import { Box } from '@material-ui/core';
import {
  frJsb,
  frAc,
  recommendationContainer,
  fcAc,
  fcJs,
  frJc,
  quickIcons,
  iconContainer,
  cursorPointer
} from '../../utilities/styles';
import LinkTextSM from '../shared/LinkTextSM';
import { StyledBox } from '../StyledComponents/StyledBox';

const QuickAction = ({ type, onClickHandler }) => {
  console.log("Quick action is called"+type);
  let content = {};
  switch (type) {
    case 'ImportMF':
      content.icon = <img src={ImportMfIcon} alt='' />;
      content.label = 'Import Mfs';
      break;
    case 'Invest':
      content.icon =  <img src={InvestIcon} alt='' />;
      content.label = 'Invest';
      break;
    case 'Redeem':
      content.icon =  <img src={RedeemIcon} alt='' />;
      content.label = 'Redeem';
      break;
    case 'Autopay':
      content.icon = <img src={AutoPayIcon} alt='' />;
      content.label = 'Set up autopay';   
      break;
    case 'Rebalance':
      content.icon = <img src={RebalanceIcon} alt='' />;
      content.label = 'Rebalance';   
      break;
    case 'PaymentSettings':
      content.icon = <img src={PaymentSettingsIcon} alt='' />;
      content.label = 'Payment settings';   
      break;
    default:
  } 

  return (
    <>
    <div style= {{ ...iconContainer, ...fcJs, ...cursorPointer}}>
      <Box onClick={onClickHandler}>
        <LinkTextSM style={{ ...quickIcons, textAlign: 'center' }}>{content.icon}</LinkTextSM>
      </Box>
      <Box>
       <LinkTextSM style={{ ...quickIcons, textAlign: 'left' }}>{content.label}</LinkTextSM>
      </Box>
    </div>
    </>
  );
};

export default QuickAction;


{/* <Button style={{ width: '50%', background: "#081320" }}>
          {content.label}
        </Button> */}