import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from '../pages/Login/LoginForm'
import SignupForm from '../pages/Login/SignupForm'
import OtpForm from '../pages/Login/EmailOtpForm'
import PhoneOtp from '../pages/Login/PhoneOtpForm';
import MobileOtpForm from '../pages/Login/SignupForm/MobileOtpForm';

export const LoginRoutes =()=> {
  return (
    <Router>
    <Routes>
    <Route path = "/" element = { <LoginForm />} />
    <Route path = "/signup" element = { <SignupForm />} />
    <Route path = "/otp" element = { <OtpForm />} />
    <Route path = "/phone-otp" element = { <PhoneOtp />} />
    <Route path = "/mobile-signup" element = { <MobileOtpForm />} />
    </Routes>
    </Router>
  );
}