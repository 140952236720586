import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { onboardingRoutes } from './constants';
import { useGenerateRoutes } from './OnboardingRoutes';

const MainRouter = ({ sectionsList }) => {
  console.log("The section list 11",sectionsList)
  return (
    <Routes>
      {useGenerateRoutes(sectionsList)}
      <Route
        path={onboardingRoutes.test.path}
        element={onboardingRoutes.test.component}
      />
    </Routes>
  );
};

export default MainRouter;
