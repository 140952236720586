import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
 Box,useTheme
} from '@material-ui/core';
import {fundInfoDetailsJson} from '../../mock-data/fundInfoJson';
import LinkTextSM from '../../components/shared/LinkTextSM';
import {
    frJsb,
    frJc,
    frJs,
    fonts,
    text14b,
    text14,
    fcJs,
    sipInvestBox,
    sipInvestSummaryBox,
    text16ln20,
    text24,
    text14fw500,
    frAs,
    sipInvestButton,
    margin9,
    amountExample,
    cursorPointer,
    fundCategoryInfo,
    text16b,
    fundDetailsBlock,
    frJsa,
    fcJsb,
    frAc,
    fundDetailsLine
  } from '../../utilities/styles';
  import { getFundInfoDetails } from '../../http-calls';
  import Button from '../../components/StyledComponents/Button';
  import NipponIcon from '../../assets/icons/Nippon_Life_Insurance.svg'; 

  const FundInfo = () => {
    const {fundInfoDetails} = fundInfoDetailsJson;
    let navigate = useNavigate();
    let onContinueHandler = () => {
        navigate('/investmentOptions');
    }
    let nipponIcon = <img src={NipponIcon} alt='' />

    useEffect(async () => {
        const response = await getFundInfoDetails({"mfId": 8643})
      }, []);
    return ( 
        <div>
            <Box style={{ ...frJc }}>
            <Box style={{ width: '50%' }}>
                    <Box style={{...fcJs,...sipInvestBox, border: `2.5px solid #F9F9F9`}}>
                        <Box style={{...frJsb}}>
                            <Box style={{...fcJs}}>
                                <Box style={{...frAs}}>
                                    <LinkTextSM style={{...text14, color: '#081320', marginTop:'15px', marginLeft:'15px', textAlign: 'left'}}>{nipponIcon}</LinkTextSM>   
                                    <LinkTextSM style={{...text14, color: '#081320', marginTop: '15px', marginLeft:'12px' }} fontFamily={fonts.rubikNormal.value}>Nippon India Liquid Fund Direct Plan Growth Plan</LinkTextSM>
                                </Box>
                                <Box style={{...frAs}}>
                                    <LinkTextSM style={{...text14, ...fundCategoryInfo, color: '#9CA1A6'}} fontFamily={fonts.rubikNormal.value}>{fundInfoDetails.data.mainData.fundLevelCategoryName}</LinkTextSM>
                                    <LinkTextSM style={{...text14, ...fundCategoryInfo, width: '80px',color: '#9CA1A6'}} fontFamily={fonts.rubikNormal.value}>Small Cap</LinkTextSM>
                                    <LinkTextSM style={{...text14, ...fundCategoryInfo, color: '#9CA1A6'}} fontFamily={fonts.rubikNormal.value}>5.0</LinkTextSM>
                                </Box>
                            </Box>
                            <Box style={{...fcJs}}>
                                    <LinkTextSM style={{...text14,marginTop: '20px',color: '#081320', marginRight: '10px'}}>{fundInfoDetails.data.mainData.Return3Yr}</LinkTextSM>
                                    <LinkTextSM style={{color: '#6B7179', marginRight: '10px'}}>1 Year Return</LinkTextSM>
                            </Box>
                        </Box>
                        <Box style={{...frJs}}>
                             
                        </Box>
                        <Box style={{...frJs}}>
                            <LinkTextSM style={{...text16ln20, color: '#081320', marginLeft:'17px', marginTop:'50px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}></LinkTextSM> 
                            <Box style={{marginTop:'25px', marginLeft: '95px'}}>
                               
                            </Box>  
                        </Box>
                        <Box style={{...frJs}}>
                            <LinkTextSM style={{...text16ln20, color: '#081320', marginLeft:'17px', marginTop:'50px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}></LinkTextSM>
                            <Box style={{marginTop:'25px', marginLeft: '60px'}}>
                               
                            </Box> 
                            <LinkTextSM style={{...text14, color: '#9CA1A6', marginLeft:'25px', marginTop:'50px', textAlign: 'left'}} fontFamily={fonts.rubikNormal.value}> </LinkTextSM>
                        </Box>
                        <Box style={{...frJs, marginLeft: '150px'}}>
                           
                        </Box>

                    </Box>
                    <Box>
                        <LinkTextSM style={{...text16b, ...fundDetailsBlock}}>Fund Details</LinkTextSM>
                    </Box>
                    <Box style={{...frJs}}>
                        <Box style={{...fcJsb}}>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, color: '#081320', marginLeft: '60px',  marginBottom: '10px'}}>Risk</LinkTextSM>
                                <LinkTextSM style={{...text14, color: '#081320',  marginLeft: '120px',  marginBottom: '10px'}}>Very High</LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, ...fundDetailsLine }}></LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, color: '#081320', marginLeft: '60px',  marginBottom: '10px', marginTop: '10px'}}>Min SIP Amount</LinkTextSM>
                                <LinkTextSM style={{...text14, color: '#081320',  marginLeft: '120px',  marginBottom: '10px', marginTop: '10px'}}>₹500</LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, ...fundDetailsLine }}></LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, color: '#081320', marginLeft: '60px',  marginBottom: '10px', marginTop: '10px'}}>Expense Ratio</LinkTextSM>
                                <LinkTextSM style={{...text14, color: '#081320',  marginLeft: '120px',  marginBottom: '10px', marginTop: '10px'}}>0.44%</LinkTextSM>
                            </Box>
                        </Box>

                        <Box style={{...fcJsb}}>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, color: '#081320', marginLeft: '60px',  marginBottom: '10px'}}>NAV</LinkTextSM>
                                <LinkTextSM style={{...text14, color: '#081320',  marginLeft: '120px',  marginBottom: '10px'}}>₹60.45 (16 Jul 2021)</LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, ...fundDetailsLine }}></LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, color: '#081320', marginLeft: '60px',  marginBottom: '10px', marginTop: '10px'}}>Fund Started</LinkTextSM>
                                <LinkTextSM style={{...text14, color: '#081320',  marginLeft: '120px',  marginBottom: '10px', marginTop: '10px'}}>29 Nov 2013</LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, ...fundDetailsLine }}></LinkTextSM>
                            </Box>
                            <Box style={{...frJsb}}>
                                <LinkTextSM style={{...text14b, color: '#081320', marginLeft: '60px',  marginBottom: '10px', marginTop: '10px'}}>Fund Size</LinkTextSM>
                                <LinkTextSM style={{...text14, color: '#081320',  marginLeft: '120px',  marginBottom: '10px', marginTop: '10px'}}>₹6,009 Cr</LinkTextSM>
                            </Box>
                        </Box>
                        <Box style={{...fcJs}}></Box>

                    </Box>
                </Box>
                

                <Box style={{...frJc, width: '100%'}}>
                    <Box style= {{width: '50%', margin: '200px'}}>
                        <Button onClick={() => onContinueHandler()}>Continue</Button>
                    </Box>
                </Box> 
            </Box> 
        </div>
        )
  }

  export default FundInfo;