import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";

const CustomTimer = ({ handleTimerOver, timeLimit = 60, textStyle }) => {
  const [time, setTime] = useState(timeLimit);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
    if (time === 0) {
      clearInterval(interval);
      // Timer over function call
      if (handleTimerOver) handleTimerOver();
    }
    return () => clearInterval(interval);
  }, [time]);

  return <div style={{ ...textStyle }}>{time}</div>;
};

export default CustomTimer;
