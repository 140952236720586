import React, { useEffect, useRef, useState } from "react";

import { useTheme } from "@material-ui/core";
import { Box } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { StyledBox } from "./StyledComponents/StyledBox";
import animation from '../assets/Interface_waiting.gif'
import '../App.css'

import {
  fcAc,
  fcJsb,
  fcJs,
  inheritHeight,
  inheritSize,
  taf,
  frJse,
  text14,
  loginContainer,
  imageContainerInitialLoader
} from "../utilities/styles";
import { LoaderList } from "../components/LoaderList";
import {
  useCustomQuery,
  useSampleJson,
  usePolling,
  useInitialVerifyInfo,
} from "../hooks/fetchQuery";
import Text from "../components/shared/Text";
import HeaderText from "./shared/HeaderText";

import {
  setCustomQuery,
  getSectionData,
  getPollingStatus,
  getOverlayComponent,
  getAge,
  reverseDateFormat,
  setAccountActivationData,
  getCustomQuery,
  setAccountActivationVerified,
  setAccountActivationRejected,
  setupForwardNavigation,
  updateSingleAccountActivationStatus,
  skipDigilockerStep,
} from "../helper-methods";
import {
  navigateMapping,
  overlayStates,
  selectApiPath,
  navigationKND,
  accountActivationMapping,
  defaultDocumentCheckOrder,
  defaultDocumentNavigationOrder,
  keyMapping,
  reverseKeyMapping,
} from "../helper-methods/constants";
import {
  getPollingDetailsApi,
  getInitialAccountDetails,
  getDocumentDetails,
  postVerfiyKycDetails,
  postVerfiyPennyDrop,
} from "../http-calls";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import FullpageLoader from "./shared/FullpageLoader";

const validateResponse = (response, isDocumentRequired) => {
  const status = response?.status;
  const data = response?.data;
  if (status.toUpperCase() === "SUCCESS") {
    if (data) {
      for (let [key, val] of Object.entries(data)) {
        if (
          typeof val === "object" &&
          val?.status?.toUpperCase() === "PENDING"
        ) {
          if (key === "cheque" && !isDocumentRequired) continue;
          return { isValid: false, updateCache: true, nextVal: key };
        } else if (
          key?.toLowerCase() === "guardianname" &&
          data["nomineeDob"] !== ""
        ) {
          if (
            getAge(reverseDateFormat(data["nomineeDob"])) < 18 &&
            val === ""
          ) {
            return { isValid: false, updateCache: true };
          }
        } else if (val === "" || val === undefined || val === null) {
          return { isValid: false, updateCache: true };
        }
      }
    }
  } else return { isValid: false, updateCache: false };

  return { isValid: true, updateCache: data ? true : false };
};
const checkDocumentRequired = (data) => {
  return data?.data?.data?.message?.data;
};

const InitialLoaderPage = () => {
  const { data: customerId } = useCustomQuery({ section: "customerId" });
  const {
    data: initialVerifyResponse,
    isLoading: isInitialInfoLoading,
    isFetching: isInitialInfoFetching,
    isError: initialVerifyError,
  } = useInitialVerifyInfo({ customerId });
  const { data: sampleJson, isLoading, isFetching } = useSampleJson();
  const { data: homeSectionQueryData } = useCustomQuery({ section: "home" });
  const theme = useTheme();
  const [showUpto, setShowUpto] = useState(0);
  const [errorIndex, setErrorIndex] = useState(null);
  const [overlayState, setOverlayState] = useState(null);
  const [requestIdState, setRequestIdState] = useState(null);
  const { kycDoggyLoaderScreen, messages } = sampleJson.data.data;
  const initialVerifyData = initialVerifyResponse?.data?.data?.data;

  const flow=initialVerifyResponse?.data?.data?.flow;

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const section = "home";
  let localHomeQueryData = { ...homeSectionQueryData };

  const redirectTo = (path) => {
    console.log("reached funcction redirect to",path)
    setCustomQuery({
      section: "backPath",
      data: { redirectToHomeFrom: path, backPath: "/home" },
      queryClient,
    });
    navigate(`${navigateMapping[path] || path}${search}`);
  };

  const moveToNextItem = () => {
    setShowUpto((showUpto) => showUpto + 1);
  };

  const errorOverlay = () => setOverlayState(overlayStates.EXTERNAL_API_ERROR);
  const updateRequestId = (val) => setRequestIdState(val);
  const overlayStateSetter = (val) => setOverlayState(val);

  const storeDataInCache = ({ queryKey, data }) => {
    setCustomQuery({ section: queryKey, data, queryClient: queryClient });
  };

  useEffect(() => {
    if (isLoading || isFetching || homeSectionQueryData) return;

    setCustomQuery({
      section: section,
      data: sampleJson
        ? getSectionData({ response: sampleJson, section: section })[section]
        : null,
      queryClient: queryClient,
    });
  }, [sampleJson]);

  if ( initialVerifyError) {
    return <FullpageLoader />;
  }

  const arr = [
    // {
    //   text: "Checking your Mutual Fund KYC status.",
    //   handler: async (errorSideEffect) => {
    //     if (initialVerifyData?.verifyKycStatusDone) {
    //       moveToNextItem();
    //       return;
    //     }

    //     let postResponse = null;

    //     try {
    //       postResponse = await postVerfiyKycDetails({ customerId });
    //     } catch (e) {
    //       errorSideEffect();
    //       errorOverlay();
    //       return;
    //     }

    //     if (postResponse.error) {
    //       errorSideEffect();
    //       errorOverlay();
    //       return;
    //     }

    //     const requestId = postResponse?.data?.data.requestId;
    //     updateRequestId(requestId);
    //   },
    // },
    {
      text: "Checking your Niyo Money account details.",
      handler: async (errorSideEffect) => {
        const apiOrder = [
          "panDetails",
          "basicDetails",
          "kycDetails",
          "nomineeDetails",
          "bankDetails",
        ];
        const formResponses = await Promise.all(
          apiOrder.map((apiPath) =>
            getInitialAccountDetails({
              customerId,
              endpoint: selectApiPath[apiPath],
            })
          )
        );
        formResponses.map((response, i) => {
          storeDataInCache({
            queryKey: `${apiOrder[i]}FormCache`,
            data: response?.data?.data?.data,
          });
        });

        for (let i = 0; i < apiOrder.length; i++) {
          const response = formResponses[i];

          if (response.error) {
            if (response.error.status === 422) {
              errorSideEffect();
              setAccountActivationData({
                itemIndex: 0,
                isCompleted: false,
                isDisabled: false,
                nextPath: navigateMapping[apiOrder[i]],
                queryClient,
                homeSectionQueryData,
              });
              redirectTo(apiOrder[i]);
              return;
            }
            errorOverlay();
            return;
          }
          const { isValid, updateCache } = validateResponse(
            response?.data?.data
          );
          if(flow.completedTasks.length>0){
            redirectTo(navigationKND[flow?.completedTasks[flow.completedTasks.length -1]])
          }
          else{
            redirectTo("/accountDetails/panDetails")
          }
        }

      },
    },
    // {
    //   text: "We are depositing Re. 1 to verify your bank details.",
    //   handler: async (errorSideEffect) => {
    //     if (initialVerifyData?.pennyDropDone) {
    //       moveToNextItem();
    //       return;
    //     }
    //     const postResponse = await postVerfiyPennyDrop({ customerId });

    //     if (postResponse.error) {
    //       errorSideEffect();
    //       errorOverlay();
    //       return;
    //     }

    //     const requestId = postResponse?.data?.data.requestId;

    //     updateRequestId(requestId);
    //   },
    // },
    {
      text: "Checking required documents.",
      handler: async (errorSideEffect) => {
        const response = await getDocumentDetails({ customerId });

        if (response.error) {
          if (response.error.status === 404) {
            redirectTo("cheque");
          }
          errorSideEffect();
          errorOverlay();
          return;
        }
        return;
      },
    },
  ];
  if (isLoading || isFetching || isInitialInfoLoading || isInitialInfoFetching)
  return <FullpageLoader />;

  if (isLoading || isFetching || isInitialInfoLoading || isInitialInfoFetching)
    return <FullpageLoader />;
    // "panDetails",
    //       "basicDetails",
    //       "kycDetails",
    //       "nomineeDetails",
    //       "bankDetails",
    // redirectTo("bankDetails")
  return (
    <>
      <div style={{ ...inheritSize, ...fcAc }}>
        <StyledBox style={{ ...inheritHeight, ...fcJs, ...loginContainer, height: '100%' }}>
          <HeaderText>{kycDoggyLoaderScreen.heading}</HeaderText>
          <Box sx={{
            marginBottom: "23%",
            ['@media (max-width:780px)']: {
              width : '100% !important'
            },
          }}
          >
          <img src={animation} alt='' 
          // style={{width: '100%' ,height: '80%', position: 'relative', bottom: '13%'}}
          style={{...imageContainerInitialLoader, maxHeight: '100% !important', marginBottom: '23%'}}
          />
          </Box>
          <Box
            sx={{
              textAlign: taf.textAlign,
              display: frJse.display,
              position: "absolute",
              bottom: "21%",
            }}
          >
            <FiberManualRecordIcon
              style={{ color: theme.palette.secondary.bullet_gray }}
            />
            <Text
              fontSize={text14.fontSize}
              style={{
                marginLeft: "30px",
                color: '#000',
              }}
            >
              {kycDoggyLoaderScreen.note.text_1}
              {arr.map((item, index) => {
                if (index > showUpto) return null;
                return (
                  <div style={{ marginTop: "20px" }}>
                    <LoaderList
                      text={item.text}
                      handler={item.handler}
                      moveToNextItem={moveToNextItem}
                      showError={index === errorIndex}
                      showTimerInitial={
                        index === showUpto && index !== errorIndex
                      }
                      errorOverlay={errorOverlay}
                    />
                  </div>
                );
              })}
            </Text>
          </Box>
        </StyledBox>
      </div>
    </>
  );
};

export default InitialLoaderPage;

