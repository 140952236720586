import React from 'react';
import GoogleIcon from '../../assets/icons/google-icon.svg';
import { Button, withStyles, Box } from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-evenly',
    // border: '1px solid var(--primaryDark)',
  //  background:' #fff !important'
  },
  
  label: {
    textTransform: 'none',
  },
})(Button);

const LoginButton = ({ type, onClick, disabled }) => {
  let content = {};
  switch (type) {
    case 'google':
      content.icon = <img src={GoogleIcon} alt='' />;
      content.label = 'Continue with Google';
      break;
    case 'email':
      content.icon = <span className='material-icons'>email</span>;
      content.label = 'Continue with Email';
      break;
    default:
  }

  return (
    <>
      <StyledButton
        variant='outlined'
        // color='black'
        fullWidth
        startIcon={content.icon}
        endIcon={<div />}
        onClick={onClick}
        disabled={disabled}
        className="google-button"
      >
        <Box style={{ textAlign: 'left' }}>{content?.label}</Box>
      </StyledButton>
    </>
  );
};

export default LoginButton;
