import React, { useState } from 'react';
import { StyledTextField } from '../StyledComponents/InputField';
import { Box, Typography, useTheme } from '@material-ui/core';
import { fonts, text20, addMargin, frAc, text12 } from '../../utilities/styles/index';
import Text from './Text';
import { useEffect } from 'react';
import { getAge, getYYYYMMDD } from '../../helper-methods';
import SpinnerDateComponent from "../../components/SpinnerDateComponent";

const TextInput = ({
  inputKey,
  autoFocus,
  valueUpdateHandler,
  label,
  placeHolder,
  fullWidth,
  errors,
  required,
  type,
  value,
  note_text,
  disabled,
  dobOverlayTrigger,
  subSection,
  section
}) => {
  const isError = errors?.length > 0;
  const theme = useTheme();
  const [inputValue, _inputValue] = useState(value);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (value != inputValue) _inputValue(value);
  }, [value]);

  const _onChange = (e) => {
    let value = e.target.value;
    valueUpdateHandler(value);

  };

  const getFieldType = (type) => {
    if (sessionStorage.getItem('platformId')?.toLowerCase() === 'ios') {
      return 'text';
    }
    return type;
  };
  const dobCloseHandler = () => {
    setOpen(!open)
  }

  const getDOB = (date) => {
    _inputValue(date)
    valueUpdateHandler(date);
  }
  return (
    <>
      {open &&
        <SpinnerDateComponent
          customHeader="Date of birth"
          confirmText="Confirm"
          cancelText="Cancel"
          section={section}
          subSection={subSection}
          cancelHandler={dobCloseHandler}
          getDOB={getDOB}
        />
      }
      {type == 'date' ? (
        <StyledTextField
          type={'text'}
          fullWidth={true}
          autoFocus={autoFocus}
          label={label}
          value={inputValue}
          onClick={dobCloseHandler}
          inputKey={inputKey}
          InputProps={{
            inputProps: {
              min: '1900-01-01',
              max: getYYYYMMDD(new Date()),
              readOnly: true,
              disableUnderline: true
            }, disableUnderline: true
          }}
          error={
            inputKey === 'dob' ? inputValue && getAge(inputValue) < 18 : isError
          }
          disabled={disabled}
          required={required}
        />
      ) : (
        <StyledTextField
          type={type}
          fullWidth={true}
          autoFocus={autoFocus}
          InputProps={{
            disableUnderline: true,
          }}
          label={label}
          value={inputValue}
          onChange={_onChange}
          error={isError}
          disabled={disabled}
          required={required}
        />
      )}

      {isError && (
        <Box py={0.5} px={2} component='small' style={{ ...frAc }}>
          <Typography
            style={{
              ...text12,
              color: theme.palette.error.main,
              fontFamily: fonts.rubik.value,
              fontWeight: text20.fontWeight
            }}
          >
            {errors[0]}
          </Typography>
        </Box>
      )}
      {required && (
        <Text
          fontSize={12}
          fontFamily={fonts.rubik.value}
          style={{
            color: inputValue && getAge(inputValue) < 18 ? theme.palette.error.main : theme.palette.common.lightBlack,
            marginLeft: theme.spacing(2),
            marginBottom: addMargin.marginBottom,
            fontWeight: text20.fontWeight
          }}
        >
          {note_text}
        </Text>
      )}
    </>
  );
};

export default TextInput;
