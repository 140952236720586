import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useCustomQuery } from '../../hooks/fetchQuery';
import { selectApiPath, overlayStates, accountActivationMapping } from '../../helper-methods/constants';
import Form from './Form';
import FullpageLoader from '../shared/FullpageLoader';
import {
  getCustomQuery,
  getOverlayComponent,
  setBackButtonPath,
  setAccountActivationData,
  getBackButtonPath,
  updateFormCache,
} from '../../helper-methods';
import { postUserDetails } from '../../http-calls';

const FormContainer = ({
  subSection,
  section,
  onSubmitPostHandler,
}) => {
  const queryClient = useQueryClient();
  const sampleJsonData = getCustomQuery({ section: 'sampleJson', queryClient });
  const { data: homeSectionQueryData } = useCustomQuery({ section: 'home' });
  const {
    data: syncPostApiData,
    isLoading,
    remove,
  } = useCustomQuery({
    section: selectApiPath[subSection],
  });
  const { data: sectionData } = useCustomQuery({ section });
  const inputFieldsData = sectionData?.inputFields[subSection];
  const { search } = useLocation();
  const navigate = useNavigate();
  const customerId = getCustomQuery({ section: 'customerId', queryClient });
  const { data: forwardNavigationMapping } = useCustomQuery({
    section: 'forwardNavigationMapping',
  });
  const [overlayState, _setOverlayState] = useState(null);
  const [skip, _skip] = useState(false);
  const { data: customerStatus } = useCustomQuery({ section: 'customerStatus' });

  useEffect(() => {
    if (syncPostApiData) {
      if (syncPostApiData?.data?.status == 200) {
        const { index: itemIndex, isLast: isCompleted } =
          accountActivationMapping[subSection];

        setAccountActivationData({
          itemIndex,
          isCompleted,
          isDisabled: false,
          nextPath: forwardNavigationMapping[subSection],
          queryClient,
          homeSectionQueryData,
          initialUpdate: false,
        });
        remove();
        navigate(`${forwardNavigationMapping[subSection]}${search}`);
      } else {
        _setOverlayState(overlayStates.EXTERNAL_API_ERROR);
      }
    }
  }, [syncPostApiData]);

  useEffect(() => {
    const backPathData = getBackButtonPath({ queryClient });
    if (backPathData?.redirectToHomeFrom === subSection) {
      setBackButtonPath({ data: '/home', queryClient });
    }
  }, [subSection]);

  useEffect(async () => {
    if (skip) {
      try {
        console.log({
          skippayload: {
            customerId,
            payload: { nomineeAvailable: false },
            path: selectApiPath[subSection],
          },
        });
        const postUserDetailsResponse = await postUserDetails({
          customerId,
          payload: { nomineeAvailable: false },
          path: selectApiPath[subSection],
        });
        console.log({ postUserDetailsResponse });
        if (!postUserDetailsResponse?.data) throw 'API_ERROR_FORM_NOT_SKIPPED';
        console.log('form-successfully-skipped');
        _skip(false);
        navigate(`${forwardNavigationMapping[subSection]}${search}`);
        setBackButtonPath({ data: null, queryClient });
      } catch (e) {
        console.log('error-while-skipping:', e);
        _skip(false);
        _setOverlayState(overlayStates.EXTERNAL_API_ERROR);
      }
    } else {
      _setOverlayState(null);
    }
  }, [skip]);

  const secondaryCtaHandler = () => {
    // required for nomineeDetails
    _skip(true);
  };

  const continueHandler = async () => {
    await onSubmitPostHandler();
    updateFormCache({ inputFieldsData, queryClient, subSection });
    setBackButtonPath({ data: null, queryClient });
    console.log('The subsection in continue handler :', inputFieldsData);
  };

  const closeOverlay = () => {
    _setOverlayState(null);
  };

  if (isLoading || skip) {
    return <FullpageLoader />;
  }

  const dobOverlayTrigger = () => {
    console.log('overlayStateChanged');
    _setOverlayState(overlayStates.DOB_SPINNER);
  };

  const dobCloseHandler = () => {
    console.log('here');
    _setOverlayState(null);
  };

  return (
    <>
      {/* {overlayState &&
        getOverlayComponent({
          section,
          subSection,
          messages: sampleJsonData?.data?.data?.messages?.common,
          overlayState,
          closeOverlay,
          dobCloseHandler,
        })} */}
      <Form
        subSection={subSection}
        section={section}
        secondaryCtaHandler={secondaryCtaHandler}
        continueButtonHandler={continueHandler}
        dobOverlayTrigger={dobOverlayTrigger}
      />
    </>
  );
};

export default FormContainer;
