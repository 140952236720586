import React, { useEffect, useState } from 'react';
import {
    getCustomQuery,
    setCustomQuery,
  } from '../helper-methods'
  import { useQueryClient } from "react-query";
  
const OtpTimer=({checkTimer})=>{
    const queryClient=useQueryClient()
    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        console.log("The second ",seconds)

        const interval = setInterval(() => {
          if (seconds > 0) {
            
            setSeconds(seconds - 1);
          }
      
          if (seconds === 0) {
              checkTimer(false)
              clearInterval(interval);
          }
        }, 1000);
 
        return () => {
          clearInterval(interval);
        };
      }, [seconds]);

    return (
        seconds ==0 ? null :
        <div>
        <div>
        <span st>Resend in :</span> {seconds}
           </div> 
       </div>
      
    )
}

export default OtpTimer;