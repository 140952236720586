import React from 'react';
import { usePostUserDetails, useCustomQuery } from '../../hooks/fetchQuery';
import FormContainer from '../../components/FormContainer/FormContainer';
import { createSaveDetailsPayload } from '../../helper-methods';
const SyncSubSection = ({ subSection, section }) => {
  const { data: customerId } = useCustomQuery({ section: 'customerId' });
  const sectionQuery = useCustomQuery({ section: section });
  const ifscFetchData = useCustomQuery({ section: 'ifscCheck' });
  const subSectionData = sectionQuery?.data?.['inputFields']?.[subSection];
  const payload = {
    payload: createSaveDetailsPayload({
      subSection,
      subSectionData,
      ifscFetchData,
    }),
    customerId: customerId,
  };
  const postSyncQuery = usePostUserDetails({ data: payload, subSection });
  return (
    <FormContainer
      subSection={subSection}
      section={section}
      onSubmitPostHandler={postSyncQuery?.refetch}
    />
  );
};

export default SyncSubSection;
