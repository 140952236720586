import React from 'react';
import BottomSheet from '../BottomSheet';

import HeaderText from './HeaderText';
import SecondaryText from './SecondaryText';
import failureIcon from './../../assets/failure.svg';
import successIcon from './../../assets/success.svg';
import ModalPopup  from '../Modal';
const OverlayPopup = ({
  show,
  children,
  toggle,
  headerText,
  secondaryText,
  imageSource,
  showIcon,
  height = 'fit-content',
}) => {
  return (
    // <BottomSheet toggle={toggle} show={show} height={height}>
    //   {showIcon && <img style={{ marginBottom: '20px' }} src={imageSource} />}
    //   {headerText && <HeaderText>{headerText}</HeaderText>}
    //   {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
    //   {children}
    // </BottomSheet>
        <ModalPopup toggle={toggle} show={show} height={height}>
      {showIcon && <img style={{ marginBottom: '20px' }} src={imageSource} />}
      {headerText && <HeaderText>{headerText}</HeaderText>}
      {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
      {children}
    </ModalPopup>
    
  );
};

export default OverlayPopup;
