/*
    Please use "material-ui v4" spinner component.
    Refer: https://v4.mui.com/components/progress/#progress
*/

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import CircularProgress from "@material-ui/core/CircularProgress";
import animation from "../../assets/doggyLoader.json";
import Lottie from "lottie-react-web";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "auto",
  },
}));

const FullpageLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CircularProgress color="primary" /> */}
      {/* <Lottie
        options={{
          animationData: animation,
        }}
      /> */}
    </div>
  );
};

export default FullpageLoader;
