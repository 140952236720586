import React, { useState, useEffect } from 'react';
import { Box, FormControl, useTheme, Typography } from '@material-ui/core';
import { useQuery, useQueryClient } from 'react-query';
import { inheritWidth, text12, frAc } from '../../../utilities/styles';
import { StyledTextField } from '../../StyledComponents/InputField';
import {
  overlayStates,
  selectApiPath,
} from '../../../helper-methods/constants';
import { fonts } from '../../../utilities/styles';
import {
  getCustomQuery,
  valueUpdateHandler,
  setDisableButton,
  setCustomQuery,
} from '../../../helper-methods';
import { useCustomQuery } from '../../../hooks/fetchQuery';
import HeaderText from '../../shared/HeaderText';
import SecondaryText from '../../shared/SecondaryText';
import Button from '../../StyledComponents/Button';

const NameMismatchOverlay = ({
  section,
  subSection,
  messages,
  primaryCtaHandler,
}) => {
  const queryClient = useQueryClient();
  const sectionData = getCustomQuery({ section: section, queryClient });
  const overlayData = messages[subSection][overlayStates.NAME_MISMATCH_ERROR];
  const [inputItem, _inputItem] = useState(overlayData?.fieldsObject);
  const [isQueryUpdated, _isQueryUpdated] = useState(false);
  const [disableSumbit, _disableSumbit] = useState(true);
  const isError = inputItem?.errors?.length > 0;
  const theme = useTheme();
  const pollingQuery = useCustomQuery({
    section: `${selectApiPath[subSection]}Polling`,
  });
  const asyncPostQuery = useCustomQuery({
    section: selectApiPath[subSection],
  });
  const onSubmitHandler = (e) => {
    setCustomQuery({
      section,
      data: {
        ...sectionData,
        inputFields: {
          ...sectionData?.inputFields,
          panDetails: {
            ...sectionData?.inputFields?.panDetails,
            panName: inputItem,
          },
        },
      },
      queryClient,
    });
    _isQueryUpdated(true);
  };
  useEffect(() => {
    pollingQuery.remove();
    asyncPostQuery.remove();
  }, []);
  useEffect(() => {
    if (isQueryUpdated) {
      primaryCtaHandler();
    }
  }, [isQueryUpdated]);
  useEffect(() => {
    setDisableButton({ inputItem, disableSumbit, _disableSumbit });
  });
  return (
    <>
      <Box>
        <img
          style={{ marginBottom: '20px', height: '40px' }}
          src={overlayData.imageUrl}
        />
        <HeaderText>{overlayData.title}</HeaderText>
        <SecondaryText>{overlayData.description}</SecondaryText>
        <FormControl
          component='fieldset'
          style={{ ...inheritWidth, margin: '20px 0' }}
        >
          <StyledTextField
            type={inputItem.type}
            placeholder={inputItem.placeHolder}
            fullWidth
            autoFocus
            label={inputItem.displayName}
            value={inputItem.value}
            inputProps={{ pattern: inputItem.regex }}
            onChange={(e) => {
              valueUpdateHandler({
                key: inputItem.key,
                value: e.target.value,
                inputItem,
                _inputItem,
              });
            }}
            error={isError}
            required
          />
          {isError && (
            <Box py={0.5} px={2} component='small' style={{ ...frAc }}>
              <Typography
                style={{
                  ...text12,
                  color: theme.palette.error.main,
                  fontFamily: fonts.rubik.value,
                  fontWeight: 400,
                }}
              >
                {inputItem.errors[0]}
              </Typography>
            </Box>
          )}
          <Box style={{ marginTop: '20px' }}>
            <Button
              disabled={disableSumbit}
              onClick={onSubmitHandler}
              style={{ width: '100%' }}
            >
              Submit
            </Button>
          </Box>
        </FormControl>
      </Box>
    </>
  );
};

export default NameMismatchOverlay;
