import React from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { overlayStates, selectApiPath } from '../helper-methods/constants';
import {
  generateEmailOTP, verifyEmailOTP, emailResendOTP,
  generateMobileOTP, verifyMobileOTP, mobileResendOTP,
  login, verifyGmail, verifyGoogleLogin, getCustomerSyncAPI, createV2CustomerUnifiedAPI, ifscCheckAPI,
  getDocumentDownloadApi,
  postUserDetails,
  getInitialVerifyInfo,
  getSampleJson,
  intiateEsign,
  intiateDigilocker,
  getFundInfoDetails
} from '../http-calls';

export const useCustomQuery = ({ section }) => {
  return useQuery(section, () => { }, {
    enabled: false,
  });
};
export const useCustomerSync = () => {
  return useQuery(
    `customerSync`,
    () => getCustomerSyncAPI(),
    {
      onSuccess: (data) => {
      },
      onError: (err) => {},
      enabled: false,
    }
  );
}
export const useEsignInitiation = (data) => {
  const queryClient = useQueryClient();
  return useMutation(intiateEsign, {
    onSuccess: async (data1) => {
      // return data ;
      // const message='success'
    },
    onError: (err) => {},

    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  });
};

export const useDigilockerInitiation = (data) => {
  const queryClient = useQueryClient();
  return useMutation(intiateDigilocker, {
    onSuccess: async (data1) => {
      // return data ;
      // const message='success'
    },
    onError: (err) => {},

    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  });
};

export const useGetDocumentDownload = (data) => {
  const { section, payload } = data;
  return useQuery(
    `user_docs_${section}`,
    () => getDocumentDownloadApi(payload),
    {
      enabled: false,
    }
  );
};

export const usePostUserDetails = ({ data, subSection }) => {
  const { customerId, payload } = data;
  console.log('data-to-send', payload);
  const path = selectApiPath[subSection];
  return useQuery(
    path,
    () =>
      postUserDetails({
        customerId,
        payload,
        path,
      }),
    {
      enabled: false,
    }
  );
};

export const useInitialVerifyInfo = (data) => {
  return useQuery('initialVerifyInfo', () => getInitialVerifyInfo(data), {});
};

export const usePolling = ({
  queryKey,
  queryFn, // should return value from overlayStates
  currentState, // state to start polling (should contain value from overlayStates)
  pollingStopSideEffect,
  pollingInterval = 4000,
}) => {
  return useQuery(queryKey, queryFn, {
    refetchInterval: (data) => {
      const status = data?.status;
      // Condition to stop polling
      if (!currentState || currentState === overlayStates.EXTERNAL_API_ERROR)
        return undefined;
      if (status && status !== currentState) {
        if (pollingStopSideEffect) {
          console.log('changing-overlay-state');
          pollingStopSideEffect(data);
        }
        return status === overlayStates.POLLING ? pollingInterval : undefined;
      }

      // Condition to start polling
      return currentState === overlayStates.POLLING
        ? pollingInterval
        : undefined;
    },
  });
};
export const useSampleJson = () => {
  return useQuery('sampleJson', () => getSampleJson(), { enabled: false });
};

export const useIFSCCheck = (value) => {
  const dataToSend = {
    ifsc: value,
  };
  return useQuery('ifscCheck', () => ifscCheckAPI(dataToSend), {
    enabled: false,
  });
};


export const CreateV2Customer = (data) => {
  const queryClient = useQueryClient();
  return useMutation(createV2CustomerUnifiedAPI, {
    onSuccess: (data) => {
      const message = 'success'
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const GenerateEmailOTP = (data) => {
  const queryClient = useQueryClient();
  return useMutation(generateEmailOTP, {
    onSuccess: (data) => {
      const message = 'success'
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const VerifyEmailOTP = (data) => {
  const queryClient = useQueryClient();
  return useMutation(verifyEmailOTP, {
    onSuccess: (data) => {
      const message = 'success';
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const ResendEmailOTP = (data) => {
  const queryClient = useQueryClient();
  return useMutation(emailResendOTP, {
    onSuccess: (data) => {
      const message = 'success';
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const GenerateMobileOTP = (data) => {
  const queryClient = useQueryClient();
  return useMutation(generateMobileOTP, {
    onSuccess: (data) => {
      const message = 'success'
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const VerifyMobileOTP = (data) => {
  const queryClient = useQueryClient();
  return useMutation(verifyMobileOTP, {
    onSuccess: (data) => {
      const message = 'success';
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const ResendMobileOTP = (data) => {
  const queryClient = useQueryClient();
  return useMutation(mobileResendOTP, {
    onSuccess: (data) => {
      const message = 'success';
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const Login = (data) => {
  const queryClient = useQueryClient();
  return useMutation(login, {
    onSuccess: (data) => {
      const message = 'success';
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const VerifyGmail = (data) => {
  const queryClient = useQueryClient();
  return useMutation(verifyGmail, {
    onSuccess: (data) => {
      const message = 'success';
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const VerifyGoogleLogin = (data) => {
  const queryClient = useQueryClient();
  return useMutation(verifyGoogleLogin, {
    onSuccess: (data) => {
      const message = 'success';
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}

export const GetFundInfo = (data) => {
  const queryClient = useQueryClient();
  return useMutation(getFundInfoDetails, {
    onSuccess: (data) => {
      const message = 'success'
    },
    onError: (err) => { },
    onSettled: () => {
      queryClient.invalidateQueries('created');
    },
  })
}
// export const fetchPreSingedUrl = (data) => {
//   return useQuery('pre_singed_url',  () => getPreSingedUrlApi(data), { enabled: false });
// }

// export const useEsignQuery = () => {
//   return useQuery('esignData', () => getEsignDetails(), { enabled: false });
// };

