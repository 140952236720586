import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
 Box,useTheme
} from '@material-ui/core';
import {quickActionJson} from '../../mock-data/quickActionsJson';
import Summary from '../../components/dashboardComponent/Summary';
import  QuickAction  from '../../components/dashboardComponent/QuickActions';
import Recommendations from '../../components/dashboardComponent/Recommendations';
import TransactionList from '../../components/dashboardComponent/TransactionList';
import LinkTextSM from '../../components/shared/LinkTextSM';
import {
    frJsb,
    frJs,   
    fcJs,
    quickActionContainer,
    quickActionSecondContainer,
    recommendationMainContainer,
    fcJc,
    frJc,
    fcAc,
    fcJse
  } from '../../utilities/styles';

const MainDashboard = () => {
    let  { quickActionsArray }  = quickActionJson.quickActions;
    let { recommendationArray } = quickActionJson.recommendations;
    let { summaryObj } = quickActionJson.summary;
    console.log("quick action"+JSON.stringify(quickActionsArray))
    let navigate = useNavigate();
    const onClick = () => {
        navigate('/fundInfo');
    }
    return ( 
        <div>
            <Box style={{...fcJse, width: '100%'}}>
                <Box style={{...frJsb}}>
                    <Box style={{width: '50%'}}>
                        <Summary
                            data= {summaryObj}
                        />
                    </Box>
                    <Box  style={{width: '50%', marginLeft: '120px'}}>
                        <LinkTextSM style={{ margin: '10px', textAlign: 'inherit', color: "#9CA1A6"  }}>Quick actions</LinkTextSM>
                        <div style= {{...frJsb, ...quickActionContainer}}>
                        {
                            quickActionsArray.map((item, index) => {
                            if(index < 3){
                                return ( 
                                <div>
                                <QuickAction
                                        type = {item.name}
                                        onClickHandler = {() => {onClick()}}
                                    />
                                </div> )
                            }
                            })
                        }
                        </div>
                        <div style= {{...frJsb, ...quickActionSecondContainer}}>
                            {
                                quickActionsArray.map((item,index)=> {
                                    if(index>=3){
                                        return (
                                            <div>
                                                <div>
                                                    <QuickAction
                                                    type = {item.name}
                                                    onClickHandler = ""
                                                    />
                                                </div>
                                            </div> )
                                    }
                                })
                            }
                        </div>
                    </Box>
                       
                </Box>
                <Box style={{...frJc, width: '100%'}}>
                    <Box style= {{width: '50%', margin: '10px'}}>
                        <TransactionList/>
                    </Box>
                    <Box style= {{width: '50%', marginLeft: '100px'}}>
                        <LinkTextSM style={{ margin: '10px', textAlign: 'inherit' , color: "#9CA1A6" }}>Recommendations for you</LinkTextSM>
                        {
                        recommendationArray.map(item => {
                            return  <Recommendations
                            type = {item.type}
                            onClickHandler = ""
                            header = {item.header}
                            body = {item.body}
                            />
                            })
                        }
                    </Box>
                </Box> 
            </Box>  
        </div>
        )
}

export default MainDashboard 