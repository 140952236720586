import React from 'react';

import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { ReactQueryDevtools } from 'react-query/devtools';

import './index.css';
import reportWebVitals from './reportWebVitals';
import './utilities/styles/index.css';

import { lightTheme } from './utilities/theme';
import App from './App'
import { LoginRoutes } from './router/LoginRoutes'
import { DashboardRoutes } from './router/DashboardRoutes'
const queryClient = new QueryClient();
const theme = createTheme(lightTheme);

const Root = () => {
  var user = localStorage.getItem('user')
  const onboardingDone = localStorage.getItem('onboardingDone')
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          overflowX: 'hidden',
        }}
      >
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            {!user ? <LoginRoutes />
              : onboardingDone === 'true' ? <DashboardRoutes /> : <App />
            }
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
          </QueryClientProvider>
        </React.StrictMode>
      </div>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



