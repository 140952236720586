const customQueryKeyConstantsNew = {
    0: 'home',
    1: 'Status',
    2: 'AccountDetails',
    3: 'Cheque',
    4: 'Signature',
    5: 'OnlineKyc',
  };
  
  const constantsToKeyNew = {
    home: 0,
    Status: 1,
    AccountDetails: 2,
    Cheque: 3,
    Signature: 4,
    OnlineKyc: 5,
  };
  
  const overlayStates = {
    POLLING: 'POLLING',
    INCOMPLETE: 'INCOMPLETE',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    EXTERNAL_API_ERROR: 'EXTERNAL_API_ERROR',
    PAN_DOB_API_ERROR: 'NAME_MISMATCH_ERROR',
    NAME_MISMATCH_ERROR: 'NAME_MISMATCH_ERROR',
    DOB_MISMATCH_ERROR: 'DOB_MISMATCH_ERROR',
    PAN_DOB_ERROR: 'NAME_MISMATCH_ERROR',
    UPLOAD_ERROR: 'UPLOAD_ERROR',
    NO_CAMERA_FOUND: 'NO_CAMERA_FOUND',
    ERROR_STARTING_CAMERA: 'ERROR_STARTING_CAMERA',
    CAMERA_PERMISSION_DENIED: 'CAMERA_PERMISSION_DENIED',
    PAN_NAME_MISMATCH_ERROR: 'PAN_NAME_MISMATCH_ERROR',
    PAN_NUMBER_MISMATCH_ERROR: 'PAN_NUMBER_MISMATCH_ERROR',
    DIGILOCKER_DATA_NOT_FOUND: 'DIGILOCKER_DATA_NOT_FOUND',
    CUSTOMER_PHOTO_AADHAAR_MISMATCH: 'CUSTOMER_PHOTO_AADHAAR_MISMATCH',
    KYC_VIDEO_NOT_FOUND: 'KYC_VIDEO_NOT_FOUND',
    FACE_MATCH_FAILURE: 'FACE_MATCH_FAILURE',
    TIME_EXCEED: 'TIME_EXCEED',
    DOB_SPINNER: 'DOB_SPINNER',
  };
  
  const customOverlayStates = {
    POLLING: 'POLLING',
    EXTERNAL_API_ERROR: 'EXTERNAL_API_ERROR',
    NAME_MISMATCH_ERROR: 'NAME_MISMATCH_ERROR',
    DOB_SPINNER: 'DOB_SPINNER',
  };
  
  const accountActivationMapping = {
    bankDetails: { index: 0, isLast: true },
    panDetails: { index: 0, isLast: false },
    basicDetails: { index: 0, isLast: false },
    kycDetails: { index: 0, isLast: false },
    nomineeDetails: { index: 0, isLast: false },
    pan: { index: 3, isLast: false },
    cheque: { index: 1, isLast: true },
    signature: { index: 2, isLast: true },
    digilocker: { index: 3, isLast: false },
    photo: { index: 3, isLast: false },
    poa: { index: 3, isLast: false },
    poa_back: { index: 3, isLast: false },
    kyc_video: { index: 3, isLast: false },
    kycvideo: { index: 3, isLast: false },
    esign: { index: 3, isLast: true },
  };
  const selectApiPath = {
    panDetails: 'pan',
    basicDetails: 'basic',
    kycDetails: 'kyc',
    nomineeDetails: 'nominee',
    bankDetails: 'bank',
    LivelinessCheck: 'liveliness',
  };
  
  const navigateMapping = {
    bankDetails: '/accountDetails/bankDetails',
    panDetails: '/accountDetails/panDetails',
    basicDetails: '/accountDetails/basicDetails',
    kycDetails: '/accountDetails/kycDetails',
    nomineeDetails: '/accountDetails/nomineeDetails',
    cheque: '/cheque',
    signature: '/signature',
    photo: '/onlineKyc/digilocker',
    pan: '/onlineKyc/digilocker',
    poa: '/onlineKyc/digilocker',
    digilocker: '/onlineKyc/digilocker',
    poa_back: '/onlineKyc/digilocker',
    kyc_video: '/onlineKyc/livelinessCheck',
    kycvideo: '/onlineKyc/livelinessCheck',
    esign: '/onlineKyc/esign',
  };

  const navigationKND={
    "PAN":"/accountDetails/basicDetails",
    "BASIC":"/accountDetails/kycDetails",
    "KYC":"/accountDetails/nomineeDetails",
    "NOMINEE":"/cheque",
    "BANK_UPLOAD":"/signature",
    "BANK":"/signature",
    "SIGNATURE":"/onlineKyc/digilocker",
    "DIGILOCKER_INITIATION":"/onlineKyc/digilocker",
    "DIGILOCKER_UPDATE_STATUS":"/onlineKyc/digilocker",
    "DIGILOCKER_SYNC":"/onlineKyc/livelinessCheck",
    "LIVELINESS":"/onlineKyc/esign"
  }

  const navigationKN={
    "PAN":"/accountDetails/basicDetails",
    "BASIC":"/accountDetails/kycDetails",
    "KYC":"/accountDetails/nomineeDetails",
    "NOMINEE":"/cheque",
    "BANK_UPLOAD":"/signature",
    "BANK":"/signature",
    "SIGNATURE":"/allDone"
  }
  
  const keyMapping = {
    cheque: 'Cheque',
    signature: 'Signature',
    photo: 'Digilocker',
    pan: 'Digilocker',
    poa: 'Digilocker',
    digilocker: 'Digilocker',
    poa_back: 'Digilocker',
    kyc_video: 'LivelinessCheck',
    kycvideo: 'LivelinessCheck',
    esign: 'Esign',
  };
  
  const reverseKeyMapping = {
    panDetails: 'panDetails',
    basicDetails: 'basicDetails',
    kycDetails: 'kycDetails',
    nomineeDetails: 'nomineeDetails',
    bankDetails: 'bankDetails',
    Cheque: 'cheque',
    Signature: 'signature',
    Digilocker: 'digilocker',
    LivelinessCheck: 'kycvideo',
    Esign: 'esign',
  };
  
  const forwardNavigationMapping = {
    panDetails: '/',
    basicDetails: '/accountDetails/kycDetails',
    kycDetails: '/accountDetails/nomineeDetails',
    nomineeDetails: '/accountDetails/bankDetails',
    bankDetails: '/cheque',
    Cheque: '/signature',
    Signature: '/onlineKyc/digilocker',
    Digilocker: '/onlineKyc/livelinessCheck',
    LivelinessCheck: '/onlineKyc/esign',
    Esign: '/registrationSuccessful',
  };
  
  const defaultDocumentNavigationOrder = [
    'cheque',
    'signature',
    'digilocker',
    'kycvideo',
    'esign',
  ];
  
  const defaultDocumentCheckOrder = [
    'cheque',
    'signature',
    'digilocker',
    'pan',
    'poa',
    'photo',
    'kycvideo',
    'esign',
  ];
  
  const uploadDocObj = {
    signature: 'SignatureImageData',
    cheque: 'ChequeImageData',
  };
  
  const dateSpinnerKey = {
    panDetails: 'dob',
    nomineeDetails: 'nomineeDob',
  };

  const bankDocFileType=["jpg", "jpeg", "png", "pdf"]
  const signatureFileType=["jpg", "jpeg", "png", "pdf"]
  export {
    customQueryKeyConstantsNew,
    constantsToKeyNew,
    overlayStates,
    customOverlayStates,
    selectApiPath,
    navigateMapping,
    forwardNavigationMapping,
    uploadDocObj,
    accountActivationMapping,
    dateSpinnerKey,
    keyMapping,
    defaultDocumentNavigationOrder,
    defaultDocumentCheckOrder,
    reverseKeyMapping,
    navigationKND,
    bankDocFileType,
    signatureFileType
  };
  