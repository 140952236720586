import React from 'react';

import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core';

import { text16b, text16, fonts } from '../../utilities/styles/index';

const Text = ({
  bold,
  secondary,
  fontFamily = fonts.lexend.value,
  fontSize = 16,
  margin = '',
  centered = false,
  children,
  style,
  onClickHandler,
}) => {
  const theme = useTheme();
  const customStyle = bold
    ? {
        ...text16b,
        fontSize: fontSize,
        fontFamily: fontFamily,
        color: theme.palette.secondary.darker,
        margin: margin,
        textAlign: centered ? 'center' : 'left',
        ...style,
      }
    : {
        ...text16,
        fontFamily: fontFamily,
        fontSize: fontSize,
        margin: margin,
        textAlign: centered ? 'center' : 'left',
        color: secondary
          ? theme.palette.secondary.main
          : theme.palette.secondary.darker,
        ...style,
      };

  return (
    <Typography
      style={customStyle}
      onClick={onClickHandler ? onClickHandler : () => {}}
    >
      {children}
    </Typography>
  );
};

export default Text;
