import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useTheme } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

import { useCustomQuery, useSampleJson } from "../../hooks/fetchQuery";
import FullpageLoader from "../../components/shared/FullpageLoader";
import Error from "../../components/shared/Error";
import { setCustomQuery, getSectionDataNew } from "../../helper-methods";
import SectionItemContainer from "../../components/SectionItemContainer";

const OnlineKyc = ({ section }) => {
  const { isLoading, isError, data, isFetching, refetch } = useSampleJson();
  const sectionQuery = useCustomQuery({ section: section });
  const queryClient = useQueryClient();
  useEffect(async () => {
    if (data) return;
    await refetch();
  }, []);

  useEffect(() => {
    if (isLoading || isFetching || sectionQuery.data) return;

    setCustomQuery({
      section: section,
      data: data ? getSectionDataNew({ section, response: data }) : null,
      queryClient: queryClient,
    });
  }, [data]);

  if (isLoading || isFetching)
    return (
      <>
        <FullpageLoader />
      </>
    );

  if (isError) {
    return (
      <>
        <Error />
      </>
    );
  }

  return (
    <>
      <SectionItemContainer section={section} />
    </>
  );
};

export default OnlineKyc;
