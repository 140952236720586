export const fundInfoDetailsJson = {
    fundInfoDetails: {
        "data": {
            "mainData": {
                "label": "",
                "fundName": "Nippon India Liquid Fund - Direct Plan - Growth Plan",
                "Return3Yr": "5.0%",
                "RatingOverall": "NA",
                "fundLevelCategoryName": "Liquid",
                "logo": "https://alphafront.s3.ap-southeast-1.amazonaws.com/app_resources/partners/cropped/nippon-india-mutual-fund.png",
                "sid_link": "https://mf.nipponindiaim.com/investor-service/downloads/scheme-information-document",
                "figures": [
                    {
                        "label": "NAV Nov 26, 2021",
                        "nav": "<p> ₹ 5143.5016 <span style='color:#1fc266'> +0.01% </span></p> "
                    },
                    {
                        "label": "Fund Size",
                        "value": "₹27,296 Cr"
                    },
                    {
                        "label": "Exit Load",
                        "value": "0.0070% before 1 day, 0.0065% before 2 days, 0.0060% before 3 days, 0.0055% before 4 days, 0.0050% before 5 days, 0.0045% before 6 days"
                    },
                    {
                        "label": "Expense ratio",
                        "value": "0.18%"
                    },
                    {
                        "label": "Lock in period",
                        "value": "0 days"
                    }
                ]
            },
            "graphData": {
                "label": "",
                "list": [
                    {
                        "label": "1Y",
                        "valueToSend": 1
                    },
                    {
                        "label": "3Y",
                        "valueToSend": 3
                    },
                    {
                        "label": "5Y",
                        "valueToSend": 5
                    },
                    {
                        "label": "All",
                        "valueToSend": "All"
                    }
                ]
            },
            "similarFunds": {
                "label": "Similar Funds",
                "data": [
                    {
                        "returnsValue": "5.0%",
                        "returnsLabel": "3 years",
                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/franklin.png",
                        "morningstarrating": "",
                        "mfid": 12561,
                        "fundName": "Franklin India Liquid Fund - Super Institutional Plan - Direct Plan - Growth",
                        "fundGroup": null,
                        "fundcategory": "Liquid Fund",
                        "isClickable": true
                    },
                    {
                        "returnsValue": "5.0%",
                        "returnsLabel": "3 years",
                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/edelweiss.png",
                        "morningstarrating": "",
                        "mfid": 11837,
                        "fundName": "Edelweiss Liquid Fund - Direct Plan - Growth",
                        "fundGroup": null,
                        "fundcategory": "Liquid Fund",
                        "isClickable": true
                    },
                    {
                        "returnsValue": "5.0%",
                        "returnsLabel": "3 years",
                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/pgim.png",
                        "morningstarrating": "",
                        "mfid": 12266,
                        "fundName": "PGIM India Insta Cash Fund - Direct Plan - Growth",
                        "fundGroup": null,
                        "fundcategory": "Liquid Fund",
                        "isClickable": true
                    },
                    {
                        "returnsValue": "5.0%",
                        "returnsLabel": "3 years",
                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/birla.png",
                        "morningstarrating": "",
                        "mfid": 8552,
                        "fundName": "Aditya Birla Sun Life Liquid Fund - Direct Plan - Growth",
                        "fundGroup": null,
                        "fundcategory": "Liquid Fund",
                        "isClickable": true
                    },
                    {
                        "returnsValue": "4.9%",
                        "returnsLabel": "3 years",
                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/bnp-logo.png",
                        "morningstarrating": "",
                        "mfid": 14351,
                        "fundName": "BNP Paribas Liquid Fund - Direct Plan - Growth",
                        "fundGroup": null,
                        "fundcategory": "Liquid Fund",
                        "isClickable": true
                    }
                ]
            },
            "tabsList": [
                "insights",
                "about"
            ],
            "tabsObject": {
                "insights": {
                    "accordionList": [
                        "topHoldings",
                        "msMinInvestment",
                        "returns",
                        "peerComparision",
                        "taxDetails"
                    ],
                    "accordionObject": {
                        "returns": {
                            "label": "Returns",
                            "data": {
                                "heading": [
                                    "1Y",
                                    "3Y",
                                    "5Y",
                                    "All"
                                ],
                                "values": [
                                    {
                                        "label": "Fund",
                                        "values": [
                                            "3.3%",
                                            "5.0%",
                                            "5.8%",
                                            "7.1%"
                                        ]
                                    },
                                    {
                                        "label": "Category Average",
                                        "values": [
                                            "2.8%",
                                            "3.5%",
                                            "4.4%",
                                            "NA"
                                        ]
                                    },
                                    {
                                        "label": "Rank within category",
                                        "values": [
                                            "14",
                                            "3",
                                            "2",
                                            "NA"
                                        ]
                                    }
                                ]
                            }
                        },
                        "peerComparision": {
                            "label": "Peer Comparision",
                            "data": {
                                "heading": [
                                    "Fund Name",
                                    "Ratings",
                                    "Returns"
                                ],
                                "list": [
                                    {
                                        "values": [
                                            "Nippon India Liquid Fund - Direct Plan - Growth Plan",
                                            "NA",
                                            "5.0%"
                                        ],
                                        "isClickable": false,
                                        "mfid": 4145,
                                        "fundImageUrl": "https://alphafront.s3.ap-southeast-1.amazonaws.com/app_resources/partners/cropped/nippon-india-mutual-fund.png"
                                    },
                                    {
                                        "values": [
                                            "Franklin India Liquid Fund - Super Institutional Plan - Direct Plan - Growth",
                                            "NA",
                                            "5.0%"
                                        ],
                                        "isClickable": true,
                                        "mfid": 12561,
                                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/franklin.png"
                                    },
                                    {
                                        "values": [
                                            "Edelweiss Liquid Fund - Direct Plan - Growth",
                                            "NA",
                                            "5.0%"
                                        ],
                                        "isClickable": true,
                                        "mfid": 11837,
                                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/edelweiss.png"
                                    },
                                    {
                                        "values": [
                                            "PGIM India Insta Cash Fund - Direct Plan - Growth",
                                            "NA",
                                            "5.0%"
                                        ],
                                        "isClickable": true,
                                        "mfid": 12266,
                                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/pgim.png"
                                    },
                                    {
                                        "values": [
                                            "Aditya Birla Sun Life Liquid Fund - Direct Plan - Growth",
                                            "NA",
                                            "5.0%"
                                        ],
                                        "isClickable": true,
                                        "mfid": 8552,
                                        "fundImageUrl": "https://s3-ap-southeast-1.amazonaws.com/alphafront/app_resources/partners/cropped/birla.png"
                                    }
                                ]
                            }
                        },
                        "topHoldings": {
                            "label": "Top Holdings",
                            "data": {
                                "heading": [
                                    "Name",
                                    "Allocation"
                                ],
                                "list": [
                                    {
                                        "values": [
                                            "91 DTB 17092020",
                                            "12.07%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "91 DTB 29102020",
                                            "7.91%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "182 DTB 26112020",
                                            "4.52%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "91 DTB 08102020",
                                            "4.33%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "91 DTB 22102020",
                                            "3.87%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "91 DTB 26112020",
                                            "3.14%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "91 DTB 15102020",
                                            "2.96%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "91 DTB 03092020",
                                            "2.58%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "91 DTB 10092020",
                                            "2.15%"
                                        ]
                                    },
                                    {
                                        "values": [
                                            "182 DTB 17092020",
                                            "0.93%"
                                        ]
                                    }
                                ]
                            }
                        },
                        "msMinInvestment": {
                            "label": "Minimum Investments",
                            "data": {
                                "orientation": "horizontal",
                                "details": [
                                    {
                                        "label": "Min amount for SIP",
                                        "value": "₹500"
                                    },
                                    {
                                        "label": "Min for first investment",
                                        "value": "₹100"
                                    },
                                    {
                                        "label": "Min for second investment onwards",
                                        "value": "₹100"
                                    }
                                ]
                            }
                        },
                        "taxDetails": [
                            {
                                "label": "Redeem before 3 years",
                                "value": "Income tax bracket",
                                "description": ""
                            },
                            {
                                "label": "Redeem after 3 years",
                                "value": "20%",
                                "description": "on gains above inflation-indexed investment amount"
                            }
                        ]
                    }
                },
                "about": {
                    "dataList": [
                        "aboutDetails",
                        "fundManager"
                    ],
                    "dataObject": {
                        "fundManager": {
                            "label": "Fund Manager",
                            "data": [
                                {
                                    "name": "Anju Chhajer",
                                    "role": "Manager",
                                    "startDate": "Oct 2013",
                                    "tenure": "6.92",
                                    "fundsManaged": [
                                        {
                                            "id": 3933,
                                            "fundname": "Nippon India Floating Rate Fund - Growth"
                                        },
                                        {
                                            "id": 3985,
                                            "fundname": "Nippon India Money Market Fund - Growth"
                                        },
                                        {
                                            "id": 4142,
                                            "fundname": "Nippon India Liquid Fund - Growth Option"
                                        },
                                        {
                                            "id": 61,
                                            "fundname": "Nippon India Low Duration Fund - Growth"
                                        },
                                        {
                                            "id": 9088,
                                            "fundname": "Nippon India Floating Rate Fund - Direct Plan - Growth Plan"
                                        }
                                    ]
                                },
                                {
                                    "name": "Kinjal Desai",
                                    "role": "Manager",
                                    "startDate": "May 2018",
                                    "tenure": "2.27",
                                    "fundsManaged": [
                                        {
                                            "id": 9239,
                                            "fundname": "Nippon India Short Term Fund - Direct Plan - Growth Plan"
                                        },
                                        {
                                            "id": 9242,
                                            "fundname": "Nippon India Short Term Fund - Direct Plan - IDCW Plan - Payout"
                                        },
                                        {
                                            "id": 9107,
                                            "fundname": "Nippon India Gilt Securities Fund - Direct Plan - Growth Plan"
                                        },
                                        {
                                            "id": 9244,
                                            "fundname": "Nippon India Short Term Fund - Direct Plan - IDCW Plan - Reinvestment"
                                        },
                                        {
                                            "id": 9109,
                                            "fundname": "Nippon India Gilt Securities Fund - Direct Plan - Growth Plan"
                                        }
                                    ]
                                }
                            ]
                        },
                        "aboutDetails": {
                            label: "",
                            data: [
                                {
                                    label: "",
                                    value: "The investment objective of the Scheme is to generate optimal returns consistent with moderate levels of risk and high liquidity by investing in debt and money market instruments."
                                },
                                {
                                    label: "Scheme Information Document",
                                    type: "url",
                                    link: "https://mf.nipponindiaim.com/investor-service/downloads/scheme-information-document"
                                }
                            ]
                        }
                    }
                }
            }
        }
    }
}