import React, { Children } from 'react';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { fonts } from '../utilities/styles';

const SectionNote = ({
  children,
  style,
  background,
  border,
  textAlign,
  borderRadius,
  padding,
  marginBottom,
  color,
  fontFamily,
  fontSize,
  lineHeight,
  letterSpacing,
}) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        background: `#F4F6F5`,
        border: `0.5px solid #F4F6F5`,
        textAlign: 'center',
        borderRadius: '2px',
        padding: '10px',
        marginBottom: '20px',
        color: `${theme.palette.common.main}`,
        fontFamily: `${fonts.rubik.value}`,
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.2%',
        fontWeight: 'regular',

        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default SectionNote;
