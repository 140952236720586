export const API_URL ='https://wealth-api.fnpaas.com/staging/unified-onboarding/v1/'
export const API_URL1="https://wealth-api.fnpaas.com/staging/unified-onboarding/v2/"
export const API_URL2="https://wealth-api.fnpaas.com/unified/mf/"
export const API_URL_LOGIN="https://nm-uat-login.fnpaas.com/dev/nm/v1"
export const PWA_URL= "http://localhost:3000/"
export const API_URL3= "https://niyomoney-gateway.fnpaas.com/"
 var customerIds="CUST-575d5ef5-b159-460b-9552-14532e2dd8ab"

export const FETCH_DIGILOCKER_URL = (customerId) => {
    // return `${API_URL}customer/${customerId}/digilocker`;
    return `${API_URL}customer/polling/${customerIds}`;
  };
  export const SAVE_DIGILOCKER_RESPONSE = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/digilocker/callback`;
  };
  export const SAVE_ESIGN_RESPONSE = (customerId) => {
    return `${API_URL}customer/${customerIds}/esign/callback`;
  };
  export const POST_DETAILS_URL = ({ customerId, path }) => {
    return `${API_URL}customer/${customerIds}/${path}`;
  };
  export const POLLING_URL = ({ requestId }) => {
    return `${API_URL}customer/polling/${requestId}`;
  };
  export const NAME_MISMATCH_API_URL = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/pan/name`;
  };
  export const FETCH_PRE_SINGED_URL = ({ customerId, document_type, format }) => {
    return `${API_URL}customer/${customerIds}/upload/${document_type}?format=${format}`;
  };
  export const POST_SAVE_DOCUMENTS = ({ customerId, document_type }) => {
    return `${API_URL}customer/${customerIds}/document/${document_type}`;
  };
  export const POST_VERIFY_KYC_DETAILS = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/verify/kyc-status`;
  };
  export const GET_CUSTOMER_DOCUMENTS = ({ customerId, document_type }) => {
    return `${API_URL}customer/${customerIds}/document/${document_type}/details`;
  };
  export const INITIATE_DIGILOCKER = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/digilocker/initiate`;
  };
  export const INITIATE_ESIGN = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/esign/initiate`;
  };
  export const POST_VERIFY_PENNY_DROP = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/verify/penny-drop`;
  };
  export const GET_DOCUMENT_DETAILS = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/document/details`;
  };
  export const INTIAL_VERIFY = ({ customerId }) => {
    return `${API_URL}customer/${customerIds}/verify`;
  };

  export const CUSTOMER_SYNC= () => {
    return `${API_URL}customer/sync`;
  }

  export const CREATE_CUSTOMER=()=>{
    return `${API_URL1}customer`
  }

  export const GET_FUND_INFO=({mfId})=>{
    return `${API_URL2}portfolio/v1/fund/details?mfid=${mfId}`
  }

  export const GET_PAYMENT_REF_ID=() => {
    return `${API_URL3}mf-transaction-processing/v1/transaction/instruction`
  }

  export const GET_PAYMENT_DETAILS= ({paymentRefId}) => {
    return `${API_URL3}mf-transaction-processing/v1/transaction/payment/details?paymentRefId=${paymentRefId}`
  }