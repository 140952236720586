import React, { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import { frAc, inheritWidth, text12, text20 } from '../../utilities/styles';
import { fonts, positionRelative, positionAbsolute } from '../../utilities/styles';

const useStyles = makeStyles({
  select: {
    '& ul': {
      maxHeight: '280px',
      padding: '0px 1px',
    },
  },
});

export const StyledSelectField = withStyles((theme) => ({
  icon: {
    color: `${theme.palette.success.lightblue}`,
  },
}))(Select);

const SelectInput = ({
  inputKey,
  autoFocus,
  valueUpdateHandler,
  label,
  placeHolder,
  errors,
  required,
  type = 'text',
  value,
  note_text,
  disabled,
  dobOverlayTrigger,
  list,
  displayName,
}) => {
  const isError = errors?.length > 0;
  const theme = useTheme();
  const classes = useStyles();
  const placeHolderValue = 'placeHolderMenuItem';
  const handleChange = (e) => {
    if (e.target.value == placeHolderValue) return;
    valueUpdateHandler(e.target.value);
  };
  return (
    <>
      <Box
        style={{
          alignItems: positionRelative.alignItems,
          boxShadow: positionAbsolute.boxShadow,
          border: `0.5px solid ${theme.palette.secondary.light}`,
          borderRadius: positionAbsolute.borderRadius,
          background: positionRelative.background,
          height:  positionAbsolute.height,
        }}
      >
        <InputLabel
          id={`${displayName}-label`}
          style={{
            color: theme.palette.secondary.darker,
            fontSize: 14,
            fontFamily: fonts.lexend.value,
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(0.5),
          }}
        >
          {displayName}
        </InputLabel>
        <Box
          style={{
            margin: '20px 16px',
            flex: 1,
          }}
        >
          <StyledSelectField
            labelId={displayName}
            id={displayName}
            value={value && value?.length > 0 ? value : placeHolderValue}
            label={displayName}
            style={{
              ...inheritWidth,
              fontSize: 14,
              fontFamily: fonts.lexend.value,
              fontWeight: text20.fontWeight,
              margin: '1px 0px',
              color:
                value && value?.length == 0 && theme.palette.secondary.dark,
            }}
            onChange={handleChange}
            MenuProps={{ classes: { paper: classes.select } }}
            disableUnderline
          >
            <MenuItem
              value={placeHolderValue}
              style={{
                fontSize: 14,
                fontFamily: fonts.lexend.value,
                color: theme.palette.secondary.dark,
                borderBottom: `1px solid ${theme.palette.secondary.light}`,
              }}
            >
              {placeHolder}
            </MenuItem>
            {list.map((listItem, index) => {
              return (
                <MenuItem
                  key={index}
                  value={listItem.value}
                  style={{
                    fontSize: 14,
                    fontFamily: fonts.lexend.value,
                    borderBottom: `1px solid ${theme.palette.secondary.light}`,
                  }}
                >
                  {listItem.label}
                </MenuItem>
              );
            })}
          </StyledSelectField>
        </Box>
      </Box>

      {isError && (
        <Box py={0.5} px={2} component='small' style={{ ...frAc }}>
          <Typography
            style={{
              ...text12,
              color: theme.palette.error.main,
              fontFamily: fonts.rubik.value,
              fontWeight: text20.fontWeight
            }}
          >
            {errors[0]}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SelectInput;
