import React from 'react';
import { useCustomQuery } from '../../hooks/fetchQuery';
import { getPollingDetailsApi, postUserDetails } from '../../http-calls';
import AsyncFormItemContainer from '../../components/FormContainer/AsyncFormItemContainer';
import { createSaveDetailsPayload, setCustomQuery } from '../../helper-methods';
import { selectApiPath } from '../../helper-methods/constants';
import { useQueryClient } from 'react-query';
const AsyncSubSection = ({ subSection, section }) => {
  const { data: customerId } = useCustomQuery({ section: 'customerId' });
  const sectionQuery = useCustomQuery({ section: section });
  const ifscFetchData = useCustomQuery({ section: 'ifscCheck' });
  const subSectionData = sectionQuery?.data?.['inputFields']?.[subSection];
  const queryClient = useQueryClient();
  const asyncPostQuery = useCustomQuery({ section: selectApiPath[subSection] });
  console.log('async-sub-section', subSectionData);
  const onSubmitPostHandler = async () => {
    try {
      const payload = createSaveDetailsPayload({
        subSection,
        subSectionData,
        ifscFetchData,
      });
      console.log('posting-async-data:', payload);
      const postAsyncUsersDetailsResponse = await postUserDetails({
        customerId,
        payload: payload,
        path: selectApiPath[subSection],
      });
      console.log({ postAsyncUsersDetailsResponse });
      setCustomQuery({
        section: selectApiPath[subSection],
        data: { ...postAsyncUsersDetailsResponse },
        queryClient,
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <AsyncFormItemContainer
      subSection={subSection}
      section={section}
      onSubmitPostHandler={onSubmitPostHandler}
      pollingAPI={getPollingDetailsApi}
    />
  );
};

export default AsyncSubSection;
