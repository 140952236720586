import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { Box } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { sampleJson } from '../../mock-data/nmOnboardingJson';
import { StyledBox } from '../StyledComponents/StyledBox';
import { getPreSingedUrlApi, postSaveKycVideo } from '../../http-calls';
import {
  fcAc,
  frJc,
  fcJsb,
  fonts,
  inheritHeight,
  inheritSize,
  taf,
  tac,
} from '../../utilities/styles';
import Text from '../shared/Text';
import CustomButton from '../StyledComponents/Button';
import {
  // forwardNavigationMapping,
  overlayStates,
  selectApiPath,
} from '../../helper-methods/constants';
import {
  getCustomQuery,
  getOverlayComponent,
  setCustomQuery,
  setBackButtonPath,
  base64ToImage,
  getBackButtonPath,
  skipSection,
} from '../../helper-methods';
import { useQueryClient } from 'react-query';
import { useCustomQuery } from '../../hooks/fetchQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import KycSuccessMsg from './KycSuccessMsg';
import axios from 'axios';
/**
 * livelinessStarted = -2 ; error
 * livelinessStated = -1 ; timeout-error
 * livelinessStatus = 0 ; not-started
 * livelinessStatus = 1 ; liveliness-started
 * livelinessStatus = 2 ; liveliness-completed
 */

/**
 * * /-----retry values should not be set as pwaStatus in handlers, on in useEffect() to populate rejection-reason----------------------/
 * pwaStatus = -1 ; no-camera-available
 * pwaStatus = 0 ; not-started
 * pwaStatus = 1 ; waiting for user-response
 * pwaStatus = 2 ; camera-permission-denied
 * pwaStatus = 3 ; error-occured-while-starting-camera
 * pwaStatus = 4 ; upload-error
 * pwaStatus = 5 ; FACE_MATCH_FAILURE
 * pwaStatus = 20; redirect-to-next-page; liveliness-video-processing-completed
 */

const isPwaError = (pwaStatus) => {
  return !(pwaStatus == 0 || pwaStatus == 1 || pwaStatus == 20);
};

const isLivelinessError = (livelinessStatus) => {
  return livelinessStatus == -1 || livelinessStatus == -2;
};

const isProcessStarted = ({ livelinessStatus, pwaStatus }) => {
  return pwaStatus !== 0 || livelinessStatus !== 0;
};

const KycMainScreen = ({ section, subSection }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { data: customerId } = useCustomQuery({ section: 'customerId' });
  const rejectionData = getCustomQuery({
    section: 'rejectedRemarks',
    queryClient,
  })?.kycvideo;
  console.log({ customerId });
  const framesArray = [];
  const imageArray = [];
  const livelinessFramesQuery = useCustomQuery({
    section: 'livelinessFramesCache',
    queryClient,
  });
  const { LivelinessCheck } =
    sampleJson.jsonstructure.sectionsObject.OnlineKyc.tabsObject;
  const basicDetailsFormCacheData = getCustomQuery({
    section: 'basicDetailsFormCache',
    queryClient,
  });
  const gender =
    basicDetailsFormCacheData && basicDetailsFormCacheData?.gender != ''
      ? basicDetailsFormCacheData?.gender == 'M'
        ? 'male'
        : 'female'
      : 'male';
  const sectionData = getCustomQuery({ section: section, queryClient });
  const subSectionMessages = sectionData?.messages;
  const [overlayState, _overlayState] = useState(null);
  const [pwaStatus, _pwaStatus] = useState(0);
  const [livelinessStatus, _livelinessStatus] = useState(0);
  const { data: forwardNavigationMapping } = useCustomQuery({
    section: 'forwardNavigationMapping',
  });
  const ctaBtnText = {
    0: LivelinessCheck.proceedBtn,
    1: LivelinessCheck.waitingTxt,
    4: LivelinessCheck.retry_text,
    5: LivelinessCheck.retry_text,
  };
  const rejectionText = {
    2: LivelinessCheck.note.text_8,
    ['-1']: LivelinessCheck.note.text_5,
    3: LivelinessCheck.note.text_6,
    7: LivelinessCheck.note.text_7,
    4: LivelinessCheck.note.text_10,
    5: LivelinessCheck.note.text_11,
  };
  const pwaStartLiveliness = async () => {
    try {
      window.startLiveliness();
      await nativeLiveliness();
      console.log('nativeLivelinessProcessImageCompleted:', {
        count: framesArray.length,
      });
      const verificationChallengeData = await nativeVerificationChallenge();
      console.log({ verificationChallengeData });
      console.log({ processingImagesCompleted: framesArray.length });
      return verificationChallengeData;
    } catch (e) {
      _pwaStatus(7);
      return;
    }
  };

  const nativeLiveliness = async () => {
    let response = await window.loadingImageCaptured();
    console.log({ response });
    if (typeof response === 'string') {
      console.log(JSON.parse(response));
    }
    if (response) {
      let parsedResponse =
        typeof response === 'string' ? JSON.parse(response) : response;
      console.log({ parsedResponse });
      if (parsedResponse?.frameBase64) {
        let framesApiObject = {
          image_format: parsedResponse?.imageFormat,
          eyeClose: parsedResponse?.eyeClose * 100,
          eyeOpen: parsedResponse?.eyeOpen * 100,
          timestamp: new Date().getTime(),
        };
        framesArray.push(framesApiObject);
        imageArray.push(parsedResponse?.frameBase64);
        setCustomQuery({
          section: 'livelinessFramesCache',
          data: {
            frames: framesArray,
            images: imageArray,
          },
          queryClient,
        });
      }
    }

    console.log({ framesArray });
    if (framesArray.length === 3 || !response) return;
    nativeLiveliness();
  };

  const nativeVerificationChallenge = async () => {
    let responseLivelinessCompleted = await window.loadingLivelinessCompleted();
    console.log({ responseLivelinessCompleted });
    if (typeof responseLivelinessCompleted === 'string') {
      try {
        console.log(JSON.parse(responseLivelinessCompleted));
      } catch (e) {
        console.log(e);
        _livelinessStatus(-2);
      }
    }
    let parsedResponse =
      typeof responseLivelinessCompleted === 'string'
        ? JSON.parse(responseLivelinessCompleted)
        : responseLivelinessCompleted;
    console.log(
      'nativeVerificationChallengeResponse : ',
      JSON.stringify(parsedResponse)
    );
    return parsedResponse;
  };

  useEffect(() => {
    /**
     * need to add check for NO-CAMERA-AVAILABLE condition
     * need to add check for CAMERA-ACCESS-DENIED condition
     */
    sessionStorage.setItem('platform', 'flutter');
  });

  useEffect(() => {
    setBackButtonPath({ data: '/home', queryClient });
  }, []);

  console.log({ pwaStatus, livelinessStatus, overlayState });
  useEffect(() => {
    let newOverlayState = null;
    let newPwaStatus = pwaStatus;
    let newLivelinessStatus = livelinessStatus;
    const pwaStateObj = {
      [-1]: overlayStates.NO_CAMERA_FOUND,
      2: overlayStates.CAMERA_PERMISSION_DENIED,
      3: overlayStates.ERROR_STARTING_CAMERA,
      4: overlayStates.UPLOAD_ERROR,
      5: overlayStates.FACE_MATCH_FAILURE,
    };
    const livelinessStateObj = {
      [-2]: overlayStates.EXTERNAL_API_ERROR,
      [-1]: overlayStates.TIME_EXCEED,
      2: overlayStates.POLLING,
    };
    if (pwaStatus === 20) {
      livelinessFramesQuery?.remove();
      // setBackButtonPath({ data: null, queryClient });
      navigate(`${forwardNavigationMapping[subSection]}${search}`);
    } else if (isPwaError(pwaStatus)) {
      newOverlayState =
        pwaStateObj[pwaStatus] || overlayStates.EXTERNAL_API_ERROR;
      newLivelinessStatus = 0;
    } else if (isLivelinessError(livelinessStatus) || livelinessStatus == 2) {
      newOverlayState = livelinessStateObj[livelinessStatus];
    }
    if (overlayState != newOverlayState) {
      _overlayState(newOverlayState);
    }
    if (pwaStatus != newPwaStatus) {
      _pwaStatus(newPwaStatus);
    }
    if (livelinessStatus != newLivelinessStatus) {
      _livelinessStatus(newLivelinessStatus);
    }
  }, [pwaStatus, livelinessStatus]);

  useEffect(async () => {
    if (livelinessFramesQuery?.data?.frames.length === 3) {
      const payload = [];
      try {
        for (let i = 0; i < livelinessFramesQuery?.data?.images.length; i++) {
          console.log(`generating-presigned-url-for-image-${i + 1}`);

          console.log(`generating-presigned-url-payload-image-${i + 1}`, {
            customerId: customerId,
            document_type: `liveliness_image_${i + 1}`,
            format: livelinessFramesQuery?.data?.frames[i]?.image_format,
          });
          const preSignedUrlResponse = await getPreSingedUrlApi({
            customerId: customerId,
            document_type: `liveliness_image_${i + 1}`,
            format: livelinessFramesQuery?.data?.frames[i]?.image_format,
          });
          if (preSignedUrlResponse?.data?.status != 200)
            throw 'NO_PRESIGNED_URL_GENERATED';

          const presignedUrl = preSignedUrlResponse?.data?.data?.url;
          console.log('presigned-url: ', presignedUrl);
          console.log(`presign-url-for-image-${i + 1}: `, presignedUrl);
          console.log(`uploading-image-${i + 1}-to-s3`);
          const s3UploadResponse = await axios({
            method: 'put',
            url: presignedUrl,
            headers: {
              'Content-Type': `image/${livelinessFramesQuery?.data?.frames[i]?.image_format}`,
            },
            data: base64ToImage({
              base64String: livelinessFramesQuery?.data?.images[i],
            }),
          });
          if (s3UploadResponse?.status != 200)
            throw 'IMAGES_NOT_UPLOADED_TO_S3';
          console.log('response-from-s3:', s3UploadResponse);
          payload.push({
            ...livelinessFramesQuery?.data?.frames[i],
            image_name: `liveliness_image_${i + 1}`,
          });
        }
        console.log('all-images-successfully-uploaded-to-s3');
        console.log('save-video-details-payload', payload);
        const saveKycVideoResponse = await postSaveKycVideo({
          customerId,
          payload: {
            frames: payload,
            challengeResponse: {
              status: 'success',
            },
          },
          path: selectApiPath[subSection],
        });
        console.log('save-kyc-video-response:', saveKycVideoResponse);
        if (saveKycVideoResponse?.error?.status == 422) {
          console.log('FACE_MATCH_FAILURE');
          _pwaStatus(5);
          return;
        }
        if (saveKycVideoResponse?.data?.status != 200)
          throw 'KYC_DETAILS_NOT_SAVED';

        console.log('kyc-video-data-successfully-posted');
        _pwaStatus(20);
      } catch (e) {
        console.log(e);
        console.log('error-in-upload');
        _pwaStatus(4);
      }
    } else if (livelinessFramesQuery?.data?.frames.length === 2) {
      console.log('waiting');
    }
  }, [livelinessFramesQuery?.data?.frames?.length]);

  const closeOverlay = () => {
    livelinessFramesQuery.remove();
    if (pwaStatus == 7 || livelinessStatus == -2) {
      console.log("something's not right");
      // window.exitFromPWA();
      return;
    }
    _overlayState(null);
    _livelinessStatus(0);
  };

  const submitHandler = async () => {
    _pwaStatus(0);
    const verificationData = await pwaStartLiveliness();
    console.log({ framesReceived: framesArray.length });
    console.log('verificationData', JSON.stringify(verificationData));
    if (verificationData?.status?.toUpperCase() === overlayStates.FAILURE) {
      if (verificationData?.event === 'C') {
        console.log('time_exceed');
        _livelinessStatus(-1);
      } else if (verificationData?.event === 'back_pressed') {
        console.log('back_pressed');
        _livelinessStatus(0);
      } else {
        _livelinessStatus(-2);
      }
    } else if (
      verificationData?.status?.toUpperCase() === overlayStates.SUCCESS
    ) {
      _livelinessStatus(2);
    } else {
      _livelinessStatus(-1);
    }
  };

  if (pwaStatus === 20) {
    return <KycSuccessMsg />;
  }

  if (livelinessFramesQuery?.isError) {
    _pwaStatus(4);
  }

  return (
    <>
      {overlayState &&
        getOverlayComponent({
          section,
          subSection,
          messages: subSectionMessages,
          closeOverlay,
          overlayState,
          ctaHandler: closeOverlay,
        })}
      <div style={{ ...inheritSize, ...fcAc }}>
        <StyledBox style={{ ...inheritHeight, ...fcJsb }}>
          <Box>
            <Text
              style={{
                color: theme.palette.common.darkBlack,
                margin: '10px 0',
                fontWeight: 900,
                fontSize: '20px',
                letterSpacing: '0.001em',
              }}
            >
              {LivelinessCheck.displayName}{' '}
              {isPwaError(pwaStatus) && (
                <ErrorOutlineIcon
                  style={{
                    color: theme.palette.error.darker,
                    position: 'absolute',
                    marginLeft: '10px',
                  }}
                />
              )}
            </Text>
            {!isPwaError(pwaStatus) && rejectionData?.remarks && (
              <Box
                style={{
                  background: theme.palette.error.orangeLight,
                  padding: '12px',
                  borderRadius: '2px',
                  margin: '12px 0 ',
                }}
              >
                <Text
                  style={{
                    color: theme.palette.error.orageLighter,
                    fontFamily: fonts.rubik.value,
                    fontSize: '10px',
                    textAlign: 'left',
                  }}
                >
                  {/* {LivelinessCheck.rejectedText} */}
                  Kyc Video Rejected
                </Text>
                <Text
                  style={{
                    color: theme.palette.common.main,
                    fontFamily: fonts.rubik.value,
                    fontSize: '12px',
                    textAlign: 'left',
                    lineHeight: '14px',
                    fontWeight: '400',
                  }}
                >
                  {rejectionData?.remarks}
                </Text>
              </Box>
            )}
            {isPwaError(pwaStatus) && (
              <Box
                style={{
                  background: isPwaError(pwaStatus)
                    ? theme.palette.error.orangeLight
                    : theme.palette.error.orangeDark,
                  padding: '12px',
                  borderRadius: '2px',
                }}
              >
                <Text
                  style={{
                    color: theme.palette.error.orageLighter,
                    fontFamily: fonts.rubik.value,
                    fontSize: '10px',
                    textAlign: taf.textAlign,
                  }}
                >
                  {LivelinessCheck.rejectedText}
                </Text>
                <Text
                  style={{
                    color: theme.palette.common.main,
                    fontFamily: fonts.rubik.value,
                    fontSize: '12px',
                    textAlign: taf.textAlign,
                    lineHeight: '14px',
                    fontWeight: '400',
                  }}
                >
                  {rejectionText[pwaStatus]}
                </Text>
              </Box>
            )}
          </Box>
          <Box style={{ ...frJc, flex: 0.8 }}>
            <img
              src={
                gender !== 'male'
                  ? LivelinessCheck?.niyoxPlaceholderImageFemale
                  : LivelinessCheck?.niyoxPlaceholderImageMale
              }
              style={{ ...inheritSize }}
            />
          </Box>
          <Text
            style={{
              fontFamily: fonts.lexend.value,
              fontSize: '16px',
              color: theme.palette.common.heading_black,
              textAlign: tac.textAlign,
              margin: '20px 0',
            }}
          >
            {pwaStatus === 0 && LivelinessCheck?.note?.text}
            {/* {pwaStatus === 4 && LivelinessCheck?.note?.text_10} */}
          </Text>
          <Box style={{ ...fcAc }}>
            <CustomButton
              disabled={pwaStatus === 1}
              onClick={submitHandler}
              textStyle={
                pwaStatus === 1 && {
                  color: theme.palette.secondary.dark,
                }
              }
            >
              {ctaBtnText[pwaStatus] || LivelinessCheck.retryBtn}
            </CustomButton>
          </Box>
        </StyledBox>
      </div>
    </>
  );
};

export default KycMainScreen;
