import React from 'react';
import { Box } from '@material-ui/core';
import EquityIcon from '../../assets/icons/equity-icon.svg';
import DebtIcon from '../../assets/icons/debt-icon.svg';
import UpArrowIcon from '../../assets/icons/upArrow.svg';
import DownArrowIcon from '../../assets/icons/downArrow.svg';

import {
  frJsb,
  frJsa,
  fcJc,
  text14,
  fonts,
  summaryTab1,
  frAs,
  text24,
  text12,
  fcJsb
} from '../../utilities/styles';
import LinkTextSM from '../shared/LinkTextSM';

const Summary = ({ data }) => {
    console.log("data is"+JSON.stringify(data));
    let equityIcon = <img src={EquityIcon} alt='' />
    let debtIcon = <img src={DebtIcon} alt='' />
    let downArrowIcon = <img src={DownArrowIcon} alt='' />
    let upArrowIcon = <img src={UpArrowIcon} alt='' />

  return (
    <>
        <Box style={{...frJsa, ...summaryTab1}}>
            <Box style={{...fcJc, width: '40%'}}>
                <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#6B7179'}}>Total Current Value</LinkTextSM>
                <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text24, color: '#000000'}}>{data.currentValue}</LinkTextSM>
                <Box style={{...frJsb}}>&nbsp;
                </Box>
                <Box style={{...frAs, marginLeft: '50px'}}>
                    <Box style={{borderRadius: '5px', border: '1.5px solid #FFB800', background: '#FFB800', width: `${data.equity}` }}></Box>
                    <Box style={{borderRadius: '5px', border: '1.5px solid #02A4FF', background: '#02A4FF', width: `${data.debt}` }}></Box>
                </Box>
                <Box style={{...frJsb, width: '80%', marginLeft: '50px'}}>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14}}>{equityIcon}</LinkTextSM>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text12, color: '#6B7179'}}>{data.equity}equity</LinkTextSM>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text12, color: '#6B7179'}}>{data.debt}debt</LinkTextSM>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14}}>{debtIcon}</LinkTextSM>
                </Box>
            </Box>
            <Box style={{...fcJsb, width: '40%'}}>
                <Box style={{...frJsb}}>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#6B7179'}}>Invested value</LinkTextSM>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#081320'}}>{data.investedValue}</LinkTextSM>
                </Box>
                <Box style={{...frJsb}}>&nbsp;
                </Box>
                <Box style={{...frJsb}}>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#6B7179'}}>Total gain</LinkTextSM>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#1A936F', padding: '3px'}}>{upArrowIcon}{data.totalGain}{data.totalGainValue}</LinkTextSM>
                </Box>
                <Box style={{...frJsb}}>&nbsp;
                </Box>
                <Box style={{...frJsb}}>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#6B7179'}}>1 Day change</LinkTextSM>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#D70000'}}>{downArrowIcon}{data.oneDayChange}{data.oneDayChangeValue}</LinkTextSM>
                </Box>
                <Box style={{...frJsb}}>&nbsp;
                </Box>
                <Box style={{...frJsb}}>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#6B7179'}}>Total SIPs</LinkTextSM>
                    <LinkTextSM fontFamily={fonts.rubikNormal.value} style={{...text14, color: '#081320'}}>{data.totalSips}</LinkTextSM>
                </Box>
                
                
                
            </Box>
        </Box>
    </>
  );
};

export default Summary;