import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import React from 'react';

import { fonts, text14, inheritWidthImp } from '../../utilities/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: theme.spacing(37),
    borderRadius: theme.spacing(1),
    boxShadow: inheritWidthImp.none,
    border: inheritWidthImp.none,
    color: (props) =>
      `${
        props.secondary
          ? theme.palette.primary.main
          : theme.palette.common.white
      }`,
    '&.MuiButton-root': {
      background: (props) => 
        props.disabled
          ? theme.palette.secondary.main
          : inheritWidthImp.color
    },
    ['@media (max-width:780px)']: {
      width: inheritWidthImp.width,
    },
  },
}))(Button);

const CustomButton = ({
  disabled,
  onClick,
  children,
  style,
  color,
  textStyle,
  variant,
  secondary,
  icon
}) => {
  return (
    <>
      <StyledButton
        secondary={secondary}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        startIcon={icon}
        disableElevation
        color = {color}
        style={{ textTransform: inheritWidthImp.none , ...style }}
      >
        <Typography style={{ ...fonts.lexend.style, ...text14, ...textStyle }}>
          {children}
        </Typography>
      </StyledButton>
    </>
  );
};

export default CustomButton;
