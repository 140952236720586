import { useEffect, useState } from 'react';
import blink from './Blink';
import {
  inheritHeight,
  inheritSize,
  fcJc,
  loginContainer,
  fcAllCenter,
} from '../utilities/styles';
import { StyledBox } from '../components/StyledComponents/StyledBox';
import WebcamCapture from './KycScreen'

const TestPage = () => {
  const updateModelStatus = () => {
    const status = document.querySelector('.model-status');
    if (status) {
      status.innerHTML = 'Model loaded! You can start!';
      status.classList.add('fade-out');
      status.classList.remove('model-status');
    }
  };
  const [CaptureOnBlink, setCaptureOnBlink] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const init = async () => {
  var raf;
  const videoElement = document.querySelector('video');
    await blink.loadModel();
    await blink.setUpCamera(videoElement);
  
    let blinkIndicator = document.getElementById('blink-indicator');
    let body = document.getElementsByTagName('body');
    const predict = async () => {
      let result = await blink.getBlinkPrediction();
      updateModelStatus();
  
      if (result) {
        if (result.blink) {
          setCaptureOnBlink(true)
          console.log('111111111') 
        } else {
          setCaptureOnBlink(false)
          // console.log('2222222222')
        }
        if (result.longBlink) {
          // toggleMode();
        }
      }
      raf = requestAnimationFrame(predict);
    };
    predict();
  };
  useEffect(() => {
    init();
  }, [])

  const CaptureOnBlinkFn = (data) => {
    setImgSrc(data)
    console.log('CaptureOnBlinkFn', data)
  }
  return(<>
    <div style={{ ...inheritSize, ...fcAllCenter }}>
            <StyledBox style={{ ...inheritHeight, ...fcJc, ...loginContainer }}>
    <div className='video_control'>
      <WebcamCapture id="video" CaptureOnBlink ={CaptureOnBlink} CaptureOnBlinkFn = {CaptureOnBlinkFn}/>
      {/* <video id="video" playsInline width="750" height="500" /> */}
      <img src= {imgSrc} />
      </div>
    </StyledBox>
    </div>
  </>)
}

export default TestPage